import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { JhiItemCount, JhiPagination, TextFormat, Translate, getPaginationState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import { getEntities } from './approval-log.reducer';

export const ApprovalLog = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const employees = useAppSelector(state => state.employee.entities);
  const approvalLogList = useAppSelector(state => state.approvalLog.entities);
  const loading = useAppSelector(state => state.approvalLog.loading);
  const totalItems = useAppSelector(state => state.approvalLog.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h5 id="approval-log-heading" data-cy="ApprovalLogHeading" className="text-center text-primary fw-bold p-1">
        <Translate contentKey="hrmsApp.approvalLog.home.title">Approval Logs</Translate>
        <div className="d-flex justify-content-end">
          <Button className="btn-sm me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="hrmsApp.approvalLog.home.refreshListLabel">Refresh</Translate>
          </Button>
          {/* <Link to="/approval-log/new" className="btn btn-sm btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="hrmsApp.approvalLog.home.createLabel">Create new Approval Log</Translate>
          </Link> */}
        </div>
      </h5>
      <div className="table-responsive">
        {hrCodes && hrCodes.length > 0 && approvalLogList && approvalLogList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr className="table-info">
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hrmsApp.approvalLog.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('dated')}>
                  <Translate contentKey="hrmsApp.approvalLog.dated">Dated</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('dated')} />
                </th>
                <th className="hand" onClick={sort('entityId')}>
                  <Translate contentKey="hrmsApp.approvalLog.entityId">Entity Id</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('entityId')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.approvalLog.entityStatus">Entity Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>Role</th>
                <th>
                  <Translate contentKey="hrmsApp.approvalLog.workflow">Workflow</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.approvalLog.initiator">Initiator</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.approvalLog.approver">Approver</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.approvalLog.delegator">Delegator</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.approvalLog.action">Action</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('remarks')}>
                  <Translate contentKey="hrmsApp.approvalLog.remarks">Remarks</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('remarks')} />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {approvalLogList.map((approvalLog, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/approval-log/${approvalLog.id}`} color="link" size="sm">
                      {approvalLog.id}
                    </Button>
                  </td>
                  <td>{approvalLog.dated ? <TextFormat type="date" value={approvalLog.dated} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{approvalLog.entityId}</td>
                  <td>
                    {approvalLog.entityStatus ? (
                      <Link to={`/hr-code/${approvalLog.entityStatus.id}`}>{approvalLog.entityStatus.codeName}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{approvalLog.roleName}</td>
                  <td>
                    {approvalLog.workflow ? hrCodes.find(e=>e.id===approvalLog.workflow.id)?.codeName : ''}
                  </td>
                  <td>
                    {approvalLog.initiator ? employees.find(e=>e.id===approvalLog.initiator.id)?.qlid : ''}
                  </td>
                  <td>
                    {approvalLog.approver ? employees.find(e=>e.id===approvalLog.approver.id)?.qlid : ''}
                  </td>
                  <td>
                    {approvalLog.delegator ? employees.find(e=>e.id===approvalLog.delegator.id)?.qlid : ''}
                  </td>
                  <td>{approvalLog.action ? hrCodes.find(e=>e.id===approvalLog.action.id)?.codeName : ''}</td>
                  <td>{approvalLog.remarks}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button onClick={() => (window.location.href = `/approval-log/${approvalLog.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`) } color="danger" size="sm" data-cy="entityDeleteButton" >
                        <FontAwesomeIcon icon="trash" />{' '}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.approvalLog.home.notFound">No Approval Logs found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={approvalLogList && approvalLogList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ApprovalLog;
