import dayjs from 'dayjs';
import { IHrCode } from 'app/shared/model/hr-code.model';

export interface ICountryHoliday {
  id?: number;
  dated?: dayjs.Dayjs;
  remarks?: string | null;
  country?: IHrCode;
  status?: IHrCode;
}

export const defaultValue: Readonly<ICountryHoliday> = {};
