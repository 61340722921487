import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, ValidatedInput, getSortState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import EmployeeModal from '../employee/employee-modal';
import HrCodeModal from '../hr-code/hr-code-modal';

import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { getEntities } from '../../entities/employee/employee.reducer';
import YearWeekFilter from './year-week-filter';
import { getEmployeeTimeSheets } from '../time-sheet-record/employee-time-sheet-record.reducer';

export const TimeSheetEmployees = () => {
  const dispatch = useAppDispatch();
  const [searchFields, setSearchFields] = useState({
    id: '',
    qlid: '',
    firstName: '',
    lastName: '',
    codeStatus: '',
    codeSeq: '',
  });

  const [selectedList, setSelectedList] = useState([]);

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const employees = useAppSelector(state => state.employee.entities);
  const employeeTimeSheetRecord = useAppSelector(state => state.employeeTimeSheetRecord.entities);
  const loading = useAppSelector(state => state.employee.loading);

  const [hrCodeFetched, setHrCodeFetched] = useState(false);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );

    dispatch(getEmployeeTimeSheets({ year: 2025, weekNumber: 9 }));
  };

  useEffect(() => {
    if (!hrCodes && !hrCodeFetched) {
      dispatch(getHrCodes({}));
      setHrCodeFetched(true);
    }
    setSelectedList(employeeTimeSheetRecord);
  }, [hrCodes]);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  useEffect(() => {
    const sortedList = [...employeeTimeSheetRecord].sort((a, b) => {
      const dateA = a?.timeSheetsRecord?.createdDate ? new Date(a.timeSheetsRecord.createdDate).getTime() : 0;
      const dateB = b?.timeSheetsRecord?.createdDate ? new Date(b.timeSheetsRecord.createdDate).getTime() : 0;
      return dateB - dateA; // Sorts in descending order (newest first)
    });

    setSelectedList(sortedList);
  }, [employeeTimeSheetRecord]);

  const [selectedHrCode, setSelectedHrCode] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isHrCodeModalOpen, setHrCodeModalOpen] = useState(false);
  const [isEmployeeModalOpen, setEmployeeModalOpen] = useState(false);
  const toggleHrCodeModal = () => {
    setHrCodeModalOpen(!isHrCodeModalOpen);
  };
  const toggleEmployeeModal = () => {
    setEmployeeModalOpen(!isEmployeeModalOpen);
  };

  const showHrCodeModal = detail => {
    setSelectedHrCode(detail);
    toggleHrCodeModal();
  };

  // const showEmployeeModal = detail => {
  //   setSelectedEmployee(employeeList ? employeeList.filter(e => e.id === detail.id) : detail);
  //   toggleEmployeeModal();
  // };

  const handleFilterSubmit = () => {
    // Perform any action with the selected year & week
  };

  return (
    <div>
      <YearWeekFilter onSubmit={handleFilterSubmit} startYear={2010} />
      <h5 id="employee-heading" data-cy="EmployeeHeading" className="text-center text-primary fw-bold p-1">
        <div className="d-flex justify-content-end">
          <Button className="btn-sm me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            {/* <Translate contentKey="hrmsApp.employee.home.refreshListLabel">Refresh</Translate> */}
          </Button>
          <Link to="/employee/new" className="btn btn-sm btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            {/* <Translate contentKey="hrmsApp.employee.home.createLabel">Create new Employee</Translate> */}
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {selectedList && selectedList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr className="table-info">
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hrmsApp.employee.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('qlid')}>
                  <Translate contentKey="hrmsApp.employee.qlid">Qlid</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('qlid')} />
                </th>
                <th className="hand" onClick={sort('firstName')}>
                  <Translate contentKey="hrmsApp.employee.firstName">First Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('firstName')} />
                </th>
                <th className="hand" onClick={sort('lastName')}>
                  <Translate contentKey="hrmsApp.employee.lastName">Last Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('lastName')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.employee.employeeType">Employee Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.employee.reportingLine">Reporting Line</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.employee.employeeStatus">Submitted Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {selectedList.map((timeSheetRecord, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/employee/${timeSheetRecord.employee.id}`} color="link" size="sm">
                      {timeSheetRecord?.employee.id}
                    </Button>
                  </td>
                  <td>{timeSheetRecord?.employee.qlid}</td>
                  <td>{timeSheetRecord?.employee.firstName}</td>
                  <td>{timeSheetRecord?.employee.lastName}</td>
                  <td>
                    {timeSheetRecord?.employee.employeeType ? (
                      <span
                        className="text-primary"
                        onClick={() => showHrCodeModal(hrCodes.find(e => e.id === timeSheetRecord?.employee.employeeType.id))}
                      >
                        {hrCodes.find(e => e.id === timeSheetRecord?.employee.employeeType.id)?.codeName}{' '}
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {timeSheetRecord?.employee.reportingLine ? (
                      <span
                        className="text-primary"
                        // onClick={() => showEmployeeModal(employees.find(e => e.id === timeSheetRecord?.employee.reportingLine.id))}
                      >
                        {employees.find(e => e.id === timeSheetRecord?.employee.reportingLine.id)?.firstName}{' '}
                        {employees.find(e => e.id === timeSheetRecord?.employee.reportingLine.id)?.lastName}
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {timeSheetRecord?.timeSheetsRecord?.createdDate && (
                      <TextFormat value={timeSheetRecord?.timeSheetsRecord?.createdDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/time-sheet-record/${timeSheetRecord?.timeSheetsRecord?.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.timeSheetRecord?.employee.home.notFound">No Employees found</Translate>
            </div>
          )
        )}
      </div>
      {selectedHrCode && <HrCodeModal hrCodeEntity={selectedHrCode} isOpen={isHrCodeModalOpen} toggle={toggleHrCodeModal} />}
      {selectedEmployee && <EmployeeModal employeeEntity={selectedEmployee} isOpen={isEmployeeModalOpen} toggle={toggleEmployeeModal} />}
    </div>
  );
};

export default TimeSheetEmployees;
