import dayjs from 'dayjs';
import { IApprovalLimit } from 'app/shared/model/approval-limit.model';

export interface IApprovalLimitLog {
  id?: number;
  oldAmount?: number;
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs;
  approvalLimit?: IApprovalLimit;
}

export const defaultValue: Readonly<IApprovalLimitLog> = {};
