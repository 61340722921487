import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { translate, Translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { useParams } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { useAppSelector } from 'app/config/store';
import { useForm } from 'react-hook-form';

export const ExpenseItemUpdateDialog = ({ isOpen, expenseItemEntity, toggle, saveItemModelEntity }) => {
  /* eslint no-console: off */
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const [isOpenModal, setIsOpenModal] = useState(isOpen);
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const loading = useAppSelector(state => state.expenseItem.loading);
  const updating = useAppSelector(state => state.expenseItem.updating);

  const handleClose = () => {
    setIsOpenModal(false);
    toggle(false);
  };

  const saveEntity = () => {
    const values = getValues();

    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.amount !== undefined && typeof values.amount !== 'number') {
      values.amount = Number(values.amount);
    }
    if (values.exchangeRate !== undefined && typeof values.exchangeRate !== 'number') {
      values.exchangeRate = Number(values.exchangeRate);
    }
    const entity = {
      ...expenseItemEntity,
      ...values,
      expenseType: hrCodes.find(it => it.id.toString() === values.expenseType?.toString()),
      currecny: hrCodes.find(it => it.id.toString() === values.currecny?.toString()),
      status: isNew
        ? hrCodes.find(e => e.codeType === 'WKS' && e.codeName === 'Pending')
        : hrCodes.find(it => it.id.toString() === values.status?.toString()),
      id: expenseItemEntity.id || Date.now(),
    };
    console.log('====== Expense Entity: ', entity);
    saveItemModelEntity(entity);
    handleClose();
  };

  // const defaultValues = () =>
  //   isNew
  //     ? {}
  //     : {
  //         ...expenseItemEntity,
  //         expenseClaim: expenseItemEntity?.expenseClaim?.id,
  //         expenseType: expenseItemEntity?.expenseType?.id,
  //         currecny: expenseItemEntity?.currecny?.id,
  //         status: expenseItemEntity?.status?.id,
  //       };

  const defaultValues = useMemo(() => {
    const defaultValuesData = {
      ...expenseItemEntity,
      expenseClaim: expenseItemEntity?.expenseClaim?.id,
      expenseType: expenseItemEntity?.expenseType?.id,
      currecny: expenseItemEntity?.currecny?.id,
      status: expenseItemEntity?.status?.id,
    };
    return defaultValuesData;
  }, [expenseItemEntity, hrCodes]);

  const { control, setValue, register, handleSubmit, getValues } = useForm({
    defaultValues,
  });

  return (
    <Modal isOpen={isOpenModal} toggle={handleClose}>
      <ModalHeader toggle={handleClose} className="fw-bold text-primary">
        Create/Update Expense Item
      </ModalHeader>
      <ModalBody>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <ValidatedForm defaultValues={defaultValues} onSubmit={handleSubmit(saveEntity)}>
                <ValidatedField
                  label={translate('hrmsApp.expenseItem.expDate')}
                  id="expense-item-expDate"
                  name="expDate"
                  data-cy="expDate"
                  type="date"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                  register={register}
                />
                <ValidatedField
                  id="expense-item-expenseType"
                  name="expenseType"
                  data-cy="expenseType"
                  label={translate('hrmsApp.expenseItem.expenseType')}
                  type="select"
                  required
                  register={register}
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'XPT' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.expenseItem.description')}
                  id="expense-item-description"
                  name="description"
                  data-cy="description"
                  type="text"
                  register={register}
                />
                <ValidatedField
                  label={translate('hrmsApp.expenseItem.receiptNo')}
                  id="expense-item-receiptNo"
                  name="receiptNo"
                  data-cy="receiptNo"
                  type="text"
                  register={register}
                />
                <ValidatedField
                  label={translate('hrmsApp.expenseItem.amount')}
                  id="expense-item-amount"
                  name="amount"
                  data-cy="amount"
                  type="text"
                  register={register}
                />
                <ValidatedField
                  label={translate('hrmsApp.expenseItem.exchangeRate')}
                  id="expense-item-exchangeRate"
                  name="exchangeRate"
                  data-cy="exchangeRate"
                  type="text"
                  register={register}
                />
                <ValidatedField
                  id="expense-item-currecny"
                  name="currecny"
                  data-cy="currecny"
                  label={translate('hrmsApp.expenseItem.currecny')}
                  type="select"
                  required
                  register={register}
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'CUR' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName}
                          </option>
                        ))
                    : null}
                </ValidatedField>
              </ValidatedForm>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="button" onClick={saveEntity}>
          <FontAwesomeIcon icon="save" />
          &nbsp;
          <Translate contentKey="entity.action.save">Save</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ExpenseItemUpdateDialog;
