import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getBusinessProjects } from 'app/entities/business-project/business-project.reducer';
import { createEntity as createExpenseClaim, getEntity, reset, updateEntity as updateExpenseClaim } from './expense-claim.reducer';
import { getEntities as getExpenseItems, reset as resetExpenseItem } from '../expense-item/expense-item.reducer';
import ExpenseClaimItemList from './expense-claim-item-list';

const ExpenseClaimUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = !id;

  const account = useAppSelector(state => state.authentication.account);
  const employees = useAppSelector(state => state.employee.entities);
  const contracts = useAppSelector(state => state.contract.entities);
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const businessProjects = useAppSelector(state => state.businessProject.entities);
  const expenseClaimEntity = useAppSelector(state => state.expenseClaim.entity);
  const loading = useAppSelector(state => state.expenseClaim.loading);
  const updating = useAppSelector(state => state.expenseClaim.updating);
  const updateSuccess = useAppSelector(state => state.expenseClaim.updateSuccess);

  const [expenseItems, setExpenseItems] = useState([]);
  const [canUpdate, setCanUpdate] = useState(false);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
      dispatch(resetExpenseItem());
    } else {
      dispatch(getEntity(id));
      // dispatch(getExpenseItems({ query: `expenseClaimId.equals=${id}` }));
    }
    dispatch(getBusinessProjects({}));
  }, [dispatch, id, isNew]);

  const curEmployee = useMemo(() => employees.find(e => e.id === account?.employeeRef) || null, [account, employees]);

  const curContract = useMemo(() => {
    const activeContractStatus = hrCodes.find(e => e.codeType === 'CTS' && e.codeName === 'Active')?.id;
    return contracts.find(e => e.employee.id === account?.employeeRef && e.contractStatus?.id === activeContractStatus) || null;
  }, [account, contracts, hrCodes]);

  useEffect(() => {
    if (updateSuccess) navigate('/expense-claim');
  }, [updateSuccess, navigate]);

  const handleItemAdded = useCallback(
    data => {
      setExpenseItems(prevItems => {
        const newItem = { ...data, id: data.id || Date.now() };
        return Array.isArray(prevItems) ? [...prevItems, newItem] : [newItem];
      });
    },
    [expenseItems],
  );

  const onItemsRemoved = data => {
    setExpenseItems(data);
  };

  const onItemsUpdated = data => {
    setExpenseItems(data);
  };

  useEffect(() => {
    if (expenseClaimEntity) {
      setExpenseItems(expenseClaimEntity?.expenseItems ?? []);
      setCanUpdate(hrCodes.find(e => e.id === expenseClaimEntity?.status?.id)?.codeName === 'Pending' || isNew ? true : false);
    }
  }, [expenseClaimEntity]);

  const saveEntity = values => {
    const entity = {
      ...expenseClaimEntity,
      ...values,
      contract: curContract,
      businessProject: businessProjects.find(it => it.id.toString() === values.businessProject?.toString()),
      status: hrCodes.find(e => e.codeType === 'ECS' && e.codeName === 'Pending'),
      dated: new Date(),
      expenseItems,
    };

    // // isNew ? dispatch(createEntity(entity)) : dispatch(updateEntity(entity));

    if (isNew) {
      dispatch(createExpenseClaim(entity));
    } else {
      dispatch(updateExpenseClaim(entity));
    }
  };

  const defaultValues = useMemo(
    () =>
      isNew
        ? {}
        : {
            ...expenseClaimEntity,
            contract: expenseClaimEntity?.contract?.id,
            businessProject: expenseClaimEntity?.businessProject?.id,
            status: expenseClaimEntity?.status?.id,
          },
    [dispatch, expenseClaimEntity, curContract, businessProjects, hrCodes, isNew, expenseItems],
  );

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5 className="text-center fw-bold text-primary">
            <Translate contentKey="hrmsApp.expenseClaim.home.createOrEditLabel" />
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center text-primary fw-bold">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues} onSubmit={saveEntity}>
              <ValidatedField
                id="expense-claim-businessProject"
                name="businessProject"
                label={translate('hrmsApp.expenseClaim.businessProject')}
                type="select"
                required
                disabled={!canUpdate}
              >
                <option value="" key="0" />
                {businessProjects.map(otherEntity => (
                  <option value={otherEntity.id} key={otherEntity.id}>
                    {otherEntity.projectName}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('hrmsApp.expenseClaim.remarks')}
                id="expense-claim-remarks"
                name="remarks"
                type="text"
                disabled={!canUpdate}
              />
              <ExpenseClaimItemList
                onItemAdded={handleItemAdded}
                onItemsRemoved={onItemsRemoved}
                onItemsUpdated={onItemsUpdated}
                canUpdate={canUpdate}
              />
              <Button tag={Link} to="/expense-claim" replace color="info">
                <FontAwesomeIcon icon="arrow-left" /> &nbsp; <Translate contentKey="entity.action.back" />
              </Button>
              &nbsp;
              {canUpdate && (
                <Button color="primary" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" /> &nbsp; <Translate contentKey="entity.action.save" />
                </Button>
              )}
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ExpenseClaimUpdate;
