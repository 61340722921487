import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, ValidatedInput, getSortState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import EmployeeModal from '../employee/employee-modal';
import HrCodeModal from '../hr-code/hr-code-modal';

import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { getEntities } from './employee.reducer';

export const Employee = () => {
  const dispatch = useAppDispatch();
  const [searchFields, setSearchFields] = useState({
    id: '',
    qlid: '',
    firstName: '',
    lastName: '',
    codeStatus: '',
    codeSeq: '',
  });

  const [selectedList, setSelectedList] = useState([]);

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const employees = useAppSelector(state => state.employee.entities);
  const employeeList = useAppSelector(state => state.employee.entities);
  const loading = useAppSelector(state => state.employee.loading);

  const [selectedEmpType, setSelectedEmpType] = useState('');
  const [selectedRepLine, setSelectedRepLine] = useState('');
  const [selectedEmpStatus, setSelectedEmpStatus] = useState('');
  const [selectedJobMatrixType, setSelectedJobMatrixType] = useState('');

  const [hrCodeFetched, setHrCodeFetched] = useState(false);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  useEffect(() => {
    console.log('------- hrCodeFetched: ', hrCodeFetched);
    if (!hrCodes && !hrCodeFetched) {
      dispatch(getHrCodes({}));
      setHrCodeFetched(true);
    }
    setSelectedList(employeeList);
  }, [hrCodes]);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  useEffect(() => {
    setSelectedList(employeeList);
  }, [employeeList]);

  /* eslint no-console: off */
  const handleEmpTypeChange = event => {
    const selectedValue = event.target.value;
    setSelectedEmpType(selectedValue);
  };

  const handleJobMatrixTypeChange = event => {
    const selectedValue = event.target.value;
    setSelectedJobMatrixType(selectedValue);
  };

  useEffect(() => {
    setSelectedList(
      selectedJobMatrixType ? employeeList.filter(it => it?.jobMatrix?.id === parseInt(selectedJobMatrixType, 10)) : employeeList,
    );
  }, [selectedJobMatrixType]);

  useEffect(() => {
    setSelectedList(selectedEmpType ? employeeList.filter(it => it.employeeType.id === parseInt(selectedEmpType, 10)) : employeeList);
  }, [selectedEmpType]);

  const handleEmpStatusChange = event => {
    const selectedValue = event.target.value;
    setSelectedEmpStatus(selectedValue);
  };

  useEffect(() => {
    setSelectedList(selectedEmpStatus ? employeeList.filter(it => it.employeeStatus.id === parseInt(selectedEmpStatus, 10)) : employeeList);
  }, [selectedEmpStatus]);

  const handleRepLineChange = (e, field) => {
    if (e.key === 'Enter') {
      console.log(`Search for ${field} with value: ${searchFields[field]}`);
      // const empRow = employeeList.filter(it=> it['reportingLine'].'?.toLowerCase().includes(searchFields[field].toLowerCase()))
      // setSelectedList(employeeList.filter(it=> it[field]?.toLowerCase().includes(searchFields[field].toLowerCase())));
    }
  };

  useEffect(() => {
    setSelectedList(
      selectedRepLine
        ? employeeList.filter(it => it.reportingLine['firstName'].toLowerCase().includes(selectedRepLine.toLowerCase))
        : employeeList,
    );
  }, [selectedRepLine]);

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setSearchFields(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleKeyDown = (e, field) => {
    if (e.key === 'Enter') {
      console.log(`Search for ${field} with value: ${searchFields[field]}`);
      if (field === 'id') {
        setSelectedList(employeeList.filter(it => it[field] === parseInt(searchFields[field], 10)));
      } else if (field === 'employeeType' || field === 'employeeStatus') {
        const hrRow = hrCodes.filter(it => it['codeName']?.toLowerCase().includes(field.toLowerCase()));
        console.log('----------- HrRow: ', hrRow);
      } else {
        setSelectedList(employeeList.filter(it => it[field]?.toLowerCase().includes(searchFields[field].toLowerCase())));
      }
    }
  };

  const filterList = () => {
    setSelectedList(
      employeeList.filter(it => {
        return Object.keys(searchFields).every(field => {
          const fieldValue = searchFields[field].toLowerCase();
          return !fieldValue || it[field]?.toLowerCase().includes(fieldValue);
        });
      }),
    );
  };

  const [selectedHrCode, setSelectedHrCode] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isHrCodeModalOpen, setHrCodeModalOpen] = useState(false);
  const [isEmployeeModalOpen, setEmployeeModalOpen] = useState(false);
  const toggleHrCodeModal = () => {
    setHrCodeModalOpen(!isHrCodeModalOpen);
  };
  const toggleEmployeeModal = () => {
    setEmployeeModalOpen(!isEmployeeModalOpen);
  };

  const showHrCodeModal = detail => {
    setSelectedHrCode(detail);
    toggleHrCodeModal();
  };

  const showEmployeeModal = detail => {
    setSelectedEmployee(employeeList ? employeeList.filter(e => e.id === detail.id) : detail);
    toggleEmployeeModal();
  };

  return (
    <div>
      <h5 id="employee-heading" data-cy="EmployeeHeading" className="text-center text-primary fw-bold p-1">
        <Translate contentKey="hrmsApp.employee.home.title">Employees</Translate>
        <div className="d-flex justify-content-end">
          <Button className="btn-sm me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            {/* <Translate contentKey="hrmsApp.employee.home.refreshListLabel">Refresh</Translate> */}
          </Button>
          <Link to="/employee/new" className="btn btn-sm btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            {/* <Translate contentKey="hrmsApp.employee.home.createLabel">Create new Employee</Translate> */}
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {selectedList && selectedList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <input
                    type="text"
                    className="border border-gray w-100 form-control-sm"
                    disabled /* onChange={(e)=>handleInputChange(e,`id`)} onKeyDown={(e)=>handleKeyDown(e,'id')}*/
                  />
                </th>
                <th>
                  <input
                    type="text"
                    className="border border-gray w-100 form-control-sm"
                    onChange={e => handleInputChange(e, `qlid`)}
                    onKeyDown={e => handleKeyDown(e, 'qlid')}
                  />
                </th>
                <th>
                  <input
                    type="text"
                    className="border border-gray w-100 form-control-sm"
                    onChange={e => handleInputChange(e, `firstName`)}
                    onKeyDown={e => handleKeyDown(e, 'firstName')}
                  />
                </th>
                <th>
                  <input
                    type="text"
                    className="border border-gray w-100 form-control-sm"
                    onChange={e => handleInputChange(e, `lastName`)}
                    onKeyDown={e => handleKeyDown(e, 'lastName')}
                  />
                </th>
                <th>
                  <input type="text" className="border border-gray w-100 form-control-sm" disabled />
                </th>
                <th>
                  <input type="text" className="border border-gray w-100  form-control-sm" disabled />
                </th>
                <th>
                  <ValidatedInput
                    name="employeeTypeSearch"
                    className="form-control-sm"
                    type="select"
                    value={selectedEmpType}
                    onChange={handleEmpTypeChange}
                  >
                    <option value="" key="0" />
                    {hrCodes
                      ? hrCodes
                          .filter(outerEntity => outerEntity.codeType === 'EMT' && outerEntity.id % 100 > 0)
                          .sort((a, b) => a.id - b.id)
                          .map(otherEntity => (
                            <option value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                              {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                            </option>
                          ))
                      : null}
                  </ValidatedInput>
                  {/* <input type="text" className="border border-gray w-100" onChange={(e)=>handleInputChange(e,`employeeType`)} onKeyDown={(e)=>handleKeyDown(e,'employeeType')} /> */}
                </th>
                <th>
                  <ValidatedInput
                    name="jobMatrixSearch"
                    className="form-control-sm"
                    type="select"
                    value={selectedJobMatrixType}
                    onChange={handleJobMatrixTypeChange}
                  >
                    <option value="" key="0" />
                    {hrCodes
                      ? hrCodes
                          .filter(outerEntity => outerEntity.codeType === 'JMX' && outerEntity.id % 100 > 0)
                          .sort((a, b) => a.id - b.id)
                          .map(otherEntity => (
                            <option value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                              {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                            </option>
                          ))
                      : null}
                  </ValidatedInput>
                  {/* <input type="text" className="border border-gray w-100" onChange={(e)=>handleInputChange(e,`employeeType`)} onKeyDown={(e)=>handleKeyDown(e,'employeeType')} /> */}
                </th>
                <th>
                  <input type="text" className="border border-gray w-100 form-control-sm" disabled />
                </th>
                <th>
                  <input
                    type="text"
                    className="border border-gray w-100 form-control-sm"
                    onChange={e => handleInputChange(e, `reportingLine`)}
                    onKeyDown={e => handleRepLineChange(e, 'reportingLine')}
                  />
                </th>
                {/* <th>
                  <Translate contentKey="hrmsApp.employee.financeLine">Finance Line</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}
                <th>
                  <Translate contentKey="hrmsApp.employee.adhocLine">Adhoc Line</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <ValidatedInput
                    name="employeeStatusSearch"
                    className="form-control-sm"
                    type="select"
                    value={selectedEmpStatus}
                    onChange={handleEmpStatusChange}
                  >
                    <option value="" key="0" />
                    {hrCodes
                      ? hrCodes
                          .filter(outerEntity => outerEntity.codeType === 'EMS' && outerEntity.id % 100 > 0)
                          .sort((a, b) => a.id - b.id)
                          .map(otherEntity => (
                            <option value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                              {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                            </option>
                          ))
                      : null}
                  </ValidatedInput>
                  {/* <input type="text" className="border border-gray w-100 form-control-sm" onChange={(e)=>handleInputChange(e,`employeeStatus`)} onKeyDown={(e)=>handleKeyDown(e,'employeeStatus')} /> */}
                </th>
                <th />
              </tr>
              <tr className="table-info">
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hrmsApp.employee.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('qlid')}>
                  <Translate contentKey="hrmsApp.employee.qlid">Qlid</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('qlid')} />
                </th>
                <th className="hand" onClick={sort('firstName')}>
                  <Translate contentKey="hrmsApp.employee.firstName">First Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('firstName')} />
                </th>
                <th className="hand" onClick={sort('lastName')}>
                  <Translate contentKey="hrmsApp.employee.lastName">Last Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('lastName')} />
                </th>
                <th className="hand" onClick={sort('dob')}>
                  <Translate contentKey="hrmsApp.employee.dob">Dob</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('dob')} />
                </th>
                <th className="hand" onClick={sort('joiningDate')}>
                  <Translate contentKey="hrmsApp.employee.joiningDate">Joining Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('joiningDate')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.employee.employeeType">Employee Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.employee.jobMatrix">Job Matrix</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.employee.user">User</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.employee.reportingLine">Reporting Line</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                {/* <th>
                  <Translate contentKey="hrmsApp.employee.financeLine">Finance Line</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}
                <th>
                  <Translate contentKey="hrmsApp.employee.adhocLine">Adhoc Line</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.employee.employeeStatus">Employee Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {selectedList.map((employee, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/employee/${employee.id}`} color="link" size="sm">
                      {employee.id}
                    </Button>
                  </td>
                  <td>{employee.qlid}</td>
                  <td>{employee.firstName}</td>
                  <td>{employee.lastName}</td>
                  <td>{employee.dob ? <TextFormat type="date" value={employee.dob} format={APP_LOCAL_DATE_FORMAT} /> : null}</td>
                  <td>
                    {employee.joiningDate ? <TextFormat type="date" value={employee.joiningDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {employee.employeeType ? (
                      <span className="text-primary" onClick={() => showHrCodeModal(hrCodes.find(e => e.id === employee.employeeType.id))}>
                        {hrCodes.find(e => e.id === employee.employeeType.id)?.codeName}{' '}
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {employee.jobMatrix ? (
                      <span className="text-primary" onClick={() => showHrCodeModal(hrCodes.find(e => e.id === employee.jobMatrix.id))}>
                        {hrCodes.find(e => e.id === employee.jobMatrix.id)?.codeName}
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{employee.user ? employee.user.login : ''}</td>
                  <td>
                    {employee.reportingLine ? (
                      <span
                        className="text-primary"
                        onClick={() => showEmployeeModal(employees.find(e => e.id === employee.reportingLine.id))}
                      >
                        {employees.find(e => e.id === employee.reportingLine.id)?.firstName}{' '}
                        {employees.find(e => e.id === employee.reportingLine.id)?.lastName}
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                  {/* <td>{employee.financeLine ? <Link to={`/employee/${employee.financeLine.id}`}>{employee.financeLine.id}</Link> : ''}</td> */}
                  <td>
                    {employee.adhocLine ? (
                      <span className="text-primary" onClick={() => showEmployeeModal(employees.find(e => e.id === employee.adhocLine.id))}>
                        {employees.find(e => e.id === employee.adhocLine.id)?.firstName}{' '}
                        {employees.find(e => e.id === employee.adhocLine.id)?.lastName}
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {employee.employeeStatus ? (
                      <span
                        className="text-primary"
                        onClick={() => showHrCodeModal(hrCodes.find(e => e.id === employee.employeeStatus.id))}
                      >
                        {hrCodes.find(e => e.id === employee.employeeStatus.id)?.codeName}{' '}
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      {/* <Button tag={Link} to={`/employee/${employee.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button> */}
                      <Button tag={Link} to={`/employee/${employee.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span> */}
                      </Button>
                      <Button
                        onClick={() => (window.location.href = `/employee/${employee.id}/delete`)}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span> */}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.employee.home.notFound">No Employees found</Translate>
            </div>
          )
        )}
      </div>
      {selectedHrCode && <HrCodeModal hrCodeEntity={selectedHrCode} isOpen={isHrCodeModalOpen} toggle={toggleHrCodeModal} />}
      {selectedEmployee && <EmployeeModal employeeEntity={selectedEmployee} isOpen={isEmployeeModalOpen} toggle={toggleEmployeeModal} />}
    </div>
  );
};

export default Employee;
