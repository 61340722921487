import dayjs from 'dayjs';
import { IAllowanceItem } from 'app/shared/model/allowance-item.model';

export interface IAllowanceItemLog {
  id?: number;
  oldVal?: number;
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs;
  allowanceItem?: IAllowanceItem;
}

export const defaultValue: Readonly<IAllowanceItemLog> = {};
