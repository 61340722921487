import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import EmpProfile from './emp-profile';
import EmpProfileDeleteDialog from './emp-profile-delete-dialog';
import EmpProfileDetail from './emp-profile-detail';
import EmpProfileUpdate from './emp-profile-update';

const EmpProfileRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<EmpProfile />} />
    <Route path="new" element={<EmpProfileUpdate />} />
    <Route path=":id">
      <Route index element={<EmpProfileDetail />} />
      <Route path="edit" element={<EmpProfileUpdate />} />
      <Route path="delete" element={<EmpProfileDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default EmpProfileRoutes;
