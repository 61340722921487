import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, getPaginationState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import { getEntities } from './emp-qualification.reducer';

export const EmpQualificationHome = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const empQualificationList = useAppSelector(state => state.empQualification.entities);
  const loading = useAppSelector(state => state.empQualification.loading);
  const totalItems = useAppSelector(state => state.empQualification.totalItems);

  useEffect(() => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `passedOn,desc`,
      }),
    );
  }, []);

  // const getAllEntities = () => {
  //   dispatch(
  //     getEntities({
  //       page: paginationState.activePage - 1,
  //       size: paginationState.itemsPerPage,
  //       sort: `${paginationState.sort},${paginationState.order}`,
  //     }),
  //   );
  // };

  // const sortEntities = () => {
  //   getAllEntities();
  //   const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
  //   if (pageLocation.search !== endURL) {
  //     navigate(`${pageLocation.pathname}${endURL}`);
  //   }
  // };

  // useEffect(() => {
  //   sortEntities();
  // }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  // useEffect(() => {
  //   const params = new URLSearchParams(pageLocation.search);
  //   const page = params.get('page');
  //   const sort = params.get(SORT);
  //   if (page && sort) {
  //     const sortSplit = sort.split(',');
  //     setPaginationState({
  //       ...paginationState,
  //       activePage: +page,
  //       sort: sortSplit[0],
  //       order: sortSplit[1],
  //     });
  //   }
  // }, [pageLocation.search]);

  // const sort = p => () => {
  //   setPaginationState({
  //     ...paginationState,
  //     order: paginationState.order === ASC ? DESC : ASC,
  //     sort: p,
  //   });
  // };

  // const handlePagination = currentPage =>
  //   setPaginationState({
  //     ...paginationState,
  //     activePage: currentPage,
  //   });

  // const handleSyncList = () => {
  //   sortEntities();
  // };

  // const getSortIconByFieldName = (fieldName: string) => {
  //   const sortFieldName = paginationState.sort;
  //   const order = paginationState.order;
  //   if (sortFieldName !== fieldName) {
  //     return faSort;
  //   } else {
  //     return order === ASC ? faSortUp : faSortDown;
  //   }
  // };

  return (
    <div>
      <h5 id="emp-qualification-heading" data-cy="EmpQualificationHeading" className="text-center fw-bold text-primary p-1">
        <Translate contentKey="hrmsApp.empQualification.home.title">Emp Qualifications</Translate>
        <div className="d-flex justify-content-end">
          {/* <Button className="btn-sm me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="hrmsApp.empQualification.home.refreshListLabel">Refresh</Translate>
          </Button> */}
          <Link
            to="/emp-qualification/new"
            className="btn btn-sm btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="hrmsApp.empQualification.home.createLabel">Create new Emp Qualification</Translate>
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {empQualificationList && empQualificationList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr className="table-info">
                {/* <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hrmsApp.empQualification.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th> */}
                <th className="hand">
                  <Translate contentKey="hrmsApp.empQualification.degreeLevel">Degree Level</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empQualification.degreeName">Degree Name</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empQualification.majorSub">Major Sub</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empQualification.institute">Institute</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empQualification.passGrade">Pass Grade</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empQualification.passedOn">Passed On</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empQualification.anyAcheivement">Any Acheivement</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empQualification.isLast">Is Last</Translate>{' '}
                </th>
                {/* <th>
                  <Translate contentKey="hrmsApp.empQualification.employee">Employee</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}
                <th />
              </tr>
            </thead>
            <tbody>
              {empQualificationList.map((empQualification, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  {/* <td>
                    <Button tag={Link} to={`/emp-qualification/${empQualification.id}`} color="link" size="sm">
                      {empQualification.id}
                    </Button>
                  </td> */}
                  <td>{empQualification.degreeLevel}</td>
                  <td>{empQualification.degreeName}</td>
                  <td>{empQualification.majorSub}</td>
                  <td>{empQualification.institute}</td>
                  <td>{empQualification.passGrade}</td>
                  <td>
                    {empQualification.passedOn ? (
                      <TextFormat type="date" value={empQualification.passedOn} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{empQualification.anyAcheivement}</td>
                  <td>{empQualification.isLast ? 'true' : 'false'}</td>
                  {/* <td>
                    {empQualification.employee ? (
                      <Link to={`/employee/${empQualification.employee.id}`}>{empQualification.employee.id}</Link>
                    ) : (
                      ''
                    )}
                  </td> */}
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      {/* <Button
                        tag={Link}
                        to={`/emp-qualification/${empQualification.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button> */}
                      <Button
                        tag={Link}
                        to={`/emp-qualification/${empQualification.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span> */}
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/emp-qualification/${empQualification.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span> */}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.empQualification.home.notFound">No Emp Qualifications found</Translate>
            </div>
          )
        )}
      </div>
      {/* {totalItems ? (
        <div className={empQualificationList && empQualificationList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )} */}
    </div>
  );
};

export default EmpQualificationHome;
