import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import BusinessClient from './business-client';
import BusinessClientDetail from './business-client-detail';
import BusinessClientUpdate from './business-client-update';
import BusinessClientDeleteDialog from './business-client-delete-dialog';

const BusinessClientRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<BusinessClient />} />
    <Route path="new" element={<BusinessClientUpdate />} />
    <Route path=":id">
      <Route index element={<BusinessClientDetail />} />
      <Route path="edit" element={<BusinessClientUpdate />} />
      <Route path="delete" element={<BusinessClientDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default BusinessClientRoutes;
