import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { Translate, getSortState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import { getEntities } from './country-workday.reducer';

export const CountryWorkday = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const countryWorkdayList = useAppSelector(state => state.countryWorkday.entities);
  const loading = useAppSelector(state => state.countryWorkday.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h5 id="country-workday-heading" data-cy="CountryWorkdayHeading" className="text-center text-primary fw-bold p-1">
        <Translate contentKey="hrmsApp.countryWorkday.home.title">Country Workdays</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          </Button>
          <Link to="/country-workday/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {hrCodes && hrCodes.length > 0 && countryWorkdayList && countryWorkdayList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="hrmsApp.countryWorkday.country">Country</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('monOn')}>
                  <Translate contentKey="hrmsApp.countryWorkday.monOn">Mon On</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('monOn')} />
                </th>
                <th className="hand" onClick={sort('tueOn')}>
                  <Translate contentKey="hrmsApp.countryWorkday.tueOn">Tue On</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('tueOn')} />
                </th>
                <th className="hand" onClick={sort('wedOn')}>
                  <Translate contentKey="hrmsApp.countryWorkday.wedOn">Wed On</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('wedOn')} />
                </th>
                <th className="hand" onClick={sort('thuOn')}>
                  <Translate contentKey="hrmsApp.countryWorkday.thuOn">Thu On</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('thuOn')} />
                </th>
                <th className="hand" onClick={sort('friOn')}>
                  <Translate contentKey="hrmsApp.countryWorkday.friOn">Fri On</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('friOn')} />
                </th>
                <th className="hand" onClick={sort('satOn')}>
                  <Translate contentKey="hrmsApp.countryWorkday.satOn">Sat On</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('satOn')} />
                </th>
                <th className="hand" onClick={sort('sunOn')}>
                  <Translate contentKey="hrmsApp.countryWorkday.sunOn">Sun On</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('sunOn')} />
                </th>
                <th className="hand" onClick={sort('timeZone')}>
                  <Translate contentKey="hrmsApp.countryWorkday.timeZone">Time Zone</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('timeZone')} />
                </th>
                <th className="hand" onClick={sort('remarks')}>
                  <Translate contentKey="hrmsApp.countryWorkday.remarks">Remarks</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('remarks')} />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {countryWorkdayList.map((countryWorkday, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td className="text-start">{countryWorkday.country ? hrCodes.find(e=>e.id===countryWorkday.country.id)?.codeName : ''}</td>
                  <td>{countryWorkday.monOn ? '✔' : 'X'}</td>
                  <td>{countryWorkday.tueOn ? '✔' : 'X'}</td>
                  <td>{countryWorkday.wedOn ? '✔' : 'X'}</td>
                  <td>{countryWorkday.thuOn ? '✔' : 'X'}</td>
                  <td>{countryWorkday.friOn ? '✔' : 'X'}</td>
                  <td>{countryWorkday.satOn ? '✔' : 'X'}</td>
                  <td>{countryWorkday.sunOn ? '✔' : 'X'}</td>
                  <td>{countryWorkday.timeZone}</td>
                  <td className="text-start">{countryWorkday.remarks}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/country-workday/${countryWorkday.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                      </Button>
                      <Button onClick={() => (window.location.href = `/country-workday/${countryWorkday.id}/delete`)} color="danger" size="sm" data-cy="entityDeleteButton">
                        <FontAwesomeIcon icon="trash" />{' '}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.countryWorkday.home.notFound">No Country Workdays found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default CountryWorkday;
