import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';

const YearWeekFilter = ({ onSubmit, startYear = 2000, endYear = new Date().getFullYear() }) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedWeek, setSelectedWeek] = useState(1);
  const [weeks, setWeeks] = useState([]);

  // Generate years dynamically
  const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);

  // Update available weeks based on the selected year
  useEffect(() => {
    const weeksInYear = getWeeksInYear(selectedYear);
    setWeeks(Array.from({ length: weeksInYear }, (_, i) => i + 1));
    setSelectedWeek(1); // Reset week selection when year changes
  }, [selectedYear]);

  // Function to calculate number of weeks in a given year
  const getWeeksInYear = year => {
    const lastDay = new Date(year, 11, 31);
    return lastDay.getDay() === 4 ? 53 : 52;
  };

  // Handle form submission
  const handleSubmit = e => {
    e.preventDefault();
    onSubmit({ year: selectedYear, week: selectedWeek });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="g-2 align-items-center">
        {' '}
        {/* Added align-items-center */}
        <Col xs={12} md={4}>
          <FormGroup>
            <Label for="yearSelect">Select Year</Label>
            <Input type="select" id="yearSelect" value={selectedYear} onChange={e => setSelectedYear(Number(e.target.value))}>
              {years.map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col xs={12} md={4}>
          <FormGroup>
            <Label for="weekSelect">Select Week</Label>
            <Input type="select" id="weekSelect" value={selectedWeek} onChange={e => setSelectedWeek(Number(e.target.value))}>
              {weeks.map(week => (
                <option key={week} value={week}>
                  Week {week}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col xs={12} md={4} className="d-flex align-items-end mt-4">
          <Button type="submit" color="primary" className="w-100 py-2.5">
            {' '}
            {/* Added mt-3 mt-md-0 */}
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default YearWeekFilter;
