import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { overridePaginationStateWithQueryParams, overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, ValidatedField, getPaginationState, getSortState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, FormGroup, Input, Label, Table } from 'reactstrap';

import HrCodeModal from '../hr-code/hr-code-modal';
import { getEntities, updateEntity } from './allowance-plan.reducer';

export const AllowancePlanRow = ({ allowancePlan, paginationState }: { allowancePlan: any; paginationState: any }) => {
  const dispatch = useAppDispatch();
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const [allowancePlanStatus, setAllowancePlanStatus] = useState(true);

  const [selectedHrCode, setSelectedHrCode] = useState(null);
  const [isHrCodeModalOpen, setHrCodeModalOpen] = useState(false);
  const toggleHrCodeModal = () => {
    setHrCodeModalOpen(!isHrCodeModalOpen);
  };

  const showHrCodeModal = detail => {
    setSelectedHrCode(detail);
    console.log('-----> val: ', detail.codeType, detail.codeName, detail.parent?.id);
    toggleHrCodeModal();
  };

  useEffect(() => {
    if (allowancePlan) {
      const allowanceItemStatusCode = hrCodes.find(e => e.id === allowancePlan?.allowanceStatus?.id);
      setAllowancePlanStatus(allowanceItemStatusCode.codeName === 'Active' ? true : false);
    }
  }, [allowancePlan]);

  // useEffect(() => {
  //   if (allowancePlanStatus) {
  //     const allowanceItemStatusCode = hrCodes.find(e => e.id === allowancePlan?.allowanceStatus?.id);
  //     setAllowancePlanStatus(allowanceItemStatusCode.codeName === 'Active' ? true : false);
  //   }
  // }, [allowancePlanStatus]);

  const deactivateAllowancePlan = () => {
    console.log('Allwance Plan Deactivate .....', allowancePlanStatus);
    const updatedAllowancePlan = { ...allowancePlan };

    updatedAllowancePlan['allowanceStatus'] = allowancePlanStatus
      ? hrCodes.find(e => e.codeType === 'ALS' && e.codeName === 'Disabled')
      : hrCodes.find(e => e.codeType === 'ALS' && e.codeName === 'Active');

    // console.log(updatedAllowancePlan);

    dispatch(updateEntity(updatedAllowancePlan));
  };

  return (
    <>
      <tr key={`entity-${allowancePlan.id}`} data-cy="entityTable">
        <td>
          <Button tag={Link} to={`/allowance-plan/${allowancePlan.id}`} color="link" size="sm">
            {allowancePlan.id}
          </Button>
        </td>
        <td>
          {allowancePlan.allowancePlan ? (
            <span
              className="text-primary fw-bold"
              onClick={() => showHrCodeModal(hrCodes.find(e => e.id === allowancePlan.allowancePlan.id))}
            >
              {hrCodes.find(e => e.id === allowancePlan.allowancePlan.id)?.codeName}{' '}
            </span>
          ) : (
            ''
          )}
        </td>
        <td>
          {allowancePlan.startDate ? <TextFormat type="date" value={allowancePlan.startDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
        </td>
        <td>{allowancePlan.endDate ? <TextFormat type="date" value={allowancePlan.endDate} format={APP_LOCAL_DATE_FORMAT} /> : null}</td>
        <td>
          {allowancePlan.allowanceStatus ? (
            <span
              className="text-primary fw-bold"
              onClick={() => showHrCodeModal(hrCodes.find(e => e.id === allowancePlan.allowanceStatus.id))}
            >
              {hrCodes.find(e => e.id === allowancePlan.allowanceStatus.id)?.codeName}{' '}
            </span>
          ) : (
            ''
          )}
        </td>
        <td className="text-end">
          <div className="btn-group flex-btn-group-container">
            {(hrCodes.find(e => e.id === allowancePlan?.allowanceStatus?.id)?.codeName === 'Active' ||
              hrCodes.find(e => e.id === allowancePlan?.allowanceStatus?.id)?.codeName === 'Disabled') && (
              <FormGroup switch>
                <Input
                  type="switch"
                  checked={allowancePlanStatus}
                  onClick={() => {
                    setAllowancePlanStatus(!allowancePlanStatus);
                    deactivateAllowancePlan();
                  }}
                />
                <Label></Label>
              </FormGroup>
            )}
            <Button tag={Link} to={`/allowance-plan/${allowancePlan.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
              {' '}
              <FontAwesomeIcon icon="pencil-alt" />{' '}
            </Button>
            <Button
              onClick={() => (window.location.href = `/allowance-plan/${allowancePlan.id}/delete`)}
              color="danger"
              size="sm"
              data-cy="entityDeleteButton"
            >
              {' '}
              <FontAwesomeIcon icon="trash" />{' '}
            </Button>
          </div>
        </td>
      </tr>

      {selectedHrCode && <HrCodeModal hrCodeEntity={selectedHrCode} isOpen={isHrCodeModalOpen} toggle={toggleHrCodeModal} />}
    </>
  );
};

export const AllowancePlan = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const allowancePlanList = useAppSelector(state => state.allowancePlan.entities);
  const loading = useAppSelector(state => state.allowancePlan.loading);

  const [selectedOption, setSelectedOption] = useState('');
  const [selectedList, setSelectedList] = useState([]);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  /* eslint no-console: off */
  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
    dispatch(getHrCodes({}));
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  useEffect(() => {
    setSelectedList(allowancePlanList);
  }, [allowancePlanList]);

  const handleSelectChange = event => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };

  useEffect(() => {
    setSelectedList(
      selectedOption ? allowancePlanList.filter(it => it.allowancePlan.id === parseInt(selectedOption, 10)) : allowancePlanList,
    );
  }, [selectedOption]);

  // useEffect(() => {
  //   console.log('------- selected, allowancePlan, selectedList: ', selectedOption, allowancePlanList.length, selectedList.length);
  // }, [selectedList]);

  return (
    <div>
      <h5 id="allowance-plan-heading" data-cy="AllowancePlanHeading" className="text-center text-primary fw-bold m-0 p-0">
        <Translate contentKey="hrmsApp.allowancePlan.home.title">Allowance Plans</Translate>
        <div className="d-flex mt-3 justify-content-between align-items-start">
          <ValidatedField
            // id="allowance-plan-allowancePlan"
            name="allowancePlanSearch"
            type="select"
            value={selectedOption}
            onChange={handleSelectChange}
          >
            <option value="" key="0" />
            {hrCodes
              ? hrCodes
                  .filter(outerEntity => outerEntity.codeType === 'ALP' && outerEntity.id % 100 > 0)
                  .sort((a, b) => a.id - b.id)
                  .map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                      {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                    </option>
                  ))
              : null}
          </ValidatedField>
          <div className="d-flex">
            <Button className="btn-sm me-2" color="info" onClick={handleSyncList} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading} />{' '}
              <Translate contentKey="hrmsApp.allowancePlan.home.refreshListLabel">Refresh</Translate>
            </Button>
            <Link
              to="/allowance-plan/new"
              className="btn btn-sm btn-primary jh-create-entity"
              id="jh-create-entity"
              data-cy="entityCreateButton"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="hrmsApp.allowancePlan.home.createLabel">Create new Allowance Plan</Translate>
            </Link>
          </div>
        </div>
      </h5>
      <div className="table-responsive">
        {hrCodes && hrCodes.length > 0 && selectedList && selectedList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr className="table-info">
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hrmsApp.allowancePlan.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.allowancePlan.allowancePlan">Allowance Plan</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('startDate')}>
                  <Translate contentKey="hrmsApp.allowancePlan.startDate">Start Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('startDate')} />
                </th>
                <th className="hand" onClick={sort('endDate')}>
                  <Translate contentKey="hrmsApp.allowancePlan.endDate">End Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('endDate')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.allowancePlan.allowanceStatus">Allowance Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {selectedList.map((allowancePlan, i) => (
                <AllowancePlanRow key={allowancePlan.id} allowancePlan={allowancePlan} paginationState={paginationState} />
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.allowancePlan.home.notFound">No Allowance Plans found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default AllowancePlan;
