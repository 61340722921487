import React from 'react';
import Chart from 'react-google-charts';

const TimeSheetDetailPieChart = ({ data, width = 500, height = 500 }) => {
  // Transform PieChartData into Google Charts format
  const gdata = [
    ['Task', 'Hours'], // Header row
    ...data.map(({ label, value }) => [label, value]),
  ];

  const options = {
    title: 'Time Sheet Breakdown',
    is3D: true,
    slices: {
      0: { offset: 0.1 }, // Optional: Adds slight offset for the first slice
    },
  };

  return (
    <Chart
      chartType="PieChart" // Using PieChart
      data={gdata}
      options={options}
    />
  );
};

export default TimeSheetDetailPieChart;
