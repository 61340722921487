import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, getSortState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table, UncontrolledTooltip } from 'reactstrap';

import { getEntities as getBusinessClients } from 'app/entities/business-client/business-client.reducer';
import { getEntities } from './business-project.reducer';

export const BusinessProject = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const businessProjectList = useAppSelector(state => state.businessProject.entities);
  const businessClients = useAppSelector(state => state.businessClient.entities);
  const employees = useAppSelector(state => state.employee.entities);
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const loading = useAppSelector(state => state.businessProject.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
    dispatch(getBusinessClients({}));
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h5 id="business-project-heading" data-cy="BusinessProjectHeading" className="text-center text-primary fw-bold p-1">
        <Translate contentKey="hrmsApp.businessProject.home.title">Business Projects</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            {/* <Translate contentKey="hrmsApp.businessProject.home.refreshListLabel">Refresh List</Translate> */}
          </Button>
          <Link to="/business-project/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            {/* <Translate contentKey="hrmsApp.businessProject.home.createLabel">Create new Business Project</Translate> */}
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {businessProjectList && businessProjectList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hrmsApp.businessProject.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('projectName')}>
                  <Translate contentKey="hrmsApp.businessProject.projectName">Project Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('projectName')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.businessProject.projectType">Project Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.businessProject.mainClient">Main Client</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.businessProject.partnerClient">Partner Client</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.businessProject.industry">Industry</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.businessProject.engagementType">Engagement Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.businessProject.engagementStatus">Engagement Status</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.businessProject.department">Department</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.businessProject.location">Location</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.businessProject.projectManager">Project Manager</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.businessProject.adhocManager">Adhoc Manager</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('startDate')}>
                  <Translate contentKey="hrmsApp.businessProject.startDate">Start Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('startDate')} />
                </th>
                <th className="hand" onClick={sort('endDate')}>
                  <Translate contentKey="hrmsApp.businessProject.endDate">End Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('endDate')} />
                </th>
                <th className="hand" onClick={sort('remarks')}>
                  <Translate contentKey="hrmsApp.businessProject.remarks">Remarks</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('remarks')} />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {businessProjectList.map((businessProject, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>{businessProject.id}</td>
                  <td>{businessProject.projectName}</td>
                  <td>{businessProject.projectType ? hrCodes.find(e => e.id === businessProject.projectType.id).codeName : ''}</td>
                  <td>
                    {' '}
                    {businessProject.mainClient ? (
                      <>
                        <span id={`tooltip-mainclient-${businessProject.id}`}>
                          {businessClients.find(e => e.id === businessProject.mainClient.id)?.clientCode}
                        </span>
                        <UncontrolledTooltip
                          placement="top"
                          className="bg-info text-primary"
                          target={`tooltip-mainclient-${businessProject.id}`}
                        >
                          {businessClients.find(e => e.id === businessProject.mainClient.id)?.clientName}
                        </UncontrolledTooltip>
                      </>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {' '}
                    {businessProject.partnerClient ? (
                      <>
                        <span id={`tooltip-partnerclient-${businessProject.id}`}>
                          {businessClients.find(e => e.id === businessProject.partnerClient.id)?.clientCode}
                        </span>
                        <UncontrolledTooltip
                          placement="top"
                          className="bg-info text-primary"
                          target={`tooltip-partnerclient-${businessProject.id}`}
                        >
                          {businessClients.find(e => e.id === businessProject.partnerClient.id)?.clientName}
                        </UncontrolledTooltip>
                      </>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{businessProject.industry ? hrCodes.find(e => e.id === businessProject.industry.id).codeName : ''}</td>
                  <td>{businessProject.engagementType ? hrCodes.find(e => e.id === businessProject.engagementType.id).codeName : ''}</td>
                  <td>
                    {businessProject.engagementStatus ? hrCodes.find(e => e.id === businessProject.engagementStatus.id).codeName : ''}
                  </td>
                  <td>{businessProject.department ? hrCodes.find(e => e.id === businessProject.department.id).codeName : ''}</td>
                  <td>{businessProject.location ? hrCodes.find(e => e.id === businessProject.location.id).codeName : ''}</td>
                  <td>
                    {businessProject.projectManager
                      ? `${employees.find(e => e.id === businessProject.projectManager.id)?.firstName} ${employees.find(e => e.id === businessProject.projectManager.id)?.lastName}`
                      : ''}
                  </td>
                  <td>
                    {businessProject.adhocManager
                      ? `${employees.find(e => e.id === businessProject.adhocManager.id)?.firstName} ${employees.find(e => e.id === businessProject.adhocManager.id)?.lastName}`
                      : ''}
                  </td>
                  <td>
                    {businessProject.startDate ? (
                      <TextFormat type="date" value={businessProject.startDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {businessProject.endDate ? (
                      <TextFormat type="date" value={businessProject.endDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{businessProject.remarks}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/business-project/${businessProject.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                      </Button>
                      <Button
                        onClick={() => (window.location.href = `/business-project/${businessProject.id}/delete`)}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.businessProject.home.notFound">No Business Projects found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default BusinessProject;
