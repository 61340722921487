import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getProjectTasks } from 'app/entities/project-task/project-task.reducer';
import { getEntities as getProjects } from 'app/entities/business-project/business-project.reducer';
import { getEntity, updateEntity, createEntity, reset } from './task-assignment.reducer';
import { getEntities as getClients } from '../business-client/business-client.reducer';

export const TaskAssignmentUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const projectTasks = useAppSelector(state => state.projectTask.entities);
  const businessProjectList = useAppSelector(state => state.businessProject.entities);
  const businessClients = useAppSelector(state => state.businessClient.entities);
  const employees = useAppSelector(state => state.employee.entities);
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const taskAssignmentEntity = useAppSelector(state => state.taskAssignment.entity);
  const loading = useAppSelector(state => state.taskAssignment.loading);
  const updating = useAppSelector(state => state.taskAssignment.updating);
  const updateSuccess = useAppSelector(state => state.taskAssignment.updateSuccess);

  const handleClose = () => {
    navigate('/task-assignment');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getClients({}));
    dispatch(getProjectTasks({}));
    dispatch(getProjects({ sort: 'id,desc' }));
    // dispatch(getEmployees({}));
    // dispatch(getHrCodes({}));
  }, []);

  const [hrCodesWKS, setHrCodesWKS] = useState([]);

  useEffect(() => {
    const hrCodesWKSLIST = hrCodes.filter(it => it.codeType === 'WKS');
    setHrCodesWKS(hrCodesWKSLIST);
  }, [hrCodes]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const [selectedOption, setSelectedOption] = useState<number>();

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.hoursAssigned !== undefined && typeof values.hoursAssigned !== 'number') {
      values.hoursAssigned = Number(values.hoursAssigned);
    }

    const entity = {
      ...taskAssignmentEntity,
      ...values,
      projectTask: projectTasks.find(it => it.id.toString() === values.projectTask?.toString()),
      employee: employees.find(it => it.id.toString() === values.employee?.toString()),
      status: hrCodes.find(it => it.id.toString() === values.status?.toString() && it.codeType === 'WKS'),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...taskAssignmentEntity,
          projectTask: taskAssignmentEntity?.projectTask?.id,
          employee: taskAssignmentEntity?.employee?.id,
          status: taskAssignmentEntity?.status?.id,
        };

  const handleSelectionChange = event => {
    // console.log('------------- selectedId: ', event.target.value);
    // handleCloseTask();
    setSelectedOption(Number.isNaN(parseInt(event.target.value, 10)) ? null : parseInt(event.target.value, 10));
  };
  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hrmsApp.taskAssignment.home.createOrEditLabel" data-cy="TaskAssignmentCreateUpdateHeading">
            <Translate contentKey="hrmsApp.taskAssignment.home.createOrEditLabel">Create or edit a TaskAssignment</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="task-assignment-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('hrmsApp.taskAssignment.hoursAssigned')}
                id="task-assignment-hoursAssigned"
                name="hoursAssigned"
                data-cy="hoursAssigned"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  max: { value: 2000, message: translate('entity.validation.max', { max: 2000 }) },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.taskAssignment.remarks')}
                id="task-assignment-remarks"
                name="remarks"
                data-cy="remarks"
                type="text"
              />
              <ValidatedField
                id="task-assignment-businessProject"
                name="businessProject"
                data-cy="businessProject"
                label={translate('hrmsApp.taskAssignment.businessProject')}
                type="select"
                value={selectedOption}
                onChange={handleSelectionChange}
                required
              >
                <option value="" key="0" />
                {businessProjectList
                  .filter(e => e.engagementStatus.id === hrCodes.find(it => it.codeType === 'WKS' && it.codeName === 'Active')?.id)
                  .sort((a, b) => a.startDate - b.startDate) /* a.projectName.localeCompare(b.projectName))*/
                  .map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.startDate} - {otherEntity.projectName} -
                      {businessClients.find(e => e.id === otherEntity.mainClient.id)?.clientName}(
                      {hrCodes.find(e => e.id === businessClients.find(t => t.id === otherEntity.mainClient.id)?.country.id)?.codeName})
                      {otherEntity.partnerClient
                        ? `- (${businessClients.find(e => e.id === otherEntity.partnerClient.id)?.clientName})`
                        : ''}
                      {/* {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''} */}
                    </option>
                  ))}
              </ValidatedField>
              <ValidatedField
                id="task-assignment-projectTask"
                name="projectTask"
                data-cy="projectTask"
                label={translate('hrmsApp.taskAssignment.projectTask')}
                type="select"
                required
              >
                <option value="" key="0" />
                {projectTasks
                  ? projectTasks
                      .filter(e => e.businessProject.id === selectedOption)
                      .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.taskName}
                        </option>
                      ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="task-assignment-employee"
                name="employee"
                data-cy="employee"
                label={translate('hrmsApp.taskAssignment.employee')}
                type="select"
                required
              >
                <option value="" key="0" />
                {employees
                  ? employees.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.firstName} {otherEntity.lastName} ({otherEntity.qlid})
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="task-assignment-status"
                name="status"
                data-cy="status"
                label={translate('hrmsApp.taskAssignment.status')}
                type="select"
                required
              >
                <option value="" key="0" />
                {hrCodesWKS
                  ? hrCodesWKS.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/task-assignment" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TaskAssignmentUpdate;
