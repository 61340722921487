import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import LeavesPlan from './leaves-plan';
import LeavesPlanDetail from './leaves-plan-detail';
import LeavesPlanUpdate from './leaves-plan-update';
import LeavesPlanDeleteDialog from './leaves-plan-delete-dialog';

const LeavesPlanRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<LeavesPlan />} />
    <Route path="new" element={<LeavesPlanUpdate />} />
    <Route path=":id">
      <Route index element={<LeavesPlanDetail />} />
      <Route path="edit" element={<LeavesPlanUpdate />} />
      <Route path="delete" element={<LeavesPlanDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default LeavesPlanRoutes;
