import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getAuthorities } from 'app/shared/reducers/authority-management';
import { useAppDispatch, useAppSelector } from 'app/config/store';

// import { getEntitiesList as getEmployees } from 'app/entities/employee/employee.reducer';
import { getUsers } from 'app/shared/reducers/user-management';
import { createEntity, getEntity, reset, updateEntity } from './employee.reducer';
import Multiselect from 'multiselect-react-dropdown';

export const EmployeeUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const users = useAppSelector(state => state.userManagement.users);
  const employees = useAppSelector(state => state.employee.entities);
  const employeeEntity = useAppSelector(state => state.employee.entity);
  const loading = useAppSelector(state => state.employee.loading);
  const updating = useAppSelector(state => state.employee.updating);
  const updateSuccess = useAppSelector(state => state.employee.updateSuccess);
  const authorityManagement = useAppSelector(state => state.authorityManagement.authorities);
  // const nextId

  const [roles, setRoles] = useState({
    options: authorityManagement,
    selectedValue: {},
  });

  const [selectedRole, setSelectedRole] = useState([]);

  const onSelect = (selectedList, selectedItem) => {
    setSelectedRole(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedRole(selectedList);
  };

  useEffect(() => {
    // Map authorityManagement to include id for each option
    const optionsWithId = authorityManagement.map((role, index) => ({
      name: role.name,
      id: index + 1, // Assigning a unique id (use actual id from API if available)
    }));

    const entityAuthorityNames = employeeEntity?.user?.authorities.map(auth => auth.name);

    setRoles(prevState => ({
      ...prevState,
      options: optionsWithId,
    }));

    // Prepopulate selected roles from the account
    const preselectedRoles = (entityAuthorityNames || []).map(roleName => {
      const matchedRole = optionsWithId.find(option => option.name === roleName);
      return matchedRole || { name: roleName, id: null }; // Handle roles not found in options
    });

    setRoles(prevState => ({
      ...prevState,
      selectedValue: preselectedRoles,
    }));

    setSelectedRole(preselectedRoles);
  }, [authorityManagement, employeeEntity]);

  const handleClose = () => {
    navigate('/employee' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getUsers({}));
    dispatch(getAuthorities({}));
    // dispatch(getHrCodes({}));
    // dispatch(getEmployees({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // Added by Shafqat
  const [employeeType, setEmployeeType] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [qlid, setQlid] = useState('');

  useEffect(() => {
    if (firstName && lastName) {
      const firstInitial = firstName.charAt(0).toUpperCase();
      const lastInitial = lastName.charAt(0).toUpperCase();
      if (employeeType) {
        setQlid(`${firstInitial}${lastInitial}${employeeType}`);
      } else {
        setQlid(`${firstInitial}${lastInitial}`);
      }
    } else {
      setQlid(''); // Set qlid to an empty string if either firstName or lastName is missing
    }
  }, [firstName, lastName]);
  // End Added
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    // let updatedUser;
    let updatedUser = isNew ? null : { ...users.find(it => it.id.toString() === values.user.toString()) };
    if (updatedUser) {
      updatedUser = { ...updatedUser, authorities: selectedRole.map(d => d.name) };
    }

    const entity = {
      ...employeeEntity,
      ...values,
      employeeType: hrCodes.find(it => it.id.toString() === values.employeeType.toString()),
      jobMatrix: hrCodes.find(it => it.id.toString() === values.jobMatrix.toString()),
      employeeStatus: isNew
        ? hrCodes.find(e => e.codeType === 'EMS' && e.codeName === 'Pending')
        : hrCodes.find(it => it.id.toString() === values.employeeStatus.toString()),
      // user: isNew ? null : users.find(it => it.id.toString() === values.user.toString()),
      user: updatedUser,
      reportingLine: isNew ? null : employees.find(it => it.id.toString() === values.reportingLine.toString()),
    };
    // if (new Date(entity.endDate) < new Date(entity.startDate)) {
    /* eslint no-console: off */
    const cutDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      dob = new Date(entity.dob.toString());
    console.log('------------ CurDate: ', new Date(), '---', new Date(dob.setFullYear(dob.getFullYear() + 18)));
    if (new Date(entity.dob) > cutDate) {
      document.getElementById('hr-code-message').innerHTML =
        '<span class="col-md-8 bg-danger">DoB can\'t be greater than ' + cutDate.toLocaleDateString() + '</span>';
      return;
    }
    if (entity.joiningDate > new Date() || entity.joiningDate < new Date(dob.setFullYear(dob.getFullYear() + 18))) {
      document.getElementById('hr-code-message').innerHTML =
        '<span class="col-md-8 bg-danger">Joining Date can\'t be in future or before 18 years of age.</span>';
      return;
    }
    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...employeeEntity,
          employeeType: employeeEntity?.employeeType?.id,
          jobMatrix: employeeEntity?.jobMatrix?.id,
          user: employeeEntity?.user?.id,
          reportingLine: employeeEntity?.reportingLine?.id,
          employeeStatus: employeeEntity?.employeeStatus?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5
            id="hrmsApp.employee.home.createOrEditLabel"
            data-cy="EmployeeCreateUpdateHeading"
            className="text-center fw-bold text-primary"
          >
            <Translate contentKey="hrmsApp.employee.home.createOrEditLabel">Create or edit a Employee</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center text-primary">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="employee-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                id="employee-employeeType"
                name="employeeType"
                data-cy="employeeType"
                label={translate('hrmsApp.employee.employeeType') + '*'}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                onChange={e => {
                  // Added by Shafqat
                  // const selectedHrCode = hrCodes.find(hrCode => hrCode.id.toString() === e.target.value);
                  // const codeDescription = selectedHrCode ? selectedHrCode.codeDescription.match(/\(([^)]+)\)/)[1] : '';
                  // setEmployeeType(codeDescription);
                  // //  setQlid(`${employeeEntity?.firstName || ''}-${employeeEntity?.lastName || ''}-${employeeTypeValue}`);
                }}
              >
                <option value="" key="0" />
                {hrCodes
                  ? hrCodes
                      .filter(outerEntity => outerEntity.codeType === 'EMT' && outerEntity.id % 100 > 0)
                      .sort((a, b) => a.id - b.id)
                      .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                          {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                        </option>
                      ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('hrmsApp.employee.firstName') + '*'}
                id="employee-firstName"
                name="firstName"
                data-cy="firstName"
                type="text"
                // onChange={e => setFirstName(e.target.value)} // Added by Shafqat
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.employee.lastName') + '*'}
                id="employee-lastName"
                name="lastName"
                data-cy="lastName"
                type="text"
                // onChange={e => setLastName(e.target.value)} // Added by Shafqat
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.employee.jobMatrix') + '*'}
                id="employee-jobMatrix"
                name="jobMatrix"
                data-cy="jobMatrix"
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hrCodes
                  ? hrCodes
                      .filter(outerEntity => outerEntity.codeType === 'JMX' && outerEntity.codeStatus === 'A' && outerEntity.id % 100 > 0)
                      .sort((a, b) => a.id - b.id)
                      .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}
                          {' - '}
                          {otherEntity.codeSeq}
                        </option>
                      ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('hrmsApp.employee.dob') + '*'}
                id="employee-dob"
                name="dob"
                data-cy="dob"
                type="date"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              {!isNew ? (
                <ValidatedField
                  label={translate('hrmsApp.employee.joiningDate')}
                  id="employee-joiningDate"
                  name="joiningDate"
                  data-cy="joiningDate"
                  type="date"
                />
              ) : null}
              {isNew ? (
                <ValidatedField
                  id="emp-profile-gender"
                  name="gender"
                  data-cy="gender"
                  label={translate('hrmsApp.empProfile.gender')}
                  type="select"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'GND' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
              ) : null}
              {isNew ? (
                <ValidatedField
                  label={translate('hrmsApp.empProfile.cnic')}
                  id="emp-profile-cnic"
                  name="cnic"
                  data-cy="cnic"
                  type="text"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                    min: { value: 1000000000001, message: translate('entity.validation.min', { min: 1000000000001 }) },
                    max: { value: 9999999999999, message: translate('entity.validation.max', { max: 9999999999999 }) },
                    validate: v => isNumber(v) || translate('entity.validation.number'),
                  }}
                />
              ) : null}
              {!isNew ? (
                <ValidatedField
                  id="employee-user"
                  name="user"
                  data-cy="user"
                  readOnly
                  label={translate('hrmsApp.employee.user')}
                  type="select"
                >
                  <option value="" key="0" />
                  {users
                    ? users.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.login}
                        </option>
                      ))
                    : null}
                </ValidatedField>
              ) : null}
              {!isNew ? (
                <ValidatedField
                  id="employee-reportingLine"
                  name="reportingLine"
                  data-cy="reportingLine"
                  label={translate('hrmsApp.employee.reportingLine')}
                  type="select"
                >
                  <option value="" key="0" />
                  {employees
                    ? employees.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.qlid} : {' (' + otherEntity.firstName + ' ' + otherEntity.lastName + ')'}
                        </option>
                      ))
                    : null}
                </ValidatedField>
              ) : null}
              <Multiselect
                className="my-5"
                placeholder="Select Role"
                options={roles.options} // Options to display in the dropdown
                selectedValues={roles.selectedValue} // Preselected value to persist in dropdown
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
              />
              {isNew
                ? null /* (
                <ValidatedField
                  id="employee-employeeStatus"
                  name="employeeStatus"
                  data-cy="employeeStatus"
                  label={translate('hrmsApp.employee.employeeStatus')}
                  type="select"
                  readonly
             //     required
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'EMS' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              )*/
                : null}
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/employee" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
      <Row id="hr-code-message" className="justify-content-center text-white m-0 py-1"></Row>
    </div>
  );
};

export default EmployeeUpdate;
