import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, getPaginationState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import { getEntities } from './emp-certification.reducer';

export const EmpCertificationHome = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const empCertificationList = useAppSelector(state => state.empCertification.entities);
  const loading = useAppSelector(state => state.empCertification.loading);
  const totalItems = useAppSelector(state => state.empCertification.totalItems);

  useEffect(() => {
    dispatch(getEntities({
      page: paginationState.activePage - 1,
      size: paginationState.itemsPerPage,
      sort: `toDate,desc`,
    }));
  }, []);

  return (
    <div>
      <h5 id="emp-certification-heading" data-cy="EmpCertificationHeading" className="text-center fw-bold text-primary p-1">
        <Translate contentKey="hrmsApp.empCertification.home.title">Emp Certifications</Translate>
        <div className="d-flex justify-content-end">
          <Link to="/emp-certification/new" className="btn btn-sm btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />&nbsp;
            <Translate contentKey="hrmsApp.empCertification.home.createLabel">Create new Emp Certification</Translate>
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {empCertificationList && empCertificationList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr className="table-info">
                <th className="hand">
                  <Translate contentKey="hrmsApp.empCertification.trainingCert">Training Cert</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empCertification.certName">Cert Name</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empCertification.awardingOrg">Awarding Org</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empCertification.location">Location</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empCertification.regNo">Reg No</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empCertification.candidateId">Candidate Id</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empCertification.fromDate">From Date</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empCertification.toDate">To Date</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empCertification.validTill">Valid Till</Translate>{' '}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {empCertificationList.map((empCertification, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Translate contentKey={`hrmsApp.CertOrTrain.${empCertification.trainingCert}`} />
                  </td>
                  <td>{empCertification.certName}</td>
                  <td>{empCertification.awardingOrg}</td>
                  <td>{empCertification.location}</td>
                  <td>{empCertification.regNo}</td>
                  <td>{empCertification.candidateId}</td>
                  <td>
                    {empCertification.fromDate ? (
                      <TextFormat type="date" value={empCertification.fromDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {empCertification.toDate ? (
                      <TextFormat type="date" value={empCertification.toDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {empCertification.validTill ? (
                      <TextFormat type="date" value={empCertification.validTill} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/emp-certification/${empCertification.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span> */}
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/emp-certification/${empCertification.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span> */}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.empCertification.home.notFound">No Emp Certifications found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default EmpCertificationHome;
