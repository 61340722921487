import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Badge, Button, Card, CardBody, CardTitle, Table } from 'reactstrap';
import { getEntity as getTimeSheetRecordsEntity } from 'app/entities/time-sheet-record/time-sheet-record.reducer';
import { useParams } from 'react-router-dom';
import TimeSheetDetailPieChart from 'app/shared/components/charts/time-sheet-record/TimeSheetDetailPieChart';
import {
  updateEntity as updateApproval,
  getEntity as getEntityPendingApproval,
} from '../../entities/pending-approval/pending-approval.reducer';
import { ValidatedInput } from 'react-jhipster';
import { toNumber } from 'lodash';

// Define the structure of a timesheet entry
interface TimesheetEntry {
  id: number;
  dated: string;
  hours: number;
  activities: any;
  assignment?: any;
  internalTask?: any;
  taskType?: any;
}

// Define the structure of grouped data by date and type
interface GroupedByDateAndType {
  project?: {
    [date: string]: TimesheetEntry[];
  };
  internal?: {
    [date: string]: TimesheetEntry[];
  };
}

const TimesheetTable = () => {
  const dispatch = useAppDispatch();

  // Account for the current logged in user
  const account = useAppSelector(state => state.authentication.account);

  const { timeSheetRecordID } = useParams<'timeSheetRecordID'>();
  const { approvalID } = useParams<'approvalID'>();
  const pendingApprovalEntity = useAppSelector(state => state.pendingApproval.entity);
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const [editedRemarks, setEditedRemarks] = useState('');

  // only approver id and account id equals then only show the remakrs and approve and reject button
  const isApprover = useMemo(() => {
    return (
      account?.employeeRef === pendingApprovalEntity?.approver?.id &&
      toNumber(timeSheetRecordID) === toNumber(pendingApprovalEntity?.entityId)
    );
  }, [account, pendingApprovalEntity, approvalID, timeSheetRecordID]);

  // if approvalID then call dispatch getEntityPendingApproval using useeffect
  useEffect(() => {
    if (approvalID) {
      dispatch(getEntityPendingApproval(approvalID));
    }
  }, [approvalID, dispatch]);

  const handleAction = (values, vAction) => {
    const entity = {
      ...pendingApprovalEntity,
      ...values,
      action:
        vAction === 'Approve'
          ? hrCodes.find(it => it.codeType === 'WKA' && it.codeName === 'Approve')
          : hrCodes.find(it => it.codeType === 'WKA' && it.codeName === 'Reject'),
      remarks: editedRemarks,
    };
    dispatch(updateApproval(entity));
  };

  // Helper functions...
  const reorderDays = (days: string[]): string[] => {
    const weekdays = days.filter(
      date => ![0, 6].includes(new Date(date).getDay()), // Monday to Friday
    );
    const weekend = days.filter(date => [0, 6].includes(new Date(date).getDay())); // Sunday and Saturday
    return [...weekdays, ...weekend]; // Combine weekdays first, then weekend
  };

  const getStartOfWeek = (year: number, weekNumber: number): Date => {
    const startOfYear = new Date(year, 0, 1);
    const startOfWeek = new Date(startOfYear.setDate(startOfYear.getDate() + (weekNumber - 1) * 7));
    const dayOfWeek = startOfWeek.getDay();
    startOfWeek.setDate(startOfWeek.getDate() - dayOfWeek);
    return startOfWeek;
  };

  const generateWeekDates = (startOfWeek: Date): string[] => {
    const days = Array.from({ length: 7 }).map((_, index) => {
      const date = new Date(startOfWeek);
      date.setDate(startOfWeek.getDate() + index);
      return date.toISOString().split('T')[0];
    });
    return reorderDays(days);
  };

  const groupEntriesByDateAndType = (timeSheets: TimesheetEntry[]): GroupedByDateAndType => {
    return timeSheets.reduce((acc: GroupedByDateAndType, entry: TimesheetEntry) => {
      const date = entry.dated;
      const taskType = entry.assignment ? 'project' : 'internal';
      if (!acc[taskType]) acc[taskType] = {};
      if (!acc[taskType][date]) acc[taskType][date] = [];
      acc[taskType][date].push(entry);
      return acc;
    }, {} as GroupedByDateAndType);
  };

  const getUniqueTaskNames = (tasks: TimesheetEntry[]): number[] => {
    const uniqueTasks = new Map<number, number>();

    tasks.forEach(task => {
      if (task.assignment && task.assignment.projectTask) {
        const taskId = task.assignment.projectTask.id; // Unique ID for project tasks

        uniqueTasks.set(taskId, taskId);
      } else if (task.internalTask) {
        const taskId = task.internalTask.id; // Unique ID for internal tasks

        uniqueTasks.set(taskId, taskId);
      }
    });

    return Array.from(uniqueTasks.values());
  };

  const timeSheetRecordList = useAppSelector(state => state.timeSheetRecord.entity);

  useEffect(() => {
    dispatch(getTimeSheetRecordsEntity(timeSheetRecordID));
  }, [dispatch]);

  const memoizedData = useMemo(() => {
    if (!timeSheetRecordList?.timeSheets) return null;

    const { timeSheets, weekNumber, year } = timeSheetRecordList;
    const startOfWeek = getStartOfWeek(year, weekNumber);
    const daysOfWeek = generateWeekDates(startOfWeek);
    const groupedByDateAndType = groupEntriesByDateAndType(timeSheets);

    const projectTasks = Object.keys(groupedByDateAndType.project || {}).flatMap(date => groupedByDateAndType.project[date]);
    const internalTasks = Object.keys(groupedByDateAndType.internal || {}).flatMap(date => groupedByDateAndType.internal[date]);
    const projectTaskNames = getUniqueTaskNames(projectTasks);
    const internalTaskNames = getUniqueTaskNames(internalTasks);

    const totalHoursByDay = daysOfWeek.map(date => {
      const projectEntries = groupedByDateAndType.project?.[date] || [];
      const internalEntries = groupedByDateAndType.internal?.[date] || [];
      return (
        projectEntries.reduce((total, entry) => total + entry.hours, 0) + internalEntries.reduce((total, entry) => total + entry.hours, 0)
      );
    });

    const employee = timeSheetRecordList?.employee;

    return { daysOfWeek, projectTaskNames, internalTaskNames, groupedByDateAndType, totalHoursByDay, employee, weekNumber, year };
  }, [timeSheetRecordList]);

  if (!memoizedData) return null;

  const { daysOfWeek, projectTaskNames, internalTaskNames, groupedByDateAndType, totalHoursByDay, employee, weekNumber, year } =
    memoizedData;

  const renderTaskEntries = (
    taskIds: number[], // Now using task IDs instead of names
    taskType: 'project' | 'internal',
    timeSheets: TimesheetEntry[], // Adding timeSheets as a parameter
  ) => {
    // Create a set of tasks (either project or internal)
    const taskSet = new Map<number, { name: string; taskId: number; projectTask?: any; internalTask?: any }>();
    // Populate the task set with unique tasks
    timeSheets.forEach(entry => {
      let taskId: number | undefined;
      let taskName: string | undefined;
      let projectTask: any;
      let internalTask: any;

      if (taskType === 'project' && entry.assignment?.projectTask) {
        taskId = entry.assignment.projectTask.id;
        taskName = entry.assignment.projectTask.businessProject?.projectName || 'Unknown Project';
        projectTask = entry.assignment.projectTask; // Only store projectTask when taskType is 'project'
      } else if (taskType === 'internal' && entry.internalTask) {
        taskId = entry.internalTask.id;
        taskName = entry.internalTask.codeName || 'Unknown Internal Task';
        internalTask = entry.internalTask; // Only store internalTask when taskType is 'internal'
      }

      if (taskId && taskName) {
        taskSet.set(taskId, { name: taskName, taskId, projectTask, internalTask });
      }
    });

    // Now render task entries based on the taskSet
    return taskIds.map((taskId, taskIndex) => {
      const task = taskSet.get(taskId);

      if (!task) return null; // Skip if the task doesn't exist

      return (
        <tr key={`${taskType}-${taskIndex}`}>
          <th scope="row">{taskType === 'project' ? `${task.name} (${task.projectTask?.taskName})` : task.name}</th>

          {daysOfWeek.map((date, dayIndex) => {
            const entries = groupedByDateAndType[taskType]?.[date] || [];
            const filteredEntries = entries.filter(entry =>
              taskType === 'project' ? entry.assignment?.projectTask?.id === taskId : entry.internalTask?.id === taskId,
            );

            return (
              <td key={`${taskType}-${taskIndex}-${dayIndex}`}>
                {filteredEntries.length > 0 ? (
                  filteredEntries.map((entry, idx) => (
                    <div key={idx}>
                      {entry.activities}{' '}
                      <Badge color="primary" pill>
                        {entry.hours} hrs
                      </Badge>
                    </div>
                  ))
                ) : (
                  <span>—</span> // Fallback for empty days
                )}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  const barChartData = Object.keys(groupedByDateAndType)
    .map(type => {
      return Object.keys(groupedByDateAndType[type] || {}).map(date => ({
        day: date,
        tasks: groupedByDateAndType[type][date].map(entry => {
          const task = entry.assignment || entry.internalTask || 'Unknown Task'; // Corrected variable assignment
          return {
            task, // Using the task variable here
            hours: entry.hours,
            taskLabel: task.projectTask?.taskName || task.codeName,
          };
        }),
      }));
    })
    .flat();

  const aggregateTaskHours = pieChartData => {
    const taskHoursMap = new Map();

    pieChartData.forEach(day => {
      day.tasks.forEach(task => {
        const taskLabel = task.task.projectTask?.taskName || task.task.codeName; // Get task label dynamically
        if (!taskLabel) return; // Skip tasks without a valid label

        taskHoursMap.set(taskLabel, (taskHoursMap.get(taskLabel) || 0) + task.hours);
      });
    });

    return Array.from(taskHoursMap.entries()).map(([taskLabel, hours]) => ({
      label: taskLabel,
      value: hours,
    }));
  };

  const pieChartData = aggregateTaskHours(barChartData);
  return (
    <>
      <div className="row d-flex align-items-stretch mb-2">
        {/* <h5>Emplyee Weekly Time Sheet Record</h5> */}
        {/* Column 1 - Employee Profile Card */}
        <div className="col-md-2 mb-2">
          <Card className="text-center">
            <CardBody>
              <img
                alt="Profile"
                src={employee?.empProfile?.profilePicUrl}
                className="card-img-top"
                style={{ maxHeight: '200px', objectFit: 'contain' }}
              />
              <CardTitle tag="h5">
                {employee?.firstName} {employee?.lastName} <Badge color="primary">{employee?.qlid}</Badge>
                <Badge color="secondary">Year {year}</Badge>
                <Badge color="secondary">Week {weekNumber}</Badge>
              </CardTitle>
            </CardBody>
          </Card>
        </div>

        {/* Column 2 - Bar Chart Card */}
        <div className="col-md-4 mb-2">
          <Card className="">
            <CardBody>
              <TimeSheetDetailPieChart data={pieChartData} />
            </CardBody>
          </Card>
        </div>
      </div>

      <Table responsive bordered>
        <thead>
          <tr>
            <th className="text-center">Task</th>
            {daysOfWeek.map((date, index) => (
              <th key={index} className="text-center">
                {new Date(date).toLocaleDateString('en-US', {
                  weekday: 'short',
                })}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {renderTaskEntries(projectTaskNames, 'project', timeSheetRecordList.timeSheets)}
          {renderTaskEntries(internalTaskNames, 'internal', timeSheetRecordList.timeSheets)}
        </tbody>
        <tfoot>
          <tr>
            <th>Total Hours</th>
            {totalHoursByDay.map((total, index) => (
              <td key={index} className="text-center">
                {total}
              </td>
            ))}
          </tr>
        </tfoot>
      </Table>

      <div className="d-flex justify-content-end">
        <h5>
          <span className="fw-bold">TOTAL HOURS:</span> {totalHoursByDay.reduce((sum, total) => sum + total, 0)}
        </h5>
      </div>

      {approvalID && pendingApprovalEntity.id && isApprover && (
        <>
          <div className="d-flex gap-1">
            <ValidatedInput
              id="pending-approval-remarks"
              name="dated"
              className="form-control-sm"
              type="text"
              placeholder="remarks"
              onChange={e => setEditedRemarks(e.target.value)}
            />
            <Button color="primary" onClick={() => handleAction(pendingApprovalEntity, 'Approve')}>
              Approve
            </Button>
            <Button color="danger" onClick={() => handleAction(pendingApprovalEntity, 'Reject')}>
              Reject
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default TimesheetTable;
