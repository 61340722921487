import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT, APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './medical-plan.reducer';

export const MedicalPlanDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const medicalPlanEntity = useAppSelector(state => state.medicalPlan.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="medicalPlanDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.medicalPlan.detail.title">MedicalPlan</Translate>
        </h5>
        <dl className="jh-entity-details bg-white">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{medicalPlanEntity.id}</dd>
          <dt>
            <span id="monthlyLimit">
              <Translate contentKey="hrmsApp.medicalPlan.monthlyLimit">Monthly Limit</Translate>
            </span>
          </dt>
          <dd>{medicalPlanEntity.monthlyLimit ? (
            <TextFormat value={medicalPlanEntity.monthlyLimit} type="number" format={APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT}  /> ) : null }
          </dd>
          <dt>
            <span id="yearlyLimit">
              <Translate contentKey="hrmsApp.medicalPlan.yearlyLimit">Yearly Limit</Translate>
            </span>
          </dt>
          <dd>{medicalPlanEntity.yearlyLimit ? (
            <TextFormat value={medicalPlanEntity.yearlyLimit} type="number" format={APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT}  /> ) : null }
          </dd>
          <dt>
            <span id="startDate">
              <Translate contentKey="hrmsApp.medicalPlan.startDate">Start Date</Translate>
            </span>
          </dt>
          <dd>
            {medicalPlanEntity.startDate ? (
              <TextFormat value={medicalPlanEntity.startDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="endDate">
              <Translate contentKey="hrmsApp.medicalPlan.endDate">End Date</Translate>
            </span>
          </dt>
          <dd>
            {medicalPlanEntity.endDate ? <TextFormat value={medicalPlanEntity.endDate} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="hrmsApp.medicalPlan.planName">Plan Name</Translate>
          </dt>
          <dd>{medicalPlanEntity.planName ? medicalPlanEntity.planName.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.medicalPlan.itemName">Item Name</Translate>
          </dt>
          <dd>{medicalPlanEntity.itemName ? medicalPlanEntity.itemName.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.medicalPlan.status">Status</Translate>
          </dt>
          <dd>{medicalPlanEntity.status ? medicalPlanEntity.status.codeName : ''}</dd>
        </dl>
        <Button tag={Link} to="/medical-plan" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/medical-plan/${medicalPlanEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default MedicalPlanDetail;
