import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import EmpReference from './emp-reference';
import EmpReferenceDeleteDialog from './emp-reference-delete-dialog';
import EmpReferenceDetail from './emp-reference-detail';
import EmpReferenceUpdate from './emp-reference-update';

const EmpReferenceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<EmpReference />} />
    <Route path="new" element={<EmpReferenceUpdate />} />
    <Route path=":id">
      <Route index element={<EmpReferenceDetail />} />
      <Route path="edit" element={<EmpReferenceUpdate />} />
      <Route path="delete" element={<EmpReferenceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default EmpReferenceRoutes;
