import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './emp-certification.reducer';

export const EmpCertificationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const empCertificationEntity = useAppSelector(state => state.empCertification.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="empCertificationDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.empCertification.detail.title">EmpCertification</Translate>
        </h5>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{empCertificationEntity.id}</dd>
          <dt>
            <span id="trainingCert">
              <Translate contentKey="hrmsApp.empCertification.trainingCert">Training Cert</Translate>
            </span>
          </dt>
          <dd>{empCertificationEntity.trainingCert}</dd>
          <dt>
            <span id="certName">
              <Translate contentKey="hrmsApp.empCertification.certName">Cert Name</Translate>
            </span>
          </dt>
          <dd>{empCertificationEntity.certName}</dd>
          <dt>
            <span id="awardingOrg">
              <Translate contentKey="hrmsApp.empCertification.awardingOrg">Awarding Org</Translate>
            </span>
          </dt>
          <dd>{empCertificationEntity.awardingOrg}</dd>
          <dt>
            <span id="location">
              <Translate contentKey="hrmsApp.empCertification.location">Location</Translate>
            </span>
          </dt>
          <dd>{empCertificationEntity.location}</dd>
          <dt>
            <span id="regNo">
              <Translate contentKey="hrmsApp.empCertification.regNo">Reg No</Translate>
            </span>
          </dt>
          <dd>{empCertificationEntity.regNo}</dd>
          <dt>
            <span id="candidateId">
              <Translate contentKey="hrmsApp.empCertification.candidateId">Candidate Id</Translate>
            </span>
          </dt>
          <dd>{empCertificationEntity.candidateId}</dd>
          <dt>
            <span id="fromDate">
              <Translate contentKey="hrmsApp.empCertification.fromDate">From Date</Translate>
            </span>
          </dt>
          <dd>
            {empCertificationEntity.fromDate ? (
              <TextFormat value={empCertificationEntity.fromDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="toDate">
              <Translate contentKey="hrmsApp.empCertification.toDate">To Date</Translate>
            </span>
          </dt>
          <dd>
            {empCertificationEntity.toDate ? (
              <TextFormat value={empCertificationEntity.toDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="validTill">
              <Translate contentKey="hrmsApp.empCertification.validTill">Valid Till</Translate>
            </span>
          </dt>
          <dd>
            {empCertificationEntity.validTill ? (
              <TextFormat value={empCertificationEntity.validTill} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="hrmsApp.empCertification.employee">Employee</Translate>
          </dt>
          <dd>{empCertificationEntity.employee ? empCertificationEntity.employee.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/emp-certification" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/emp-certification/${empCertificationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default EmpCertificationDetail;
