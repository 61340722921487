import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import TimeSheetRecord from './time-sheet-record';

const TimeSheetRecordRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route path=":timeSheetRecordID" element={<TimeSheetRecord />}>
      <Route path="pending-approval/:approvalID" element={<TimeSheetRecord />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TimeSheetRecordRoutes;
