import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { Button, Table } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { IContract } from 'app/shared/model/contract.model';
import { IEmployee } from 'app/shared/model/employee.model';
import dayjs from 'dayjs';
import { getEntities as getBusinessProjects } from '../business-project/business-project.reducer';
import { getEntities as getLeavesPlans } from '../leaves-plan/leaves-plan.reducer';
import { createEntity, getEntities as getLeavesRecords } from './leaves-record.reducer';

interface LeaveInfo {
  // leaveId: number;
  planNameId: number;
  itemNameId: number;
  codeType: string;
  codeName: string;
  year: number;
  total: number;
  availed: number;
}
export const LeavesRecord = () => {
  const dispatch = useAppDispatch();

  // const pageLocation = useLocation();
  // const navigate = useNavigate();
  // const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const leavesRecords = useAppSelector(state => state.leavesRecord.entities);
  const leavesPlans = useAppSelector(state => state.leavesPlan.entities);
  const employees = useAppSelector(state => state.employee.entities);
  const contracts = useAppSelector(state => state.contract.entities);
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const account = useAppSelector(state => state.authentication.account);
  const loading = useAppSelector(state => state.leavesRecord.loading);
  const [curEmployee, setCurEmployee] = useState<IEmployee>(null);
  const [curContract, setCurContract] = useState<IContract>(null);
  const [curEmpLeaves, setCurEmpLeaves] = useState<LeaveInfo[]>([]);
  const [curLeaveItem, setCurLeaveItem] = useState<LeaveInfo>(null);
  const [done, setDone] = useState(false);
  const [lrFetched, setLrFetched] = useState(false);

  const businessProjects = useAppSelector(state => state.businessProject.entities);

  const [isNew, setIsNew] = useState(false);
  // const [selectedOption, setSelectedOption] = useState<number>();
  // const [selectedEntity, setSelectedEntity] = useState(countryHolidayList.find(e=>e.id===-1));
  const [selectedEntity, setSelectedEntity] = useState(leavesRecords.find(e => e.id === -1));
  const updateSuccess = useAppSelector(state => state.leavesRecord.updateSuccess);

  /* eslint no-console: off */
  // useEffect(() => {
  //   if (account?.employeeRef !== null && account?.employeeRef!==undefined) {
  //     dispatch(getContracts({ query: `employeeId.equals=${account?.employeeRef}`} ));
  //   }
  // }, [account]);

  useEffect(() => {
    if (
      account?.employeeRef !== null &&
      account?.employeeRef !== undefined &&
      contracts.length > 0 &&
      hrCodes.length > 0 &&
      employees.length > 0
    ) {
      const ctsActiveId = hrCodes.find(e => e.codeType === 'CTS' && e.codeName === 'Active')?.id;
      const employee = employees.find(e => e.id === account?.employeeRef);
      setCurEmployee(employee);
      const contract = contracts.find(e => e.employee.id === account?.employeeRef && e.contractStatus?.id === ctsActiveId);
      setCurContract(contract);
      dispatch(getLeavesRecords({ query: `contractId.equals=${contract?.id}` }));
      dispatch(getLeavesPlans({ query: `planNameId.equals=${contract?.leavesPlan?.id}` }));
      dispatch(getBusinessProjects({}));
    }
  }, [account, contracts, employees, hrCodes]);

  useEffect(() => {
    if (curEmployee && leavesPlans.length > 0) {
      console.log('===== lrFetched: ', lrFetched);

      const yearStart = dayjs().startOf('year');
      const yearEnd = dayjs().endOf('year');
      const currentYear = dayjs().year();
      const joining = dayjs(curEmployee.joiningDate);

      // Calculate Less Days (Days before joining)
      const lessDays = joining.isAfter(yearStart) ? joining.diff(yearStart, 'day') : 0;

      // Remaining Days = Total days in year - Less Days
      const remainingDays = 365 - lessDays;

      console.log('=== Joining: ', joining.format('YYYY-MM-DD'), ' LessDays: ', lessDays, ' Remaining Days: ', remainingDays);

      const roundToNearestHalf = (num: number) => {
        return num % 1 < 0.5 ? Math.floor(num) : Math.floor(num) + 0.5;
      };

      const lvInfos: LeaveInfo[] = [];

      leavesPlans.forEach(plan => {
        const leaveItem = hrCodes.find(e => e.id === plan.itemName.id);

        // Calculate prorated leave
        const proratedLeave = (remainingDays * plan.qty) / 365;

        // Apply custom rounding logic
        const finalLeave = roundToNearestHalf(proratedLeave);

        lvInfos.push({
          planNameId: plan.planName.id,
          itemNameId: plan.itemName.id,
          codeType: leaveItem?.codeType,
          codeName: leaveItem?.codeName,
          year: currentYear,
          total: finalLeave, // Corrected rounding
          availed: 0,
        });
      });

      if (lvInfos.length > 0) {
        setCurEmpLeaves(lvInfos);
      }
    }
  }, [curEmployee, leavesPlans]);

  const updateAvailed = (itemNameId: number, newAvailed: number) => {
    setCurEmpLeaves(prevLeaves => prevLeaves.map(leave => (leave.itemNameId === itemNameId ? { ...leave, availed: newAvailed } : leave)));
  };

  // useEffect(() => {
  //   if (curEmpLeaves.length > 0) {
  //     console.log('====== Leave Record: ', curEmpLeaves.length, ' : ', curEmpLeaves);
  //   }
  // }, [curEmpLeaves]);

  const roundToNearestHalf = (num: number) => {
    return num % 1 < 0.5 ? Math.floor(num) : Math.floor(num) + 0.5;
  };

  useEffect(() => {
    console.log('+++++ LeavesRecord useEffect: ', leavesRecords);
    if (leavesRecords.length > 0) {
      console.log('------ curEmpLeaves Before: ', curEmpLeaves);
      setCurEmpLeaves(prevLeaves =>
        prevLeaves.map(leave => {
          const filteredData = leavesRecords.filter(
            e => e.leaveItem.id === leave.itemNameId && e.fromDate >= dayjs().startOf('year'), // && e.fromDate.year === leave.year, // && (e.toDate === null || e.toDate.year === leave.year),
          );
          console.log('===== FilteredData: ', filteredData);
          const availedSoFar = filteredData.reduce((sum, item) => sum + item.dayCount, 0);
          // Return a new object with the updated availed value
          return { ...leave, availed: availedSoFar };
        }),
      );
      console.log('------ curEmpLeaves After: ', curEmpLeaves);
    }
    setDone(true);
  }, [leavesRecords]);

  useEffect(() => {
    if (curLeaveItem !== null) {
      console.log('====== Leave Item: ', curLeaveItem);
    }
  }, [curLeaveItem]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.dayCount !== undefined && typeof values.dayCount !== 'number') {
      values.dayCount = Number(values.dayCount);
    }

    const entity = {
      ...selectedEntity,
      ...values,
      contract: curContract, // contracts.find(it => it.id.toString() === values.contract?.toString()),
      businessProject: businessProjects.find(it => it.id.toString() === values.businessProject?.toString()),
      leaveItem: hrCodes.find(it => it.id.toString() === curLeaveItem?.itemNameId?.toString()),
      status: hrCodes.find(e => e.codeType === 'ALS' && e.codeName === 'Pending'), //   hrCodes.find(it => it.id.toString() === curLeaveItem?.itemNameId.toString()),
      dated: new Date(),
    };

    if (isNew) {
      dispatch(createEntity(entity));
      setIsNew(false);
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...selectedEntity,
          contract: curContract?.id,
          businessProject: selectedEntity?.businessProject?.id,
          leaveItem: curLeaveItem?.itemNameId,
          status: selectedEntity?.status?.id,
        };

  const applyLeave = leaveItem => {
    console.log('-------- Leave Item: ', leaveItem);
    setCurLeaveItem(leaveItem);
    setIsNew(true);
  };

  // const sortEntities = () => {
  //   getAllEntities();
  //   const endURL = `?sort=${sortState.sort},${sortState.order}`;
  //   if (pageLocation.search !== endURL) {
  //     navigate(`${pageLocation.pathname}${endURL}`);
  //   }
  // };

  // useEffect(() => {
  //   sortEntities();
  // }, [sortState.order, sortState.sort]);

  // const sort = p => () => {
  //   setSortState({
  //     ...sortState,
  //     order: sortState.order === ASC ? DESC : ASC,
  //     sort: p,
  //   });
  // };

  // const handleSyncList = () => {
  //   sortEntities();
  // };

  // const getSortIconByFieldName = (fieldName: string) => {
  //   const sortFieldName = sortState.sort;
  //   const order = sortState.order;
  //   if (sortFieldName !== fieldName) {
  //     return faSort;
  //   } else {
  //     return order === ASC ? faSortUp : faSortDown;
  //   }
  // };

  return (
    <div>
      <h5 id="leaves-record-heading" data-cy="LeavesRecordHeading" className="text-center text-primary fw-bold p-1">
        <Translate contentKey="hrmsApp.leavesRecord.home.title">Leaves Record</Translate>
      </h5>
      <div className="table-responsive bg-light">
        {leavesRecords && leavesRecords.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="hrmsApp.leavesRecord.leaveItem">Leave Item</Translate>
                </th>
                <th>
                  <Translate contentKey="hrmsApp.leavesRecord.fromDate">Start Date</Translate>{' '}
                </th>
                <th>
                  <Translate contentKey="hrmsApp.leavesRecord.toDate">End Date</Translate>{' '}
                </th>
                <th>
                  <Translate contentKey="hrmsApp.leavesRecord.dayCount">Days</Translate>
                </th>
                <th>
                  <Translate contentKey="hrmsApp.leavesRecord.status">Status</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {leavesRecords.map((leavesRecord, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>{leavesRecord.leaveItem ? hrCodes.find(e => e.id === leavesRecord.leaveItem.id)?.codeName : ''}</td>
                  <td>
                    {leavesRecord.fromDate ? <TextFormat type="date" value={leavesRecord.fromDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {leavesRecord.toDate ? <TextFormat type="date" value={leavesRecord.toDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
                  </td>
                  <td>{leavesRecord.dayCount}</td>
                  <td>{leavesRecord.status ? hrCodes.find(e => e.id === leavesRecord.status.id)?.codeName : ''}</td>
                  <td className="text-end">
                    {leavesRecord.status.codeName !== 'Pending' ? (
                      <div className="btn-group flex-btn-group-container">
                        <Button color="primary" size="sm">
                          <FontAwesomeIcon icon="save" /> <span className="d-none d-md-inline">Submit </span>
                        </Button>
                        <Button
                          onClick={() => (window.location.href = `/leaves-record/${leavesRecord.id}/delete`)}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && null
          // <div className="alert alert-warning">
          //   <Translate contentKey="hrmsApp.leavesRecord.home.notFound">No Leaves Records found</Translate>
          // </div>
        )}
      </div>
      {done && curEmpLeaves.length > 0 ? (
        <>
          <div className="container d-flex justify-content-center">
            {curEmpLeaves.map((leave, index) => (
              <div key={index} className={`m-2 border border-gray rounded bg-light text-center`}>
                {' '}
                <h5 className="text-center">{leave.codeName}</h5>
                <div className="text-white bg-warning px-2 py-1">
                  Availed:{' '}
                  <span id="init-by-else" className="bg-white text-primary px-2">
                    {leave.availed}
                  </span>
                </div>
                <div className="text-white bg-success px-2 py-1">
                  Balance:{' '}
                  <span id="init-by-you" className="bg-white text-primary px-2">
                    {leave.total - leave.availed}
                  </span>
                </div>
                <div className="text-white bg-white px-2 py-1">
                  <Button className="me-2" color="primary" onClick={() => applyLeave(leave)} disabled={isNew}>
                    <FontAwesomeIcon icon="plus" spin={loading} />{' '}
                    {/* <Translate contentKey="hrmsApp.leavesRecord.home.refreshListLabel">Refresh List</Translate> */}
                  </Button>
                </div>
              </div>
              //   {lvRecord.map((leave, index) => (
              //     <div key={index} className='flex-grow-1'>
              //       <p className='fw-bold'>
              //         {leave.codeName}: <span className="bg-warning text-white p-1">{leave.availed} / {leave.total - leave.availed}</span> <br />
              //         <Progress className="my-2" multi>
              //           <Progress bar color="info" value={(leave.availed * 100 / leave.total)}>Availed: {leave.availed}</Progress>
              //           <Progress bar color="warning" value={(leave.total - leave.availed)*100/leave.total}>Balance: {leave.total - leave.availed} </Progress>
              //           {/* <Progress bar color="success" value="30">Wow!</Progress> */}
              //           {/* <Progress bar color="info" value="25">Cool</Progress> */}
              //           {/* <Progress bar color="warning" value="20">20%</Progress> */}
              //           {/* <Progress bar color="danger" value="5">!!</Progress> */}
              //         </Progress>
              //       </p>
              //     </div>
              //   ))}
              // </div>
            ))}
          </div>
          <div className="d-flex justify-content-center mt-3 border border-light bg-light">
            {isNew ? (
              <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
                {!isNew ? (
                  <ValidatedField
                    name="id"
                    required
                    readOnly
                    id="leaves-record-id"
                    label={translate('global.field.id')}
                    validate={{ required: true }}
                  />
                ) : null}
                <ValidatedField
                  id="leaves-record-businessProject"
                  name="businessProject"
                  data-cy="businessProject"
                  label={translate('hrmsApp.leavesRecord.businessProject')}
                  type="select"
                >
                  <option value="" key="0" />
                  {businessProjects
                    ? businessProjects.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.projectName}
                        </option>
                      ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.leavesRecord.fromDate')}
                  id="leaves-record-fromDate"
                  name="fromDate"
                  data-cy="fromDate"
                  type="date"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                />
                <ValidatedField
                  label={translate('hrmsApp.leavesRecord.toDate')}
                  id="leaves-record-toDate"
                  name="toDate"
                  data-cy="toDate"
                  type="date"
                />
                <ValidatedField
                  label={translate('hrmsApp.leavesRecord.dayCount')}
                  id="leaves-record-dayCount"
                  name="dayCount"
                  data-cy="dayCount"
                  type="text"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                    validate: v => isNumber(v) || translate('entity.validation.number'),
                  }}
                />
                <ValidatedField
                  label={translate('hrmsApp.leavesRecord.remarks')}
                  id="leaves-record-remarks"
                  name="remarks"
                  data-cy="remarks"
                  type="text"
                />
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => setIsNew(false)} replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Cancel</span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" /* disabled={updating}*/>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </ValidatedForm>
            ) : null}
          </div>
        </>
      ) : (
        'No data found'
      )}
    </div>
  );
};

export default LeavesRecord;
