import dayjs from 'dayjs';
import { IEmployee } from 'app/shared/model/employee.model';
import { relationInfo } from 'app/shared/model/enumerations/relation-info.model';

export interface IEmpDependent {
  id?: number;
  fullName?: string;
  relationInfo?: keyof typeof relationInfo;
  cnic?: number;
  dob?: dayjs.Dayjs;
  occupation?: string | null;
  contactNo?: string | null;
  curAddress?: string | null;
  employee?: IEmployee;
}

export const defaultValue: Readonly<IEmpDependent> = {};
