import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './country-workday.reducer';

export const CountryWorkdayDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const countryWorkdayEntity = useAppSelector(state => state.countryWorkday.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="countryWorkdayDetailsHeading">
          <Translate contentKey="hrmsApp.countryWorkday.detail.title">CountryWorkday</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{countryWorkdayEntity.id}</dd>
          <dt>
            <span id="monOn">
              <Translate contentKey="hrmsApp.countryWorkday.monOn">Mon On</Translate>
            </span>
          </dt>
          <dd>{countryWorkdayEntity.monOn ? 'true' : 'false'}</dd>
          <dt>
            <span id="tueOn">
              <Translate contentKey="hrmsApp.countryWorkday.tueOn">Tue On</Translate>
            </span>
          </dt>
          <dd>{countryWorkdayEntity.tueOn ? 'true' : 'false'}</dd>
          <dt>
            <span id="wedOn">
              <Translate contentKey="hrmsApp.countryWorkday.wedOn">Wed On</Translate>
            </span>
          </dt>
          <dd>{countryWorkdayEntity.wedOn ? 'true' : 'false'}</dd>
          <dt>
            <span id="thuOn">
              <Translate contentKey="hrmsApp.countryWorkday.thuOn">Thu On</Translate>
            </span>
          </dt>
          <dd>{countryWorkdayEntity.thuOn ? 'true' : 'false'}</dd>
          <dt>
            <span id="friOn">
              <Translate contentKey="hrmsApp.countryWorkday.friOn">Fri On</Translate>
            </span>
          </dt>
          <dd>{countryWorkdayEntity.friOn ? 'true' : 'false'}</dd>
          <dt>
            <span id="satOn">
              <Translate contentKey="hrmsApp.countryWorkday.satOn">Sat On</Translate>
            </span>
          </dt>
          <dd>{countryWorkdayEntity.satOn ? 'true' : 'false'}</dd>
          <dt>
            <span id="sunOn">
              <Translate contentKey="hrmsApp.countryWorkday.sunOn">Sun On</Translate>
            </span>
          </dt>
          <dd>{countryWorkdayEntity.sunOn ? 'true' : 'false'}</dd>
          <dt>
            <span id="timeZone">
              <Translate contentKey="hrmsApp.countryWorkday.timeZone">Time Zone</Translate>
            </span>
          </dt>
          <dd>{countryWorkdayEntity.timeZone}</dd>
          <dt>
            <span id="remarks">
              <Translate contentKey="hrmsApp.countryWorkday.remarks">Remarks</Translate>
            </span>
          </dt>
          <dd>{countryWorkdayEntity.remarks}</dd>
          <dt>
            <Translate contentKey="hrmsApp.countryWorkday.country">Country</Translate>
          </dt>
          <dd>{countryWorkdayEntity.country ? countryWorkdayEntity.country.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.countryWorkday.status">Status</Translate>
          </dt>
          <dd>{countryWorkdayEntity.status ? countryWorkdayEntity.status.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/country-workday" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/country-workday/${countryWorkdayEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CountryWorkdayDetail;
