import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import HrCode from './hr-code';
import HrCodeDeleteDialog from './hr-code-delete-dialog';
import HrCodeDetail from './hr-code-detail';
import HrCodeUpdate from './hr-code-update';

const HrCodeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<HrCode />} />
    <Route path="new" element={<HrCodeUpdate />} />
    <Route path=":id">
      <Route index element={<HrCodeDetail />} />
      <Route path="edit" element={<HrCodeUpdate />} />
      <Route path="delete" element={<HrCodeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default HrCodeRoutes;
