import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ContractTemplate from './contract-template';
import ContractTemplateDetail from './contract-template-detail';
import ContractTemplateUpdate from './contract-template-update';
import ContractTemplateDeleteDialog from './contract-template-delete-dialog';

const ContractTemplateRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ContractTemplate />} />
    <Route path="new" element={<ContractTemplateUpdate />} />
    <Route path=":id">
      <Route index element={<ContractTemplateDetail />} />
      <Route path="edit" element={<ContractTemplateUpdate />} />
      <Route path="delete" element={<ContractTemplateDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ContractTemplateRoutes;
