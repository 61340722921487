import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './expense-item.reducer';

export const ExpenseItemDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const expenseItemEntity = useAppSelector(state => state.expenseItem.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="expenseItemDetailsHeading">
          <Translate contentKey="hrmsApp.expenseItem.detail.title">ExpenseItem</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{expenseItemEntity.id}</dd>
          <dt>
            <span id="expDate">
              <Translate contentKey="hrmsApp.expenseItem.expDate">Exp Date</Translate>
            </span>
          </dt>
          <dd>
            {expenseItemEntity.expDate ? <TextFormat value={expenseItemEntity.expDate} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="description">
              <Translate contentKey="hrmsApp.expenseItem.description">Description</Translate>
            </span>
          </dt>
          <dd>{expenseItemEntity.description}</dd>
          <dt>
            <span id="receiptNo">
              <Translate contentKey="hrmsApp.expenseItem.receiptNo">Receipt No</Translate>
            </span>
          </dt>
          <dd>{expenseItemEntity.receiptNo}</dd>
          <dt>
            <span id="amount">
              <Translate contentKey="hrmsApp.expenseItem.amount">Amount</Translate>
            </span>
          </dt>
          <dd>{expenseItemEntity.amount}</dd>
          <dt>
            <span id="exchangeRate">
              <Translate contentKey="hrmsApp.expenseItem.exchangeRate">Exchange Rate</Translate>
            </span>
          </dt>
          <dd>{expenseItemEntity.exchangeRate}</dd>
          <dt>
            <span id="attachmentPath">
              <Translate contentKey="hrmsApp.expenseItem.attachmentPath">Attachment Path</Translate>
            </span>
          </dt>
          <dd>{expenseItemEntity.attachmentPath}</dd>
          <dt>
            <Translate contentKey="hrmsApp.expenseItem.expenseClaim">Expense Claim</Translate>
          </dt>
          <dd>{expenseItemEntity.expenseClaim ? expenseItemEntity.expenseClaim.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.expenseItem.expenseType">Expense Type</Translate>
          </dt>
          <dd>{expenseItemEntity.expenseType ? expenseItemEntity.expenseType.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.expenseItem.currecny">Currecny</Translate>
          </dt>
          <dd>{expenseItemEntity.currecny ? expenseItemEntity.currecny.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.expenseItem.status">Status</Translate>
          </dt>
          <dd>{expenseItemEntity.status ? expenseItemEntity.status.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/expense-item" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/expense-item/${expenseItemEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ExpenseItemDetail;
