export enum relationInfo {
  Spouse = 'Spouse',

  Son = 'Son',

  Daughter = 'Daughter',

  Father = 'Father',

  Mother = 'Mother',

  Brother = 'Brother',

  Sister = 'Sister',
}
