import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, getSortState, translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';

import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { createEntity, getEntities as getContacts, updateEntity } from './../client-contact/client-contact.reducer';
import { getEntities } from './business-client.reducer';

export const BusinessClient = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const businessClientList = useAppSelector(state => state.businessClient.entities);
  const clientContactList = useAppSelector(state => state.clientContact.entities);
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const loading = useAppSelector(state => state.businessClient.loading);
  const [selectedClient, setSelectedClient] = useState(businessClientList.find(e=>e.id===-1));
  const [selectedEntity, setSelectedEntity] = useState(clientContactList.find(e=>e.id===-1));
  const [isNew, setIsNew] = useState(false);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
    if (hrCodes?.length===0) {
      dispatch(getHrCodes({}));
    }
    console.log('------- HrCodes: ', hrCodes.length);
    dispatch(getContacts({}));
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };
  
  const updating = useAppSelector(state => state.clientContact.updating);
  const updateSuccess = useAppSelector(state => state.clientContact.updateSuccess);

  const handleClose = () => {
    setIsNew(false);
    setSelectedEntity(null);
  };

  const handleClientSelection = (id) => {
    setSelectedClient(id);
    handleClose();
  }
  
  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);
  
  /* eslint no-console: off */
  const handleNew = () => {
    setIsNew(true);
    console.log(' ---------- Clicked New');
  };
  
  useEffect(() => {
    console.log('-------- selectedEntity: ', selectedEntity);
  });


  const handleEdit = (selectedId) => {
    setSelectedEntity(clientContactList.find(e=>e.id===selectedId));
    console.log('-------- SelectedId: ', selectedId, ' --- ', isNew);
  };
  
  const saveEntity = values => {
    console.log('--------- isNew: ', isNew, ' ---- ', selectedEntity);
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...selectedEntity,
      ...values,
      businessClient: businessClientList.find(it => it.id===selectedClient), //.toString() === values.businessClient?.toString()),
      status: isNew? hrCodes.find(e=>e.codeType==='WKS' && e.codeName==='Pending') : hrCodes.find(it => it.id.toString() === values.status?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...selectedEntity,
          businessClient: selectedEntity?.businessClient?.id,
          status: selectedEntity?.status?.id,
        };


  return (
    <div>
      <h5 id="business-client-heading" data-cy="BusinessClientHeading" className="text-center text-primary fw-bold p-1">
        <Translate contentKey="hrmsApp.businessClient.home.title">Business Clients</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            {/* <Translate contentKey="hrmsApp.businessClient.home.refreshListLabel">Refresh List</Translate> */}
          </Button>
          <Link to="/business-client/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            {/* <Translate contentKey="hrmsApp.businessClient.home.createLabel">Create new Business Client</Translate> */}
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {businessClientList && businessClientList.length > 0 ? (
          <>
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hrmsApp.businessClient.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('clientName')}>
                  <Translate contentKey="hrmsApp.businessClient.clientName">Client Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('clientName')} />
                </th>
                <th className="hand" onClick={sort('clientCode')}>
                  <Translate contentKey="hrmsApp.businessClient.clientCode">Client Code</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('clientCode')} />
                </th>
                <th className="hand" onClick={sort('curAddress')}>
                  <Translate contentKey="hrmsApp.businessClient.curAddress">Cur Address</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('curAddress')} />
                </th>
                <th className="hand" onClick={sort('regNo')}>
                  <Translate contentKey="hrmsApp.businessClient.regNo">Reg No</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('regNo')} />
                </th>
                <th className="hand" onClick={sort('remarks')}>
                  <Translate contentKey="hrmsApp.businessClient.remarks">Remarks</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('remarks')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.businessClient.country">Country</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                {/* <th>
                  <Translate contentKey="hrmsApp.businessClient.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}
                <th />
              </tr>
            </thead>
            <tbody>
              {businessClientList.map((businessClient, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable" onClick={()=>handleClientSelection(businessClient.id)} className={selectedClient===businessClient.id? "table-active" : ""}>
                  <td>
                    <Button tag={Link} to={`/business-client/${businessClient.id}`} color="link" size="sm">
                      {businessClient.id}
                    </Button>
                  </td>
                  <td>{businessClient.clientName}</td>
                  <td>{businessClient.clientCode}</td>
                  <td>{businessClient.curAddress}</td>
                  <td>{businessClient.regNo}</td>
                  <td>{businessClient.remarks}</td>
                  <td>
                    {businessClient.country ? hrCodes.find(e=>e.id===businessClient.country.id).codeName : ''}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/business-client/${businessClient.id}/edit`} color="primary" size="sm" data-cy="entityEditButton" >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                      </Button>
                      <Button onClick={() => (window.location.href = `/business-client/${businessClient.id}/delete`)} color="danger" size="sm" data-cy="entityDeleteButton" >
                        <FontAwesomeIcon icon="trash" />{' '}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {selectedClient? (
            <div >
              <h5 id="client-contact-heading" data-cy="ClientContactHeading" className="text-center text-white bg-secondary fw-bold p-1 m-0 mt-3">{businessClientList.find(e=>e.id===selectedClient)['clientName']} Contacts
                {/* <Translate contentKey="hrmsApp.clientContact.home.title">Client Contacts</Translate> */}
                <div className="d-flex justify-content-end">
                  <Button id="create-contact-entity" data-cy="entityCreateButton" color="primary" onClick={() => setIsNew(true)} disabled={loading || (isNew || (selectedEntity!==null && selectedEntity !== undefined))}> <FontAwesomeIcon icon="plus" spin={loading} />{' '}</Button>
                </div>
              </h5>
              <div className="table table-hover text-white bg-secondary px-1">
                {clientContactList && clientContactList.filter(e=>e.businessClient.id===selectedClient).length > 0 ? (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>
                          <Translate contentKey="hrmsApp.clientContact.firstName">First Name</Translate>{' '}
                        </th>
                        <th>
                          <Translate contentKey="hrmsApp.clientContact.lastName">Last Name</Translate>{' '}
                        </th>
                        <th >
                          <Translate contentKey="hrmsApp.clientContact.designation">Designation</Translate>{' '}
                        </th>
                        <th >
                          <Translate contentKey="hrmsApp.clientContact.email">Email</Translate>{' '}
                        </th>
                        <th >
                          <Translate contentKey="hrmsApp.clientContact.contactNos">Contact Nos</Translate>{' '}
                        </th>
                        <th >
                          <Translate contentKey="hrmsApp.clientContact.remarks">Remarks</Translate>{' '}
                        </th>
                        {/* <th>
                          <Translate contentKey="hrmsApp.clientContact.businessClient">Business Client</Translate>
                        </th> */}
                        <th>
                          <Translate contentKey="hrmsApp.clientContact.status">Status</Translate>
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {clientContactList.filter(e=>e.businessClient.id===selectedClient).map((clientContact, i) => (
                        <tr key={`entity-${i}`} data-cy="entityTable">
                          <td>{clientContact.firstName}</td>
                          <td>{clientContact.lastName}</td>
                          <td>{clientContact.designation}</td>
                          <td>{clientContact.email}</td>
                          <td>{clientContact.contactNos}</td>
                          <td>{clientContact.remarks}</td>
                          {/* <td>{clientContact.businessClient ? clientContact.businessClient.clientName : '' } </td> */}
                          <td>{clientContact.status ? hrCodes.find(e=>e.id===clientContact.status.id).codeName : ''}</td>
                          <td className="text-end">
                            <div className="btn-group flex-btn-group-container">
                              <Button color="primary" size="sm" data-cy="entityEditButton" onClick={()=>handleEdit(clientContact.id)} disabled={(isNew || (selectedEntity!==null && selectedEntity !== undefined))}>
                                <FontAwesomeIcon icon="pencil-alt" />{' '}
                              </Button>
                              <Button color="danger" size="sm" data-cy="entityDeleteButton" onClick={() => (window.location.href = `/client-contact/${clientContact.id}/delete`)}><FontAwesomeIcon icon="trash" />{' '}</Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                ) : (
                  !loading && (
                    <div className="alert alert-warning">
                      <Translate contentKey="hrmsApp.clientContact.home.notFound">No Client Contacts found</Translate>
                    </div>
                  )
                )}
              </div>
              {(isNew || (selectedEntity!==null && selectedEntity !== undefined)) ? (
              <div className="mt-2 py-2 bg-light mt-3">
                <Row className="justify-content-center m-0">
                  <Col md="8">
                    <h5 id="hrmsApp.clientContact.home.createOrEditLabel" data-cy="ClientContactCreateUpdateHeading" className="text-center fw-bold">
                      Create/Modify {businessClientList.find(e=>e.id===selectedClient)['clientName']} Contact
                      {/* <Translate contentKey="hrmsApp.clientContact.home.createOrEditLabel">Create or edit a ClientContact</Translate> */}
                    </h5>
                  </Col>
                </Row>
                <Row className="justify-content-center m-0">
                  <Col md="8">
                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
                        <ValidatedField
                          label={translate('hrmsApp.clientContact.firstName')}
                          id="client-contact-firstName"
                          name="firstName"
                          data-cy="firstName"
                          type="text"
                          validate={{
                            required: { value: true, message: translate('entity.validation.required') },
                            maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                          }}
                        />
                        <ValidatedField
                          label={translate('hrmsApp.clientContact.lastName')}
                          id="client-contact-lastName"
                          name="lastName"
                          data-cy="lastName"
                          type="text"
                          validate={{
                            maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                          }}
                        />
                        <ValidatedField
                          label={translate('hrmsApp.clientContact.designation')}
                          id="client-contact-designation"
                          name="designation"
                          data-cy="designation"
                          type="text"
                          validate={{
                            maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                          }}
                        />
                        <ValidatedField
                          label={translate('hrmsApp.clientContact.email')}
                          id="client-contact-email"
                          name="email"
                          data-cy="email"
                          type="text"
                          validate={{
                            required: { value: true, message: translate('entity.validation.required') },
                          }}
                        />
                        <ValidatedField
                          label={translate('hrmsApp.clientContact.contactNos')}
                          id="client-contact-contactNos"
                          name="contactNos"
                          data-cy="contactNos"
                          type="text"
                          validate={{
                            required: { value: true, message: translate('entity.validation.required') },
                            maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                          }}
                        />
                        <ValidatedField
                          label={translate('hrmsApp.clientContact.remarks')}
                          id="client-contact-remarks"
                          name="remarks"
                          data-cy="remarks"
                          type="text"
                        />
                        <Button color="info" id="cancel-save" data-cy="entityCreateCancelButton" onClick={handleClose} >
                          <FontAwesomeIcon icon="arrow-left" />
                        </Button>
                        &nbsp;
                        <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                          <FontAwesomeIcon icon="save" />
                        </Button>
                      </ValidatedForm>
                    )}
                  </Col>
                </Row>
              </div>
              ) : null
              }

            </div>
          ): null }
          </>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.businessClient.home.notFound">No Business Clients found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default BusinessClient;
