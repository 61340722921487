import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, isNumber, log, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { createEntity, getEntity, reset as resetEntity, updateEntity } from './allowance-item.reducer';
import { toNumber } from 'lodash';
import { useForm } from 'react-hook-form';

export const AllowanceItemUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = !id;

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const allowanceItemEntity = useAppSelector(state => state.allowanceItem.entity);
  const loading = useAppSelector(state => state.allowanceItem.loading);
  const updating = useAppSelector(state => state.allowanceItem.updating);
  const updateSuccess = useAppSelector(state => state.allowanceItem.updateSuccess);

  const [selectedAllowanceItemCoverage, setSelectedAllowanceItemCoverage] = useState<number | null>(null);
  const [selectedAllowanceType, setSelectedAllowanceType] = useState<string | null>(null);

  // Memoized default values to prevent unnecessary recalculation
  const defaultValues = useMemo(() => {
    return isNew
      ? {}
      : {
          ...allowanceItemEntity,
          code: allowanceItemEntity?.code?.id,
          allowanceItemCoverage: allowanceItemEntity?.allowanceItemCoverage?.id,
          allowanceVarianceFactor1: allowanceItemEntity?.allowanceVarianceFactor1?.id,
          allowanceVarianceFactor2: allowanceItemEntity?.allowanceVarianceFactor2?.id,
          category: allowanceItemEntity?.category?.id,
          type: allowanceItemEntity?.type?.id,
          currency: allowanceItemEntity?.currency?.id,
          status: allowanceItemEntity?.status?.id,
        };
  }, [allowanceItemEntity, isNew]);

  const { register, handleSubmit, setValue, watch, reset } = useForm({
    defaultValues,
  });

  const handleClose = useCallback(() => {
    navigate('/allowance-item' + location.search);
  }, [navigate]);

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }
    if (!hrCodes || hrCodes.length === 0) {
      dispatch(getHrCodes({}));
    }
  }, [dispatch, id, isNew, hrCodes]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess, handleClose]);

  useEffect(() => {
    if (selectedAllowanceItemCoverage !== null) {
      const filteredCurrencies = hrCodes
        ?.filter(
          outerEntity =>
            outerEntity.codeType === 'CUR' && outerEntity.id % 100 > 0 && outerEntity?.parent?.id === selectedAllowanceItemCoverage,
        )
        .sort((a, b) => a.id - b.id);

      const defaultCurrency = filteredCurrencies.length > 0 ? filteredCurrencies[0].id : null;
      // setSelectedCurrency(defaultCurrency);

      // Update the form value for the currency field
      if (defaultCurrency !== null) {
        // Assuming you're using `react-hook-form` or similar
        setValue('currency', defaultCurrency);
      }
    }
  }, [selectedAllowanceItemCoverage, hrCodes]);

  const saveEntity = (values: any) => {
    const entity = {
      ...allowanceItemEntity,
      ...values,
      code: hrCodes.find(it => it.id.toString() === values.code.toString()),
      category: hrCodes.find(it => it.id.toString() === values.category.toString()),
      type: hrCodes.find(it => it.id.toString() === values.type.toString()),
      currency: hrCodes.find(it => it.id.toString() === values.currency.toString()),
      allowanceItemCoverage: hrCodes.find(it => it.id.toString() === values.allowanceItemCoverage.toString()),
      allowanceVarianceFactor1: hrCodes.find(it => it.id.toString() === values?.allowanceVarianceFactor1?.toString()),
      allowanceVarianceFactor2: hrCodes.find(it => it.id.toString() === values?.allowanceVarianceFactor2?.toString()),
      status: isNew
        ? hrCodes.find(e => e.codeType === 'ALS' && e.codeName === 'Pending')
        : hrCodes.find(it => it.id.toString() === values.status.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const handleAllowanceTypeChange = useCallback(
    (selectedValue: number) => {
      const selectedAllowanceTypeCode = hrCodes.find(it => it.id === selectedValue);
      setSelectedAllowanceType(selectedAllowanceTypeCode?.codeName || null);
    },
    [hrCodes],
  );

  const handleAllowanceItemChange = useCallback(
    (selectedValue: number) => {
      const selectedAllowanceItemCode = hrCodes.find(it => it.id === selectedValue);
      const allowanceItemCoverageCode = selectedAllowanceItemCode?.parent?.id;
      setValue('allowanceItemCoverage', allowanceItemCoverageCode);
      if (allowanceItemCoverageCode !== null) {
        const filteredCurrencies = hrCodes
          ?.filter(
            outerEntity =>
              outerEntity.codeType === 'CUR' && outerEntity.id % 100 > 0 && outerEntity?.parent?.id === allowanceItemCoverageCode,
          )
          .sort((a, b) => a.id - b.id);

        const defaultCurrency = filteredCurrencies.length > 0 ? filteredCurrencies[0].id : null;
        // setSelectedCurrency(defaultCurrency);

        // Update the form value for the currency field
        if (defaultCurrency !== null) {
          // Assuming you're using `react-hook-form` or similar
          setValue('currency', defaultCurrency);
        }
      }
    },
    [hrCodes, allowanceItemEntity],
  );

  useEffect(() => {
    if (allowanceItemEntity) {
      // setSelectedAllowanceItemCoverage(allowanceItemEntity.allowanceItemCoverage?.id || null);

      const selectedAllowanceTypeCODE = hrCodes.find(it => it.id.toString() === allowanceItemEntity.type?.id?.toString());
      setSelectedAllowanceType(selectedAllowanceTypeCODE?.codeName || null);

      const allowanceItemCoverageID = allowanceItemEntity.allowanceItemCoverage?.id;
      if (allowanceItemCoverageID !== null) {
        const filteredCurrencies = hrCodes
          ?.filter(
            outerEntity => outerEntity.codeType === 'CUR' && outerEntity.id % 100 > 0 && outerEntity.parent?.id === allowanceItemCoverageID,
          )
          .sort((a, b) => a.id - b.id);

        // Set default currency based on filtered currencies
        setValue('currency', filteredCurrencies.length > 0 ? filteredCurrencies[0].id : null);
      }
    }
  }, [allowanceItemEntity, hrCodes]);

  useEffect(() => {
    if (defaultValues && Object.keys(defaultValues).length > 0) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5
            id="hrmsApp.allowanceItem.home.createOrEditLabel"
            data-cy="AllowanceItemCreateUpdateHeading"
            className="text-center fw-bold text-primary"
          >
            <Translate contentKey="hrmsApp.allowanceItem.home.createOrEditLabel">Create or edit a AllowanceItem</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center m-2 text-primary fw-bold">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues} onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="allowance-item-id"
                  label={translate('global.field.id') + '*'}
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  register={register}
                />
              ) : null}
              <ValidatedField
                id="allowance-item-code"
                name="code"
                data-cy="code"
                label={translate('hrmsApp.allowanceItem.code') + '*'}
                className="row d-flex"
                labelClass="col-md-4"
                inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                register={register}
                onChange={e => handleAllowanceItemChange(toNumber(e.target.value))}
              >
                <option value="" key="0" />
                {hrCodes
                  ? hrCodes
                      .filter(outerEntity => outerEntity.codeType === 'ALI' && outerEntity.id % 100 > 0)
                      .sort((a, b) => a.id - b.id)
                      .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                          {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                        </option>
                      ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="allowance-item-allowanceItemCoverage"
                name="allowanceItemCoverage"
                data-cy="allowanceItemCoverage"
                label={translate('hrmsApp.allowanceItem.allowanceItemCoverage') + '*'}
                className="row d-flex"
                labelClass="col-md-4"
                inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                register={register}
                disabled={true}
              >
                <option value="" key="0" />
                {hrCodes
                  ?.filter(outerEntity => outerEntity.codeType === 'COU' && outerEntity.id % 100 > 0)
                  .sort((a, b) => a.id - b.id)
                  .map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                      {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                    </option>
                  ))}
              </ValidatedField>
              <ValidatedField
                id="allowance-item-currency"
                name="currency"
                data-cy="currency"
                label={translate('hrmsApp.allowanceItem.currency') + '*'}
                className="row d-flex"
                labelClass="col-md-4"
                inputClass="col-md-8 custom-input flex-grow-1 form-control-md"
                type="select"
                register={register}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                disabled={true}
              >
                <option value="" key="0" />
                {hrCodes
                  ?.filter(outerEntity => outerEntity.codeType === 'CUR' && outerEntity.id % 100 > 0)
                  .sort((a, b) => a.id - b.id)
                  .map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                    </option>
                  ))}
              </ValidatedField>
              {/* <p>Selected Currency: {selectedCurrency}</p> */}
              <ValidatedField
                id="allowance-item-category"
                name="category"
                data-cy="category"
                label={translate('hrmsApp.allowanceItem.category') + '*'}
                className="row d-flex"
                labelClass="col-md-4"
                inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                register={register}
              >
                <option value="" key="0" />
                {hrCodes
                  ? hrCodes
                      .filter(outerEntity => outerEntity.codeType === 'ALC' && outerEntity.id % 100 > 0)
                      .sort((a, b) => a.id - b.id)
                      .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                          {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                        </option>
                      ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="allowance-item-type"
                name="type"
                data-cy="type"
                label={translate('hrmsApp.allowanceItem.type') + '*'}
                className="row d-flex"
                labelClass="col-md-4"
                inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                type="select"
                onChange={e => handleAllowanceTypeChange(toNumber(e.target.value))}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                register={register}
              >
                <option value="" key="0" />
                {hrCodes
                  ? hrCodes
                      .filter(outerEntity => outerEntity.codeType === 'ALT' && outerEntity.id % 100 > 0)
                      .sort((a, b) => a.id - b.id)
                      .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                          {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                        </option>
                      ))
                  : null}
              </ValidatedField>
              {/* Show Allowance Variance Factors only if type is PERCENTAGE */}
              {selectedAllowanceType === 'PERCENTAGE' ? (
                <ValidatedField
                  id="allowance-item-allowanceVarianceFactor1"
                  name="allowanceVarianceFactor1"
                  data-cy="allowanceVarianceFactor1"
                  label={translate('hrmsApp.allowanceItem.allowanceVarianceFactor1') + '*'}
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                  register={register}
                >
                  <option value="" key="0" />
                  {hrCodes
                    ?.filter(outerEntity => outerEntity.codeType === 'AVF' && outerEntity.id % 100 > 0 && outerEntity.codeSeq === 1)
                    .sort((a, b) => a.id - b.id)
                    .map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName}
                        {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}
                        {otherEntity.parent ? ` - [${otherEntity.parent.codeName}]` : ''}
                      </option>
                    ))}
                </ValidatedField>
              ) : null}
              {selectedAllowanceType === 'PERCENTAGE' ? (
                <ValidatedField
                  id="allowance-item-allowanceVarianceFactor2"
                  name="allowanceVarianceFactor2"
                  data-cy="allowanceVarianceFactor2"
                  label={translate('hrmsApp.allowanceItem.allowanceVarianceFactor2') + '*'}
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                  register={register}
                >
                  <option value="" key="0" />
                  {hrCodes
                    ?.filter(outerEntity => outerEntity.codeType === 'AVF' && outerEntity.id % 100 > 0 && outerEntity.codeSeq === 2)
                    .sort((a, b) => a.id - b.id)
                    .map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName}
                        {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}
                        {otherEntity.parent ? ` - [${otherEntity.parent.codeName}]` : ''}
                      </option>
                    ))}
                </ValidatedField>
              ) : null}
              <ValidatedField
                label={translate('hrmsApp.allowanceItem.curVal') + '*'}
                id="allowance-item-curVal"
                name="curVal"
                data-cy="curVal"
                className="row d-flex"
                labelClass="col-md-4"
                inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
                register={register}
              />
              <ValidatedField
                label={translate('hrmsApp.allowanceItem.startDate') + '*'}
                id="allowance-item-startDate"
                name="startDate"
                data-cy="startDate"
                className="row d-flex"
                labelClass="col-md-4"
                inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                type="date"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                register={register}
              />
              {!isNew ? (
                <ValidatedField
                  label={translate('hrmsApp.allowanceItem.nextVal')}
                  id="allowance-item-nextVal"
                  name="nextVal"
                  data-cy="nextVal"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="text"
                  register={register}
                />
              ) : null}
              {!isNew ? (
                <ValidatedField
                  label={translate('hrmsApp.allowanceItem.nextStartDate')}
                  id="allowance-item-nextStartDate"
                  name="nextStartDate"
                  data-cy="nextStartDate"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="date"
                  register={register}
                />
              ) : null}
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/allowance-item" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AllowanceItemUpdate;
