import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import MedicalPlan from './medical-plan';
import MedicalPlanDetail from './medical-plan-detail';
import MedicalPlanUpdate from './medical-plan-update';
import MedicalPlanDeleteDialog from './medical-plan-delete-dialog';

const MedicalPlanRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MedicalPlan />} />
    <Route path="new" element={<MedicalPlanUpdate />} />
    <Route path=":id">
      <Route index element={<MedicalPlanDetail />} />
      <Route path="edit" element={<MedicalPlanUpdate />} />
      <Route path="delete" element={<MedicalPlanDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MedicalPlanRoutes;
