import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity, updateEntity, getEntity } from './expense-claim.reducer';

export const ExpenseClaimSubmitDialog = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();

  const hrCodes = useAppSelector(state => state.hrCode.entities);

  const [loadModal, setLoadModal] = useState(false);

  useEffect(() => {
    dispatch(getEntity(id));
    setLoadModal(true);
  }, []);

  const expenseClaimEntity = useAppSelector(state => state.expenseClaim.entity);
  const updateSuccess = useAppSelector(state => state.expenseClaim.updateSuccess);

  const handleClose = () => {
    navigate('/expense-claim');
  };

  useEffect(() => {
    if (updateSuccess && loadModal) {
      handleClose();
      setLoadModal(false);
    }
  }, [updateSuccess]);

  const confirmSubmit = () => {
    const expenseClaim = { ...expenseClaimEntity, status: hrCodes.find(e => e.codeType === 'ECS' && e.codeName === 'Submitted') };

    dispatch(updateEntity(expenseClaim));
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="expenseClaimDeleteDialogHeading">
        <Translate contentKey="entity.submit.title">Confirm submit operation</Translate>
      </ModalHeader>
      <ModalBody id="hrmsApp.expenseClaim.submit.question">
        <Translate contentKey="hrmsApp.expenseClaim.submit.question" interpolate={{ id: expenseClaimEntity.id }}>
          Are you sure you want to submit this ExpenseClaim for approval?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="app-confirm-submit-expenseClaim" data-cy="entityconfirmSubmitButton" color="danger" onClick={confirmSubmit}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.submit">Submit</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ExpenseClaimSubmitDialog;
