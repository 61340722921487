import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './allowance-item.reducer';

export const AllowanceItemDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const allowanceItemEntity = useAppSelector(state => state.allowanceItem.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="allowanceItemDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.allowanceItem.detail.title">AllowanceItem</Translate>
        </h5>
        <dl className="jh-entity-details bg-white">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{allowanceItemEntity.id}</dd>
          <dt>
            <Translate contentKey="hrmsApp.allowanceItem.code">Code</Translate>
          </dt>
          <dd>{allowanceItemEntity.code ? allowanceItemEntity.code.codeName + ' : ' + allowanceItemEntity.code.codeDescription : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.allowanceItem.category">Category</Translate>
          </dt>
          <dd>{allowanceItemEntity.category ? allowanceItemEntity.category.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.allowanceItem.type">Type</Translate>
          </dt>
          <dd>{allowanceItemEntity.type ? allowanceItemEntity.type.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.allowanceItem.currency">Currency</Translate>
          </dt>
          <dd>{allowanceItemEntity.currency ? allowanceItemEntity.currency.codeName : ''}</dd>
          <dt>
            <span id="curVal">
              <Translate contentKey="hrmsApp.allowanceItem.curVal">Cur Val</Translate>
            </span>
          </dt>
          <dd>{allowanceItemEntity.curVal}</dd>
          <dt>
            <span id="startDate">
              <Translate contentKey="hrmsApp.allowanceItem.startDate">Start Date</Translate>
            </span>
          </dt>
          <dd>
            {allowanceItemEntity.startDate ? (
              <TextFormat value={allowanceItemEntity.startDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="nextVal">
              <Translate contentKey="hrmsApp.allowanceItem.nextVal">Next Val</Translate>
            </span>
          </dt>
          <dd>{allowanceItemEntity.nextVal}</dd>
          <dt>
            <span id="nextStartDate">
              <Translate contentKey="hrmsApp.allowanceItem.nextStartDate">Next Start Date</Translate>
            </span>
          </dt>
          <dd>
            {allowanceItemEntity.nextStartDate ? (
              <TextFormat value={allowanceItemEntity.nextStartDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="hrmsApp.allowanceItem.status">Status</Translate>
          </dt>
          <dd>{allowanceItemEntity.status ? allowanceItemEntity.status.codeName : ''}</dd>
        </dl>
        <Button tag={Link} to="/allowance-item" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/allowance-item/${allowanceItemEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AllowanceItemDetail;
