import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AllowancePlan from './allowance-plan';
import AllowancePlanDetail from './allowance-plan-detail';
import AllowancePlanUpdate from './allowance-plan-update';
import AllowancePlanDeleteDialog from './allowance-plan-delete-dialog';

const AllowancePlanRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AllowancePlan />} />
    <Route path="new" element={<AllowancePlanUpdate />} />
    <Route path=":id">
      <Route index element={<AllowancePlanDetail />} />
      <Route path="edit" element={<AllowancePlanUpdate />} />
      <Route path="delete" element={<AllowancePlanDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AllowancePlanRoutes;
