import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { ASC } from 'app/shared/util/pagination.constants';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ITimeSheetRecord, defaultValue } from 'app/shared/model/time-sheet-record.model';

const initialState: EntityState<ITimeSheetRecord> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/time-sheets-record';

// Actions

export const getEntities = createAsyncThunk(
  'timeSheet_record/fetch_entity_list',
  async ({ query, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}?${query ? `${query}&` : ''}${sort ? `sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
    return axios.get<ITimeSheetRecord[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getEntity = createAsyncThunk(
  'timeSheet_record/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ITimeSheetRecord>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getEmployeeTimeSheets = createAsyncThunk(
  'timeSheet_record/fetch_employee_timesheets',
  async ({ year, weekNumber, sort }: { year: number; weekNumber: number; sort?: string }) => {
    const requestUrl = `${apiUrl}/employees-with-timesheets?year=${year}&weekNumber=${weekNumber}${sort ? `&sort=${sort}` : ''}`;
    return axios.get<Map<string, ITimeSheetRecord[]>>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const TimeSheetRecordSlice = createEntitySlice({
  name: 'employeeTimeSheetRecord',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEmployeeTimeSheets.fulfilled, (state, action) => {
        state.loading = false;
        // state.entities = Object.values(action.payload.data).flat(); // Flatten the map into an array
        state.entities = Object.values(action.payload.data).flat(); // Flatten the map into an array
      })
      .addMatcher(isFulfilled(getEmployeeTimeSheets), (state, action) => {
        const rawData = action.payload.data;
        state.entities = Object.values(rawData || {}).flat();
        state.loading = false;
      })

      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data.sort((a, b) => {
            if (!action.meta?.arg?.sort) {
              return 1;
            }
            const order = action.meta.arg.sort.split(',')[1];
            const predicate = action.meta.arg.sort.split(',')[0];
            return order === ASC ? (a[predicate] < b[predicate] ? -1 : 1) : b[predicate] < a[predicate] ? -1 : 1;
          }),
        };
      });
  },
});

export const { reset } = TimeSheetRecordSlice.actions;

// Reducer
export default TimeSheetRecordSlice.reducer;
