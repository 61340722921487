import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useCallback } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { reset } from './expense-item.reducer';
import { useForm } from 'react-hook-form';

const ExpenseItemUpdateModal = ({ isOpen, toggle, expenseClaimId, expenseItemId, onItemAdded }) => {
  if (!isOpen) return null;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isNew = expenseItemId === '0';

  const expenseClaims = useAppSelector(state => state.expenseClaim.entities);
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const expenseItemEntity = useAppSelector(state => state.expenseItem.entity);
  const loading = useAppSelector(state => state.expenseItem.loading);
  const updating = useAppSelector(state => state.expenseItem.updating);

  const handleClose = useCallback(() => {
    navigate(`/expense-claim/${expenseClaimId}/edit`);
    toggle();
  }, [navigate, expenseClaimId, toggle]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const saveEntity = useCallback(
    values => {
      const entity = {
        ...expenseItemEntity,
        ...values,
        expenseClaim: expenseClaims.find(it => it.id.toString() === expenseClaimId?.toString()),
        expenseType: hrCodes.find(it => it.id.toString() === values.expenseType?.toString()),
        currecny: hrCodes.find(it => it.id.toString() === values.currecny?.toString()),
        status: isNew
          ? hrCodes.find(e => e.codeType === 'WKS' && e.codeName === 'Pending')
          : hrCodes.find(it => it.id.toString() === values.status?.toString()),
        id: expenseItemEntity.id || Date.now(),
      };

      onItemAdded(entity);
    },
    [expenseItemEntity, expenseClaims, hrCodes, expenseClaimId, isNew, onItemAdded],
  );

  const defaultValues = useMemo(
    () =>
      isNew
        ? {}
        : {
            ...expenseItemEntity,
            expenseClaim: expenseItemEntity?.expenseClaim?.id,
            expenseType: expenseItemEntity?.expenseType?.id,
            currecny: expenseItemEntity?.currecny?.id,
            status: expenseItemEntity?.status?.id,
          },
    [isNew, expenseItemEntity],
  );

  const { register, handleSubmit, getValues } = useForm({
    defaultValues,
  });

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <div className="text-primary text-center fw-bold">Add/Modify Expense Item</div>
      </ModalHeader>
      <ModalBody>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <ValidatedForm defaultValues={defaultValues} onSubmit={saveEntity}>
                {!isNew && (
                  <ValidatedField
                    name="id"
                    required
                    readOnly
                    id="expense-item-id"
                    label={translate('global.field.id')}
                    validate={{ required: true }}
                    register={register}
                  />
                )}
                <ValidatedField
                  label={translate('hrmsApp.expenseItem.expDate')}
                  id="expense-item-expDate"
                  name="expDate"
                  type="date"
                  validate={{ required: { value: true, message: translate('entity.validation.required') } }}
                  register={register}
                />
                <ValidatedField
                  id="expense-item-expenseType"
                  name="expenseType"
                  label={translate('hrmsApp.expenseItem.expenseType')}
                  type="select"
                  required
                  register={register}
                >
                  <option value="" key="0" />
                  {hrCodes
                    .filter(e => e.codeType === 'XPT' && e.id % 100 > 0)
                    .map(e => (
                      <option key={e.id} value={e.id}>
                        {e.codeName}
                      </option>
                    ))}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.expenseItem.description')}
                  id="expense-item-description"
                  name="description"
                  type="text"
                  register={register}
                />
                <ValidatedField
                  label={translate('hrmsApp.expenseItem.receiptNo')}
                  id="expense-item-receiptNo"
                  name="receiptNo"
                  type="text"
                  register={register}
                />
                <ValidatedField
                  label={translate('hrmsApp.expenseItem.amount')}
                  id="expense-item-amount"
                  name="amount"
                  type="text"
                  register={register}
                />
                <ValidatedField
                  label={translate('hrmsApp.expenseItem.exchangeRate')}
                  id="expense-item-exchangeRate"
                  name="exchangeRate"
                  type="text"
                  register={register}
                />
                <ValidatedField
                  id="expense-item-currecny"
                  name="currecny"
                  label={translate('hrmsApp.expenseItem.currecny')}
                  type="select"
                  required
                  register={register}
                >
                  <option value="" key="0" />
                  {hrCodes
                    .filter(e => e.codeType === 'CUR' && e.id % 100 > 0)
                    .map(e => (
                      <option key={e.id} value={e.id}>
                        {e.codeName}
                      </option>
                    ))}
                </ValidatedField>
                <Button color="primary" type="button" onClick={() => saveEntity(getValues())} disabled={updating}>
                  <FontAwesomeIcon icon="save" /> &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </ValidatedForm>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ExpenseItemUpdateModal;
