import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { Translate, getSortState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import { getEntities as getBusinessClients } from 'app/entities/business-client/business-client.reducer';
import { getEntities } from './client-contact.reducer';

export const ClientContact = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const clientContactList = useAppSelector(state => state.clientContact.entities);
  const businessClients = useAppSelector(state => state.businessClient.entities);
  const loading = useAppSelector(state => state.clientContact.loading);
  const hrCodes = useAppSelector(state => state.hrCode.entities);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
    dispatch(getBusinessClients({}));
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h5 id="client-contact-heading" data-cy="ClientContactHeading" className="text-center text-primary fw-bold p-1">
        <Translate contentKey="hrmsApp.clientContact.home.title">Client Contacts</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            {/* <Translate contentKey="hrmsApp.clientContact.home.refreshListLabel">Refresh List</Translate> */}
          </Button>
          <Link to="/client-contact/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            {/* <Translate contentKey="hrmsApp.clientContact.home.createLabel">Create new Client Contact</Translate> */}
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {clientContactList && clientContactList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hrmsApp.clientContact.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('firstName')}>
                  <Translate contentKey="hrmsApp.clientContact.firstName">First Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('firstName')} />
                </th>
                <th className="hand" onClick={sort('lastName')}>
                  <Translate contentKey="hrmsApp.clientContact.lastName">Last Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('lastName')} />
                </th>
                <th className="hand" onClick={sort('designation')}>
                  <Translate contentKey="hrmsApp.clientContact.designation">Designation</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('designation')} />
                </th>
                <th className="hand" onClick={sort('email')}>
                  <Translate contentKey="hrmsApp.clientContact.email">Email</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('email')} />
                </th>
                <th className="hand" onClick={sort('contactNos')}>
                  <Translate contentKey="hrmsApp.clientContact.contactNos">Contact Nos</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('contactNos')} />
                </th>
                <th className="hand" onClick={sort('remarks')}>
                  <Translate contentKey="hrmsApp.clientContact.remarks">Remarks</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('remarks')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.clientContact.businessClient">Business Client</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.clientContact.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {clientContactList.map((clientContact, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/client-contact/${clientContact.id}`} color="link" size="sm">
                      {clientContact.id}
                    </Button>
                  </td>
                  <td>{clientContact.firstName}</td>
                  <td>{clientContact.lastName}</td>
                  <td>{clientContact.designation}</td>
                  <td>{clientContact.email}</td>
                  <td>{clientContact.contactNos}</td>
                  <td>{clientContact.remarks}</td>
                  <td>
                    {clientContact.businessClient ? businessClients.find(e => e.id === clientContact.businessClient.id)?.clientName : ''}
                  </td>
                  <td>{clientContact.status ? hrCodes.find(e => e.id === clientContact.status.id)?.codeName : ''}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/client-contact/${clientContact.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/client-contact/${clientContact.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() => (window.location.href = `/client-contact/${clientContact.id}/delete`)}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.clientContact.home.notFound">No Client Contacts found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ClientContact;
