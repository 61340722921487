import dayjs from 'dayjs';
import { IEmployee } from 'app/shared/model/employee.model';
import { IHrCode } from 'app/shared/model/hr-code.model';

export interface IAuthorityDelegation {
  id?: number;
  startedOn?: dayjs.Dayjs;
  endedOn?: dayjs.Dayjs | null;
  delegator?: IEmployee;
  delegatee?: IEmployee;
  status?: IHrCode;
}

export const defaultValue: Readonly<IAuthorityDelegation> = {};
