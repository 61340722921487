import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './leaves-plan.reducer';

export const LeavesPlanDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const leavesPlanEntity = useAppSelector(state => state.leavesPlan.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="leavesPlanDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.leavesPlan.detail.title">LeavesPlan</Translate>
        </h5>
        <dl className="jh-entity-details bg-white">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{leavesPlanEntity.id}</dd>
          <dt>
            <span id="qty">
              <Translate contentKey="hrmsApp.leavesPlan.qty">Qty</Translate>
            </span>
          </dt>
          <dd>{leavesPlanEntity.qty}</dd>
          <dt>
            <span id="cumulativeLimit">
              <Translate contentKey="hrmsApp.leavesPlan.cumulativeLimit">Cumulative Limit</Translate>
            </span>
          </dt>
          <dd>{leavesPlanEntity.cumulativeLimit}</dd>
          <dt>
            <span id="startDate">
              <Translate contentKey="hrmsApp.leavesPlan.startDate">Start Date</Translate>
            </span>
          </dt>
          <dd>
            {leavesPlanEntity.startDate ? (
              <TextFormat value={leavesPlanEntity.startDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="endDate">
              <Translate contentKey="hrmsApp.leavesPlan.endDate">End Date</Translate>
            </span>
          </dt>
          <dd>
            {leavesPlanEntity.endDate ? <TextFormat value={leavesPlanEntity.endDate} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="hrmsApp.leavesPlan.planName">Plan Name</Translate>
          </dt>
          <dd>{leavesPlanEntity.planName ? leavesPlanEntity.planName.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.leavesPlan.itemName">Item Name</Translate>
          </dt>
          <dd>{leavesPlanEntity.itemName ? leavesPlanEntity.itemName.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.leavesPlan.status">Status</Translate>
          </dt>
          <dd>{leavesPlanEntity.status ? leavesPlanEntity.status.codeName : ''}</dd>
        </dl>
        <Button tag={Link} to="/leaves-plan" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/leaves-plan/${leavesPlanEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default LeavesPlanDetail;
