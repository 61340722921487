import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './business-client.reducer';

export const BusinessClientDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const businessClientEntity = useAppSelector(state => state.businessClient.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="businessClientDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.businessClient.detail.title">BusinessClient</Translate>
        </h5>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{businessClientEntity.id}</dd>
          <dt>
            <span id="clientName">
              <Translate contentKey="hrmsApp.businessClient.clientName">Client Name</Translate>
            </span>
          </dt>
          <dd>{businessClientEntity.clientName}</dd>
          <dt>
            <span id="clientCode">
              <Translate contentKey="hrmsApp.businessClient.clientCode">Client Code</Translate>
            </span>
          </dt>
          <dd>{businessClientEntity.clientCode}</dd>
          <dt>
            <span id="curAddress">
              <Translate contentKey="hrmsApp.businessClient.curAddress">Cur Address</Translate>
            </span>
          </dt>
          <dd>{businessClientEntity.curAddress}</dd>
          <dt>
            <span id="regNo">
              <Translate contentKey="hrmsApp.businessClient.regNo">Reg No</Translate>
            </span>
          </dt>
          <dd>{businessClientEntity.regNo}</dd>
          <dt>
            <span id="remarks">
              <Translate contentKey="hrmsApp.businessClient.remarks">Remarks</Translate>
            </span>
          </dt>
          <dd>{businessClientEntity.remarks}</dd>
          <dt>
            <Translate contentKey="hrmsApp.businessClient.country">Country</Translate>
          </dt>
          <dd>{businessClientEntity.country ? businessClientEntity.country.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.businessClient.status">Status</Translate>
          </dt>
          <dd>{businessClientEntity.status ? businessClientEntity.status.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/business-client" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/business-client/${businessClientEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default BusinessClientDetail;
