import dayjs from 'dayjs';
import { IEmployee } from 'app/shared/model/employee.model';

export interface IEmpExperience {
  id?: number;
  organization?: string;
  fromDate?: dayjs.Dayjs;
  toDate?: dayjs.Dayjs | null;
  lastRole?: string;
  responsibility?: string | null;
  achievement?: string | null;
  stillWorking?: boolean | null;
  employee?: IEmployee;
}

export const defaultValue: Readonly<IEmpExperience> = {
  stillWorking: false,
};
