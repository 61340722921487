import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { MaritalStatus } from 'app/shared/model/enumerations/marital-status.model';
import { createEntity, getEntity, reset, updateEntity } from './emp-profile.reducer';

export const EmpProfileUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const employees = useAppSelector(state => state.employee.entities);
  const empProfileEntity = useAppSelector(state => state.empProfile.entity);
  const loading = useAppSelector(state => state.empProfile.loading);
  const updating = useAppSelector(state => state.empProfile.updating);
  const updateSuccess = useAppSelector(state => state.empProfile.updateSuccess);
  const maritalStatusValues = Object.keys(MaritalStatus);

  const handleClose = () => {
    navigate('/emp-profile' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    // dispatch(getHrCodes({}));
    // dispatch(getEmployees({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.cnic !== undefined && typeof values.cnic !== 'number') {
      values.cnic = Number(values.cnic);
    }
    if (values.personalMobile !== undefined && typeof values.personalMobile !== 'number') {
      values.personalMobile = Number(values.personalMobile);
    }
    if (values.personalLandline !== undefined && typeof values.personalLandline !== 'number') {
      values.personalLandline = Number(values.personalLandline);
    }

    const entity = {
      ...empProfileEntity,
      ...values,
      gender: hrCodes.find(it => it.id.toString() === values.gender.toString()),
      employee: employees.find(it => it.id.toString() === values.employee.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          maritalStatus: 'Single',
          ...empProfileEntity,
          gender: empProfileEntity?.gender?.id,
          employee: empProfileEntity?.employee?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5
            id="hrmsApp.empProfile.home.createOrEditLabel"
            data-cy="EmpProfileCreateUpdateHeading"
            className="text-center fw-bold text-primary"
          >
            <Translate contentKey="hrmsApp.empProfile.home.createOrEditLabel">Create or edit a EmpProfile</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center fw-bold">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="emp-profile-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('hrmsApp.empProfile.maritalStatus')}
                id="emp-profile-maritalStatus"
                name="maritalStatus"
                data-cy="maritalStatus"
                type="select"
              >
                {maritalStatusValues.map(maritalStatus => (
                  <option value={maritalStatus} key={maritalStatus}>
                    {translate('hrmsApp.MaritalStatus.' + maritalStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('hrmsApp.empProfile.cnic')}
                id="emp-profile-cnic"
                name="cnic"
                data-cy="cnic"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  min: { value: 1000000000001, message: translate('entity.validation.min', { min: 1000000000001 }) },
                  max: { value: 9999999999999, message: translate('entity.validation.max', { max: 9999999999999 }) },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.cnicIssueDate')}
                id="emp-profile-cnicIssueDate"
                name="cnicIssueDate"
                data-cy="cnicIssueDate"
                type="date"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.cnicExpiryDate')}
                id="emp-profile-cnicExpiryDate"
                name="cnicExpiryDate"
                data-cy="cnicExpiryDate"
                type="date"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.cnicUrl')}
                id="emp-profile-cnicUrl"
                name="cnicUrl"
                data-cy="cnicUrl"
                type="text"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.passportNo')}
                id="emp-profile-passportNo"
                name="passportNo"
                data-cy="passportNo"
                type="text"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.passportIssueDate')}
                id="emp-profile-passportIssueDate"
                name="passportIssueDate"
                data-cy="passportIssueDate"
                type="date"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.passportExpiryDate')}
                id="emp-profile-passportExpiryDate"
                name="passportExpiryDate"
                data-cy="passportExpiryDate"
                type="date"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.passportUrl')}
                id="emp-profile-passportUrl"
                name="passportUrl"
                data-cy="passportUrl"
                type="text"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.personalMobile')}
                id="emp-profile-personalMobile"
                name="personalMobile"
                data-cy="personalMobile"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  min: { value: 1000000001, message: translate('entity.validation.min', { min: 1000000001 }) },
                  max: { value: 999999999999, message: translate('entity.validation.max', { max: 999999999999 }) },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.personalLandline')}
                id="emp-profile-personalLandline"
                name="personalLandline"
                data-cy="personalLandline"
                type="text"
                validate={{
                  max: { value: 999999999999, message: translate('entity.validation.max', { max: 999999999999 }) },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.personalEmailAddress')}
                id="emp-profile-personalEmailAddress"
                name="personalEmailAddress"
                data-cy="personalEmailAddress"
                type="text"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.curAddressLine1')}
                id="emp-profile-curAddressLine1"
                name="curAddressLine1"
                data-cy="curAddressLine1"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.curAddressLine2')}
                id="emp-profile-curAddressLine2"
                name="curAddressLine2"
                data-cy="curAddressLine2"
                type="text"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.permAddressLine1')}
                id="emp-profile-permAddressLine1"
                name="permAddressLine1"
                data-cy="permAddressLine1"
                type="text"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.permAddressLine2')}
                id="emp-profile-permAddressLine2"
                name="permAddressLine2"
                data-cy="permAddressLine2"
                type="text"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.profilePicUrl')}
                id="emp-profile-profilePicUrl"
                name="profilePicUrl"
                data-cy="profilePicUrl"
                type="text"
              />
              <ValidatedField
                id="emp-profile-gender"
                name="gender"
                data-cy="gender"
                label={translate('hrmsApp.empProfile.gender')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hrCodes
                  ? hrCodes
                      .filter(outerEntity => outerEntity.codeType === 'GND' && outerEntity.id % 100 > 0)
                      .sort((a, b) => a.id - b.id)
                      .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                          {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                        </option>
                      ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="emp-profile-employee"
                name="employee"
                data-cy="employee"
                label={translate('hrmsApp.empProfile.employee')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {employees
                  ? employees.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.qlid} : {otherEntity.firstName} {otherEntity.lastName}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/emp-profile" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EmpProfileUpdate;
