import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './contract.reducer';

export const ContractDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const contractEntity = useAppSelector(state => state.contract.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="contractDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.contract.detail.title">Contract</Translate>
        </h5>
        <dl className="jh-entity-details bg-white">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{contractEntity.id}</dd>
          <dt>
            <span id="startedOn">
              <Translate contentKey="hrmsApp.contract.startedOn">Started On</Translate>
            </span>
          </dt>
          <dd>
            {contractEntity.startedOn ? <TextFormat value={contractEntity.startedOn} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="endedOn">
              <Translate contentKey="hrmsApp.contract.endedOn">Ended On</Translate>
            </span>
          </dt>
          <dd>
            {contractEntity.endedOn ? <TextFormat value={contractEntity.endedOn} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="probationCompletedOn">
              <Translate contentKey="hrmsApp.contract.probationCompletedOn">Probation Completed On</Translate>
            </span>
          </dt>
          <dd>
            {contractEntity.probationCompletedOn ? (
              <TextFormat value={contractEntity.probationCompletedOn} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="hourlyRate">
              <Translate contentKey="hrmsApp.contract.hourlyRate">Hourly Rate</Translate>
            </span>
          </dt>
          <dd>{contractEntity.hourlyRate}</dd>
          <dt>
            <span id="baseSalary">
              <Translate contentKey="hrmsApp.contract.baseSalary">Base Salary</Translate>
            </span>
          </dt>
          <dd>{contractEntity.baseSalary}</dd>
          <dt>
            <span id="onsiteAllowance">
              <Translate contentKey="hrmsApp.contract.onsiteAllowance">Onsite Allowance</Translate>
            </span>
          </dt>
          <dd>{contractEntity.onsiteAllowance}</dd>
          <dt>
            <span id="flightAllowance">
              <Translate contentKey="hrmsApp.contract.flightAllowance">Flight Allowance</Translate>
            </span>
          </dt>
          <dd>{contractEntity.flightAllowance}</dd>
          <dt>
            <span id="aip">
              <Translate contentKey="hrmsApp.contract.aip">Aip</Translate>
            </span>
          </dt>
          <dd>{contractEntity.aip}</dd>
          <dt>
            <span id="performanceBonus">
              <Translate contentKey="hrmsApp.contract.performanceBonus">Performance Bonus</Translate>
            </span>
          </dt>
          <dd>{contractEntity.performanceBonus}</dd>
          <dt>
            <span id="revisionNo">
              <Translate contentKey="hrmsApp.contract.revisionNo">Revision No</Translate>
            </span>
          </dt>
          <dd>{contractEntity.revisionNo}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.employee">Employee</Translate>
          </dt>
          <dd>{contractEntity.employee ? contractEntity.employee.qlid : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.contractTemplate">Contract Template</Translate>
          </dt>
          <dd>{contractEntity.contractTemplate ? contractEntity.contractTemplate.id : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.payrollOffice">Payroll Office</Translate>
          </dt>
          <dd>{contractEntity.payrollOffice ? contractEntity.payrollOffice.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.currency">Currency</Translate>
          </dt>
          <dd>{contractEntity.currency ? contractEntity.currency.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.designation">Designation</Translate>
          </dt>
          <dd>{contractEntity.designation ? contractEntity.designation.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.jobRole">Job Role</Translate>
          </dt>
          <dd>{contractEntity.jobRole ? contractEntity.jobRole.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.jobGrade">Job Grade</Translate>
          </dt>
          <dd>{contractEntity.jobGrade ? contractEntity.jobGrade.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.department">Department</Translate>
          </dt>
          <dd>{contractEntity.department ? contractEntity.department.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.location">Location</Translate>
          </dt>
          <dd>{contractEntity.location ? contractEntity.location.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.allowancePlan">Allowance Plan</Translate>
          </dt>
          <dd>{contractEntity.allowancePlan ? contractEntity.allowancePlan.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.aipPlan">Aip Plan</Translate>
          </dt>
          <dd>{contractEntity.aipPlan ? contractEntity.aipPlan.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.bonusPlan">Bonus Plan</Translate>
          </dt>
          <dd>{contractEntity.bonusPlan ? contractEntity.bonusPlan.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.gfPlan">Gf Plan</Translate>
          </dt>
          <dd>{contractEntity.gfPlan ? contractEntity.gfPlan.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.pfPlan">Pf Plan</Translate>
          </dt>
          <dd>{contractEntity.pfPlan ? contractEntity.pfPlan.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.medicalPlan">Medical Plan</Translate>
          </dt>
          <dd>{contractEntity.medicalPlan ? contractEntity.medicalPlan.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.leavesPlan">Leaves Plan</Translate>
          </dt>
          <dd>{contractEntity.leavesPlan ? contractEntity.leavesPlan.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.contract.contractStatus">Contract Status</Translate>
          </dt>
          <dd>{contractEntity.contractStatus ? contractEntity.contractStatus.codeName : ''}</dd>
        </dl>
        <Button tag={Link} to="/contract" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/contract/${contractEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ContractDetail;
