import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './hr-code.reducer';

export const HrCodeDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const hrCodeEntity = useAppSelector(state => state.hrCode.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="hrCodeDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.hrCode.detail.title">HrCode</Translate>
        </h5>
        <dl className="jh-entity-details bg-white">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{hrCodeEntity.id}</dd>
          <dt>
            <span id="codeType">
              <Translate contentKey="hrmsApp.hrCode.codeType">Code Type</Translate>
            </span>
          </dt>
          <dd>{hrCodeEntity.codeType}</dd>
          <dt>
            <span id="codeName">
              <Translate contentKey="hrmsApp.hrCode.codeName">Code Name</Translate>
            </span>
          </dt>
          <dd>{hrCodeEntity.codeName}</dd>
          <dt>
            <span id="codeDescription">
              <Translate contentKey="hrmsApp.hrCode.codeDescription">Code Description</Translate>
            </span>
          </dt>
          <dd>{hrCodeEntity.codeDescription}</dd>
          <dt>
            <span id="codeStatus">
              <Translate contentKey="hrmsApp.hrCode.codeStatus">Code Status</Translate>
            </span>
          </dt>
          <dd>{hrCodeEntity.codeStatus}</dd>
          <dt>
            <span id="codeSeq">
              <Translate contentKey="hrmsApp.hrCode.codeSeq">Code Seq</Translate>
            </span>
          </dt>
          <dd>{hrCodeEntity.codeSeq}</dd>
          <dt>
            <Translate contentKey="hrmsApp.hrCode.parent">Parent</Translate>
          </dt>
          <dd>{hrCodeEntity.parent ? hrCodeEntity.parent.id + '-' + hrCodeEntity.parent.codeName : ''}</dd>
        </dl>
        <Button tag={Link} to="/hr-code" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/hr-code/${hrCodeEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default HrCodeDetail;
