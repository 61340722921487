import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { createEntity, getEntity, reset, updateEntity } from './medical-plan.reducer';

// Helper function to format number with commas and two decimal places
const formatNumber = (value) => {
  // Remove non-numeric characters
  const numericValue = value.replace(/[^\d.]/g, '');
  // Format with commas and two decimal places
  return parseFloat(numericValue).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

const FormattedNumberInput = ({ label, name, validate, ...rest }) => {
  const [formattedValue, setFormattedValue] = useState('');

  const handleChange = (event) => {
    const { value } = event.target;
    const formatted = formatNumber(value);
    setFormattedValue(formatted);
    // Pass the unformatted value to the parent component
    rest.onChange && rest.onChange(value.replace(/[^\d.]/g, ''));
  };

  return (
    <ValidatedField
      label={label}
      name={name}
      validate={validate}
      {...rest}
      value={formattedValue} // Use formatted value
      onChange={handleChange} // Handle change event to update state
    />
  );
};



export const MedicalPlanUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const medicalPlanEntity = useAppSelector(state => state.medicalPlan.entity);
  const loading = useAppSelector(state => state.medicalPlan.loading);
  const updating = useAppSelector(state => state.medicalPlan.updating);
  const updateSuccess = useAppSelector(state => state.medicalPlan.updateSuccess);

  const handleClose = () => {
    navigate('/medical-plan' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getHrCodes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.monthlyLimit !== undefined && typeof values.monthlyLimit !== 'number') {
      values.monthlyLimit = Number(values.monthlyLimit);
    }
    if (values.yearlyLimit !== undefined && typeof values.yearlyLimit !== 'number') {
      values.yearlyLimit = Number(values.yearlyLimit);
    }

    const entity = {
      ...medicalPlanEntity,
      ...values,
      planName: hrCodes.find(it => it.id.toString() === values.planName.toString()),
      itemName: hrCodes.find(it => it.id.toString() === values.itemName.toString()),
      status: hrCodes.find(it => it.id.toString() === values.status.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...medicalPlanEntity,
          planName: medicalPlanEntity?.planName?.id,
          itemName: medicalPlanEntity?.itemName?.id,
          status: medicalPlanEntity?.status?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5
            id="hrmsApp.medicalPlan.home.createOrEditLabel"
            data-cy="MedicalPlanCreateUpdateHeading"
            className="text-center fw-bold text-primary"
          >
            <Translate contentKey="hrmsApp.medicalPlan.home.createOrEditLabel">Create or edit a MedicalPlan</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center text-primary">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="medical-plan-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('hrmsApp.medicalPlan.monthlyLimit')}
                id="medical-plan-monthlyLimit"
                name="monthlyLimit"
                data-cy="monthlyLimit"
                type="number"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.medicalPlan.yearlyLimit')}
                id="medical-plan-yearlyLimit"
                name="yearlyLimit"
                data-cy="yearlyLimit"
                type="number"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.medicalPlan.startDate')}
                id="medical-plan-startDate"
                name="startDate"
                data-cy="startDate"
                type="date"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.medicalPlan.endDate')}
                id="medical-plan-endDate"
                name="endDate"
                data-cy="endDate"
                type="date"
              />
              <ValidatedField
                id="medical-plan-planName"
                name="planName"
                data-cy="planName"
                label={translate('hrmsApp.medicalPlan.planName')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'MDP' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="medical-plan-itemName"
                name="itemName"
                data-cy="itemName"
                label={translate('hrmsApp.medicalPlan.itemName')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'MDI' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="medical-plan-status"
                name="status"
                data-cy="status"
                label={translate('hrmsApp.medicalPlan.status')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'ALS' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/medical-plan" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MedicalPlanUpdate;
