import dayjs from 'dayjs';
import { IContract } from 'app/shared/model/contract.model';
import { IBusinessProject } from 'app/shared/model/business-project.model';
import { IHrCode } from 'app/shared/model/hr-code.model';

export interface ILeavesRecord {
  id?: number;
  fromDate?: dayjs.Dayjs;
  toDate?: dayjs.Dayjs | null;
  dayCount?: number;
  remarks?: string | null;
  dated?: dayjs.Dayjs;
  attachmentPath?: string | null;
  contract?: IContract;
  businessProject?: IBusinessProject | null;
  leaveItem?: IHrCode;
  status?: IHrCode;
}

export const defaultValue: Readonly<ILeavesRecord> = {};
