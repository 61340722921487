import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IFcmToken {
  id?: number;
  token?: string;
  createdAt?: dayjs.Dayjs;
  lastAck?: dayjs.Dayjs | null;
  user?: IUser;
}

export const defaultValue: Readonly<IFcmToken> = {};
