import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './allowance-plan.reducer';

export const AllowancePlanDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const allowancePlanEntity = useAppSelector(state => state.allowancePlan.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="allowancePlanDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.allowancePlan.detail.title">AllowancePlan</Translate>
        </h5>
        <dl className="jh-entity-details bg-white">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{allowancePlanEntity.id}</dd>
          <dt>
            <Translate contentKey="hrmsApp.allowancePlan.allowancePlan">Allowance Plan</Translate>
          </dt>
          <dd>{allowancePlanEntity.allowancePlan ? allowancePlanEntity.allowancePlan.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.allowancePlan.allowanceItem">Allowance Item</Translate>
          </dt>
          <dd>{allowancePlanEntity.allowanceItem ? allowancePlanEntity.allowanceItem.codeName : ''}</dd>
          <dt>
            <span id="startDate">
              <Translate contentKey="hrmsApp.allowancePlan.startDate">Start Date</Translate>
            </span>
          </dt>
          <dd>
            {allowancePlanEntity.startDate ? (
              <TextFormat value={allowancePlanEntity.startDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="endDate">
              <Translate contentKey="hrmsApp.allowancePlan.endDate">End Date</Translate>
            </span>
          </dt>
          <dd>
            {allowancePlanEntity.endDate ? (
              <TextFormat value={allowancePlanEntity.endDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="hrmsApp.allowancePlan.allowanceStatus">Allowance Status</Translate>
          </dt>
          <dd>{allowancePlanEntity.allowanceStatus ? allowancePlanEntity.allowanceStatus.codeName : ''}</dd>
        </dl>
        <Button tag={Link} to="/allowance-plan" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/allowance-plan/${allowancePlanEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AllowancePlanDetail;
