import React from 'react';
import { Translate } from 'react-jhipster';
import { Navigate, PathRouteProps, useLocation } from 'react-router-dom';

import { useAppSelector } from 'app/config/store';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { IAuthMatrix } from '../model/auth-matrix.model';

interface IOwnProps extends PathRouteProps {
  entity?: string;
  hasAnyAuthorities?: string[];
  authMatrix?: IAuthMatrix[];
  children: React.ReactNode;
}
/* eslint no-console: off */
export const PrivateRoute = ({ children, entity, hasAnyAuthorities = [], authMatrix, ...rest }: IOwnProps) => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const sessionHasBeenFetched = useAppSelector(state => state.authentication.sessionHasBeenFetched);
  const account = useAppSelector(state => state.authentication.account);
  const isAuthorized = hasAuthority(account.authorities, hasAnyAuthorities, authMatrix, entity);
  const pageLocation = useLocation();

  if (!children) {
    throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`);
  }

  if (!sessionHasBeenFetched) {
    return <div></div>;
  }

  if (isAuthenticated) {
    console.log('-----> isAuthorized: ', isAuthorized);
    if (isAuthorized) {
      return <ErrorBoundary>{children}</ErrorBoundary>;
    }

    return (
      <div className="insufficient-authority">
        <div className="alert alert-danger">
          <Translate contentKey="error.http.403">You are not authorized to access this page.</Translate>
        </div>
      </div>
    );
  }

  return (
    <Navigate
      to={{
        pathname: '/sign-in',
        search: pageLocation.search,
      }}
      replace
      state={{ from: pageLocation }}
    />
  );
};

export const hasAnyAuthority = (authorities: string[], hasAnyAuthorities: string[]) => {
  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some(auth => authorities.includes(auth));
  }
  return false;
};

export const doesEntityExist = (entity: string, authorities: string[], authMatrix: IAuthMatrix[]) => {
  const [entityName, pathVar, action] = entity.split('/').map(part => part.replace(/-/g, ''));
  // console.log(`====> doesEntityExist: path: ${entity} -> Entity: ${entityName} -> PathVar: ${pathVar} -> action: ${action} -> auths: ${authorities} -> Matrix Count: ${authMatrix.length}`);
  return authMatrix.some(auth => {
    const entityMatches = auth.entityName.toLowerCase() === entityName.toLowerCase();
    const authorityMatches = authorities.includes(auth.authorityName);
    return (
      entityMatches &&
      authorityMatches &&
      ((pathVar === 'new' && auth.ins === true) ||
        (action === 'delete' && auth.del === true) ||
        (action === 'edit' && auth.upd === true) ||
        (pathVar !== 'new' && action !== 'delete' && action !== 'edit' && auth.sel === true))
    );
  });
};
export const hasAuthority = (authorities: string[], hasAnyAuthorities: string[], authMatrix: IAuthMatrix[], entity: string) => {
  console.log('ENTITY', entity);
  console.log('authMatrix', authMatrix);

  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some(auth => authorities.includes(auth)) || doesEntityExist(entity, authorities, authMatrix);
  }
  return false;
};
/**
 * Checks authentication before showing the children and redirects to the
 * login page if the user is not authenticated.
 * If hasAnyAuthorities is provided the authorization status is also
 * checked and an error message is shown if the user is not authorized.
 */
export default PrivateRoute;
