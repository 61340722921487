import { IHrCode } from 'app/shared/model/hr-code.model';

export interface IApprovalWorkflow {
  id?: number;
  roleName?: string;
  step?: number;
  workflow?: IHrCode;
  action?: IHrCode;
  status?: IHrCode;
}

export const defaultValue: Readonly<IApprovalWorkflow> = {};
