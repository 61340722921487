import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Popover, PopoverBody } from 'reactstrap';

export interface IMenuItem {
  children: React.ReactNode;
  id?: string;
  icon: IconProp;
  to: string;
  label: string;
  'data-cy'?: string;
  isOpen?: boolean;
  level?: number;
  onClick?: () => void; // Add onClick as an optional function prop
}

const MenuItem = (props: IMenuItem) => {
  const { id, to, icon, label, isOpen, level, children, onClick } = props;
  const spaces = props['level']? props['level']:0 ;
  const isSub = (spaces===0)? false : true;
  const fs= (level===0)? 'fs-4' : 'fs-0'; 

  const [popoverOpen, setPopoverOpen] = useState(false);

  // const togglePopover = () => {
  //   setPopoverOpen(!popoverOpen);
  // };

  // useEffect(() => {
  //   if (popoverOpen) {
  //     // Close the tooltip after 5 seconds
  //     const timeoutId = setTimeout(() => {
  //       setPopoverOpen(false);
  //     }, 3000);
  //     // Clear the timeout when component unmounts or when tooltipOpen changes to false
  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [popoverOpen]);

  
  // const handleClick = () => {
  //   if (onClick) {
  //     onClick(); // Call onClick if it's provided
  //   }
  // };

  return (
    // <Link id={id} to={to} data-cy={props['data-cy']} onClick={handleClick} className={`${level}===0? " border border-bottom border-solid" : "bg-white"`}>
    <Link id={id} to={to} data-cy={props['data-cy']} style={{ textDecoration: 'none' }}>
      <FontAwesomeIcon icon={icon} fixedWidth onMouseEnter={()=>setPopoverOpen(true)} onMouseLeave={()=>setPopoverOpen(false)}
          className={` ${fs} pe-2 pt-2 ${level===0? 'text-primary' : 'text-danger'} ${isOpen? (level>0? 'ps-2' : 'ps-0') : level>0? 'ps-1' : 'ps-0'} }`} 
      />
      {isOpen && label}
      {!isOpen && popoverOpen && (
        // <Popover placement='right' isOpen={popoverOpen} target={id} toggle={() => togglePopover} >
        <Popover placement='right' isOpen={popoverOpen} target={id} >
          <PopoverBody className='fw-bold text-primary'>{label}</PopoverBody>
        </Popover>
      )}
    </Link>
  );
};

export default MenuItem;
