import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IBusinessClient } from 'app/shared/model/business-client.model';
import { getEntities as getBusinessClients } from 'app/entities/business-client/business-client.reducer';
import { IHrCode } from 'app/shared/model/hr-code.model';
import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { IClientContact } from 'app/shared/model/client-contact.model';
import { getEntity, updateEntity, createEntity, reset } from './client-contact.reducer';

export const ClientContactUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const businessClients = useAppSelector(state => state.businessClient.entities);
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const clientContactEntity = useAppSelector(state => state.clientContact.entity);
  const loading = useAppSelector(state => state.clientContact.loading);
  const updating = useAppSelector(state => state.clientContact.updating);
  const updateSuccess = useAppSelector(state => state.clientContact.updateSuccess);

  const handleClose = () => {
    navigate('/client-contact');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getBusinessClients({}));
    dispatch(getHrCodes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...clientContactEntity,
      ...values,
      businessClient: businessClients.find(it => it.id.toString() === values.businessClient?.toString()),
      status: isNew? hrCodes.find(e=>e.codeType==='WKS' && e.codeName==='Pending') : hrCodes.find(it => it.id.toString() === values.status?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...clientContactEntity,
          businessClient: clientContactEntity?.businessClient?.id,
          status: clientContactEntity?.status?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5 id="hrmsApp.clientContact.home.createOrEditLabel" data-cy="ClientContactCreateUpdateHeading" className="text-center fw-bold text-primary">
            <Translate contentKey="hrmsApp.clientContact.home.createOrEditLabel">Create or edit a ClientContact</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center text-primary">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="client-contact-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('hrmsApp.clientContact.firstName')}
                id="client-contact-firstName"
                name="firstName"
                data-cy="firstName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.clientContact.lastName')}
                id="client-contact-lastName"
                name="lastName"
                data-cy="lastName"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.clientContact.designation')}
                id="client-contact-designation"
                name="designation"
                data-cy="designation"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.clientContact.email')}
                id="client-contact-email"
                name="email"
                data-cy="email"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.clientContact.contactNos')}
                id="client-contact-contactNos"
                name="contactNos"
                data-cy="contactNos"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.clientContact.remarks')}
                id="client-contact-remarks"
                name="remarks"
                data-cy="remarks"
                type="text"
              />
              <ValidatedField
                id="client-contact-businessClient"
                name="businessClient"
                data-cy="businessClient"
                label={translate('hrmsApp.clientContact.businessClient')}
                type="select"
                required
              >
                <option value="" key="0" />
                {businessClients
                  ? businessClients.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              {/* <ValidatedField
                id="client-contact-status"
                name="status"
                data-cy="status"
                label={translate('hrmsApp.clientContact.status')}
                type="select"
                required
              >
                <option value="" key="0" />
                {hrCodes
                  ? hrCodes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField> */}
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/client-contact" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ClientContactUpdate;
