import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useEffect, useState } from 'react';
import { Badge, Button, Label, Table, Tooltip } from 'reactstrap';

import { addDays, endOfYear, format, startOfWeek, startOfYear } from 'date-fns';
import React from 'react';
import { createEntity, deleteEntity, updateEntity } from './time-sheet.reducer';
import { createEntity as createEntityTimeSheetRecord } from 'app/entities/time-sheet-record/time-sheet-record.reducer';
import { ITimeSheetRecord } from 'app/shared/model/time-sheet-record.model';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear);

interface Task {
  taskTypeId: number;
  name: string;
  parent: string;
  project: string;
  assignmentId: number;
  internalTaskId: number;
  countryId: number;
  startDate: Date;
  endDate: Date;
  minHour: number;
  maxHour: number;
  editable: boolean;
}

interface DailyTaskList {
  existingEntries: [];
  newEntry: [];
}

interface DailyTask {
  assignmentId: number;
  internalTaskId: number;
  timeSheetId: number;
  dated: Date;
  hours: number;
  activity: string;
  holiday: boolean;
  workday: boolean;
  editable: boolean;
  edited: boolean;
}
type Matrix = DailyTask[][];
const initialTasks: DailyTask[][] = [];

interface DateInfo {
  dated: Date;
  workday: boolean;
  holiday: boolean;
  // edited: boolean;
}

interface CurCell {
  rowIndex: number;
  colIndex: number;
}

export const TimeSheetMatrix = ({ empId, weekStartDate }) => {
  const dispatch = useAppDispatch();

  const countryHolidays = useAppSelector(state => state.countryHoliday.entities);
  const countryWorkdays = useAppSelector(state => state.countryWorkday.entities);
  const businessClients = useAppSelector(state => state.businessClient.entities);
  const businessProjects = useAppSelector(state => state.businessProject.entities);
  const isProjectLoading = useAppSelector(state => state.businessProject.loading);
  const projectTasks = useAppSelector(state => state.projectTask.entities);
  const taskAssignments = useAppSelector(state => state.taskAssignment.entities);
  const timeSheetList = useAppSelector(state => state.timeSheet.entities);
  const timeSheetEntity = useAppSelector(state => state.timeSheet.entity);
  const internalTasks = useAppSelector(state => state.internalTask.entities);
  const empProfile = useAppSelector(state => state.empProfile.entity);
  const contracts = useAppSelector(state => state.contract.entities);
  const employees = useAppSelector(state => state.employee.entities);
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const account = useAppSelector(state => state.authentication.account);

  /* eslint no-console: off */
  // console.log('=== EmpId: ', empId, ' WeekStartDate: ', weekStartDate);

  const [weekDates, setWeekDates] = useState<DateInfo[]>([]);
  const [timeSheetTasks, setTimeSheetTasks] = useState<Task[]>([]);
  const [taskMatrix, setTaskMatrix] = useState<{ existingEntries: DailyTask[]; newEntry: DailyTask }[][]>([]);
  const [curCell, setCurCell] = useState<CurCell>({ rowIndex: null, colIndex: null });
  const [curEntity, setCurEntity] = useState(timeSheetEntity);
  const [curTask, setCurTask] = useState<DailyTask>(null);
  const [curTimeSheet, setCurTimeSheet] = useState<Task>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [haveAssignment, setHaveAssignment] = useState<boolean>(null);
  const [country, setCountry] = useState<number>(null);
  const [dayTotal, setDayTotal] = useState<number[]>(Array.from({ length: 7 }, () => null));

  const updateDayTotal = (index: number, value: number) => {
    setDayTotal(prevDayTotal => {
      const newDayTotal = [...prevDayTotal]; // Create a copy of the array
      newDayTotal[index] = value; // Update the specific index
      return newDayTotal; // Return the updated array
    });
  };

  const isHoliday = (dated: Date, countryId: number) => {
    return countryHolidays?.find(
      e => format(e.dated, 'yyyy-MM-dd') === format(dated, 'yyyy-MM-dd') && e.country.id.toString() === countryId?.toString(),
    )
      ? true
      : false;
  };

  const isWorkday = (dated: Date, countryId: number) => {
    const workdays = countryWorkdays.find(e => e.country.id.toString() === countryId?.toString());
    if (!workdays) {
      console.error('No workdays found for the given country');
      return false;
    }
    const dayOfWeek = dated.getDay(); // 0 is Sunday, 1 is Monday, and so on
    switch (dayOfWeek) {
      case 0:
        return workdays.sunOn;
      case 1:
        return workdays.monOn;
      case 2:
        return workdays.tueOn;
      case 3:
        return workdays.wedOn;
      case 4:
        return workdays.thuOn;
      case 5:
        return workdays.friOn;
      case 6:
        return workdays.satOn;
      default:
        return false;
    }
  };

  const isProjectOntrack = (dated: Date, startDate: Date, endDate: Date) => {
    const normalizeDate = (date: Date) => {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    };

    const normalizedDated = normalizeDate(dated);
    // const normalizedStartDate = normalizeDate(startDate);
    // const normalizedEndDate = normalizeDate(endDate);
    // const flag = normalizedStartDate <= normalizedDated && normalizedDated <= normalizedEndDate;
    const flag = startDate <= normalizedDated && normalizedDated <= endDate;
    console.log('=== Dated1: ', normalizedDated, ' start: ', startDate, ' End: ', endDate, ' FLAG: ', flag);
    // return  true; //(startDate <= dated && dated <= endDate);
    return startDate <= dated && dated <= endDate;
  };

  useEffect(() => {
    if (contracts.length > 0 && hrCodes.length > 0) {
      const empContract = contracts.filter(
        e => e.employee.id === empId && e.contractStatus.id === hrCodes.find(t => t.codeType === 'CTS' && t.codeName === 'Active')?.id,
      );
      const locationIds = empContract?.map(c => c.location?.id) || []; // Get all location IDs
      const countryId = hrCodes.find(e => locationIds.includes(e.id))?.id;
      if (countryId !== null && countryId !== undefined) {
        setCountry(countryId);
      }
    }
  }, [contracts, hrCodes]);

  const SetWeekInfo = () => {
    if (country !== null && country !== undefined) {
      const dates: DateInfo[] = [];
      let curDate = startOfWeek(weekStartDate, { weekStartsOn: 1 }); // Week starts on Monday
      for (let i = 0; i < 7; i++) {
        dates.push({
          dated: curDate,
          workday: true, // isWorkday(curDate),
          holiday: false, // isHoliday(curDate),
        });
        curDate = addDays(curDate, 1);
      }
      setWeekDates(dates);
    }
  };
  // weekDates.forEach(d => console.log(format(d, 'EEE MMM-dd')));

  useEffect(() => {
    setHaveAssignment(taskAssignments.length > 0);
  }, [taskAssignments]);

  useEffect(() => {
    if (weekStartDate && hrCodes.length > 0 && internalTasks.length > 0 && contracts.length > 0) {
      console.log('==== Loading 1 ', isProjectLoading, ' Length: ', businessProjects?.length);
      const contractCountryId = hrCodes.find(t => t.id === contracts.find(e => e.employee.id === empId)?.location.id)?.parent?.id;
      const workflowStatusList = hrCodes.filter(e => e.codeType === 'WKS');
      // const pendingStatusId = workflowStatusList.find(e => e.codeName === 'Pending')?.id;
      // const rejectedStatusId = workflowStatusList.find(e => e.codeName === 'Rejected')?.id;
      const activeStatusId = workflowStatusList.find(e => e.codeName === 'Active')?.id;
      // const deactiveStatusId = workflowStatusList.find(e => e.codeName === 'DeActive')?.id;
      // const deletedStatusId = workflowStatusList.find(e => e.codeName === 'Deleted')?.id;
      const mergedEntries: Task[] = [
        ...taskAssignments.map(assignment => {
          const projectTask = projectTasks.find(e => e.id === assignment.projectTask.id);
          const businessProject = businessProjects?.find(e => e.id === projectTask.businessProject.id);
          const mainClient = businessClients?.find(e => e.id === businessProject?.mainClient?.id);
          return {
            taskTypeId: hrCodes.find(e => e.codeType === 'TST' && e.codeName === 'Project Task')?.id,
            name: projectTask?.taskName,
            parent: projectTask?.parentTask?.taskName, // parentTask?.taskName,
            project: businessProject?.projectName,
            assignmentId: assignment.id,
            internalTaskId: null,
            countryId: mainClient?.country?.id,
            startDate: projectTask?.startDate,
            endDate: projectTask?.endDate,
            minHour: 0.25, // DEFAULT_MIN_HOUR,
            maxHour: 8, // DEFAULT_MAX_HOUR,
            editable:
              assignment.status.id === activeStatusId &&
              projectTask?.status.id === activeStatusId &&
              businessProject?.engagementStatus.id === activeStatusId,
            // editable: true,
          };
        }),
        ...internalTasks.map(task => ({
          taskTypeId: task.id,
          name: hrCodes.find(e => e.id === task.id)?.codeName,
          parent: hrCodes.find(e => e.id === task.id)?.parentId,
          project: 'Admin',
          assignmentId: null,
          internalTaskId: task.id,
          countryId: contractCountryId,
          startDate: format(startOfYear(new Date()), 'yyyy-MM-dd'),
          endDate: format(endOfYear(new Date()), 'yyyy-MM-dd'),
          minHour: task.minHour,
          maxHour: task.maxHour,
          editable: true,
        })),
      ];
      SetWeekInfo();
      setTimeSheetTasks(mergedEntries);
      console.log('====== MergeEntries: ', mergedEntries);
    }
  }, [weekStartDate, hrCodes, internalTasks, contracts, businessClients]);

  useEffect(() => {
    if (timeSheetTasks.length > 0) {
      refreshMatrix();
    }
  }, [timeSheetTasks]);

  const refreshMatrix = () => {
    if (weekDates.length > 0 && timeSheetTasks.length > 0 && timeSheetList) {
      console.log('===== TimeSheetTasks: ', timeSheetTasks);
      console.log('===== WeekDates: ', weekDates);
      const workflowStatusList = hrCodes.filter(e => e.codeType === 'WKS');
      const pendingStatusId = workflowStatusList.find(e => e.codeName === 'Pending')?.id;
      const rejectedStatusId = workflowStatusList.find(e => e.codeName === 'Rejected')?.id;
      // const activeStatusId = workflowStatusList.find(e => e.codeName === 'Active')?.id;
      // Loopover all Task List
      const matrix: { existingEntries: DailyTask[]; newEntry: DailyTask }[][] = timeSheetTasks.map(task => {
        return weekDates.map(dateInfo => {
          // Get All Existing Entries for this task
          const timeSheetEntryList = timeSheetList.filter(
            e =>
              (e.assignment?.id === task.assignmentId || e.internalTask?.id === task.internalTaskId) &&
              format(e.dated, 'yyyy-MM-dd') === format(dateInfo.dated, 'yyyy-MM-dd'),
          );

          // return {
          //   existing:
          // }

          const isHday = task.countryId ? isHoliday(dateInfo.dated, task.countryId) : null;
          const isWday = task.countryId ? isWorkday(dateInfo.dated, task.countryId) : null;

          const newEntry: DailyTask = {
            timeSheetId: null,
            dated: new Date(dateInfo.dated),
            hours: null,
            activity: null,
            assignmentId: task.assignmentId,
            internalTaskId: task.internalTaskId,
            holiday: isHday,
            workday: isWday,
            editable: isHday === false && isWday === true && task.editable, // && isProjectOntrack(dateInfo.dated, task.startDate, task.endDate), //isWorkday(dateInfo.dated), //task.editable,
            edited: false,
          };

          const existingEntries: DailyTask[] = timeSheetEntryList.map(timeSheetEntry => {
            if (timeSheetEntry) {
              console.log('===== TimeSheetEntry: ', timeSheetEntry.id, '-', timeSheetEntry.dated, ' - ', timeSheetEntry);
            }
            return {
              timeSheetId: timeSheetEntry?.id,
              dated: new Date(dateInfo.dated),
              hours: timeSheetEntry?.hours,
              activity: timeSheetEntry?.activities,
              assignmentId: task.assignmentId,
              internalTaskId: task.internalTaskId,
              holiday: isHday,
              workday: isWday,
              editable:
                isHday === false &&
                isWday === true &&
                task.editable &&
                [null, undefined, pendingStatusId, rejectedStatusId].includes(timeSheetEntry?.status?.id), // && isProjectOntrack(dateInfo.dated, task.startDate, task.endDate), //isWorkday(dateInfo.dated), //task.editable,
              edited: false,
            };
          });

          return {
            existingEntries,
            newEntry,
          };
        });
      });
      console.log('SET MATRIX =============> : ', matrix);
      console.log('timeSheetList MATRIX =============> : ', timeSheetList);
      setTaskMatrix(matrix);
    }
  };

  useEffect(() => {
    if (timeSheetList) {
      refreshMatrix();
    }
  }, [timeSheetList]);

  useEffect(() => {
    if (taskMatrix.length > 0) {
      console.log('===== Task Matrix: ', taskMatrix);

      // Initialize sums array with zeroes for 7 days (columns)
      const sums = Array.from({ length: 7 }, () => 0);

      // Iterate over the taskMatrix to calculate daily totals
      taskMatrix.forEach(row => {
        row.forEach((taskData, colIndex) => {
          if (taskData?.existingEntries) {
            const columnTotal = taskData.existingEntries.reduce((sum, task) => sum + (task.hours || 0), 0);
            sums[colIndex] += columnTotal;
          }
        });
      });

      // Update state with calculated totals
      setDayTotal(sums);
    }
  }, [taskMatrix]);

  useEffect(() => {
    setModalOpen(false);
  }, [weekStartDate]);

  const sumWithoutCurCell = (excludeRow: number, col: number) => {
    return taskMatrix.reduce((sum, row, rowIndex) => {
      // if (rowIndex !== excludeRow) {
      const columnData = row[col];
      if (columnData) {
        // Sum up hours from all existing entries in the column
        const columnSum = columnData.existingEntries.reduce((entrySum, entry) => entrySum + (entry.hours || 0), 0);
        return sum + columnSum;
      }
      // }
      return sum;
    }, 0);
  };

  const handleTaskCellClick = (task: DailyTask, rowIndex: number, colIndex: number) => {
    console.log(task);
    console.log(taskAssignments);
    const newCell = { rowIndex, colIndex };
    setCurCell(newCell);
    console.log(newCell);
    // console.log('---- MatrixCell: ', rowIndex, ',', colIndex, ':', task);
    if (task.editable || true) {
      setCurTask(task);
      const currentTimeSheet = timeSheetTasks.find(d => {
        if (task.internalTaskId) {
          return d.internalTaskId === task.internalTaskId;
        }
        return d.assignmentId === task.assignmentId;
      });
      setCurTimeSheet(currentTimeSheet);
      console.log(currentTimeSheet);

      if (task.timeSheetId === null || task.timeSheetId === undefined) {
        const entity = {
          ...timeSheetEntity,
          id: null, // timeSheetList.find(e => format(e.dated,'yyyy-MM-dd')===format(task.dated,'yyyy-MM-dd')),
          dated: format(task.dated, 'yyyy-MM-dd'),
          hours: '',
          activities: '',
          employee: employees.find(it => it.id === empId),
          taskType: hrCodes.find(it => it.id === currentTimeSheet.taskTypeId),
          assignment: taskAssignments.find(it => it.id === currentTimeSheet?.assignmentId),
          internalTask: internalTasks.find(it => it.id === currentTimeSheet?.internalTaskId),
        };
        setCurEntity(entity);
      } else {
        const entity = timeSheetList.find(e => e.id === task.timeSheetId);
        setCurEntity(entity);
      }
      setModalOpen(true);
    }
  };

  const handleSave = () => {
    const dTotal = sumWithoutCurCell(curCell.rowIndex, curCell.colIndex);
    if (dTotal + (curEntity.hours ? curEntity.hours : 0) > 8) {
      alert('------ Value Exceed 8 hours per day');
    } else {
      const date = weekDates[curCell.colIndex];
      // setTaskMatrixCell(curCell.rowIndex, curCell.colIndex, curTask);
      updateDayTotal(curCell.colIndex, curTask.hours ? curTask.hours : 0);
      if (curEntity?.id === null || curEntity?.id === undefined) {
        dispatch(createEntity(curEntity));
      } else {
        if (
          timeSheetList.find(e => e.id === curTask.timeSheetId)?.hours !== curEntity.hours ||
          timeSheetList.find(e => e.id === curTask.timeSheetId)?.activities !== curEntity.activity
        ) {
          dispatch(updateEntity(curEntity));
        }
      }
    }
    setModalOpen(false);
  };

  const handleRemove = () => {
    // const dTotal = sumWithoutCurCell(curCell.rowIndex, curCell.colIndex); // dayTotal[curCell.colIndex];
    // const date = weekDates[curCell.colIndex];
    console.log('--- inside Delete row: ', curTask);
    // setTaskMatrixCell(curCell.rowIndex, curCell.colIndex, curTask);
    // updateDayTotal(curCell.colIndex, curTask.hours ? curTask.hours : 0);
    dispatch(deleteEntity(curTask.timeSheetId));
    setModalOpen(false);
  };

  const DateOnly = (date: Date) => {
    return date.toISOString().split('T')[0];
  };

  // Function to get week and year from a given date
  const getWeekAndYear = (date: dayjs.Dayjs) => {
    const weekNumber = date.week(); // Get the week number
    const year = date.year(); // Get the year
    return { weekNumber, year };
  };

  const handleTimeSheetRecordSubmit = () => {
    const currentDate = dayjs(); // Current date
    const weekStartDateD = dayjs(weekStartDate);
    // Get the week number and year from the current date or week start date
    const { weekNumber, year } = getWeekAndYear(weekStartDateD);
    const status = hrCodes.find(e => e.codeType === 'WKS' && e.codeName === 'Pending');
    // Create the TimeSheetRecord object
    const timeSheetRecord: ITimeSheetRecord = {
      employee: empProfile, // Assuming empProfile is already defined elsewhere
      timeSheets: [], // Empty array for now
      dated: currentDate, // Current date for the record
      weekNumber, // Calculated week number
      year, // Calculated year
      status,
    };
    dispatch(createEntityTimeSheetRecord(timeSheetRecord));
  };

  return (
    <div>
      <div className="table-hover">
        {internalTasks.length > 0 ? (
          <>
            <Table hover>
              <thead>
                <tr>
                  <th />
                  {weekDates.map((dateInfo, index) => (
                    <th key={index}>{format(dateInfo.dated, 'dd (EEE)')}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {taskMatrix.map((row, rowIndex) => {
                  const task = timeSheetTasks[rowIndex];
                  const isAdminProject = task?.project === 'Admin';
                  const parentTaskName = task?.parent ? `${task.parent} -- ` : '';

                  return (
                    <tr key={rowIndex}>
                      {/* Render Task Information */}
                      <td className={isAdminProject ? 'bg-light' : ''}>
                        <span className="text-primary fw-bold me-2">{task?.project}</span>({parentTaskName}
                        {task?.name})
                      </td>

                      {/* Render Columns for Each Row */}
                      {row.map((column, colIndex) => {
                        const isEditable = Array.isArray(column) && column.some(taskData => taskData.editable);
                        const isActiveCell = colIndex === curCell.colIndex && rowIndex === curCell.rowIndex;

                        return (
                          <td
                            key={`${colIndex}_`}
                            onClick={() => handleTaskCellClick(column.newEntry, rowIndex, colIndex)}
                            className={`border border-gray text-center ${
                              colIndex >= 0 ? '' : 'fw-bold text-primary'
                            } ${isEditable ? '' : 'bg-light'} ${isActiveCell ? 'bg-secondary' : ''}`}
                          >
                            {/* Render Tasks Inside Each Cell */}
                            {column.existingEntries.map((taskData, taskIndex) => (
                              <span key={taskIndex} className="p-1">
                                <Badge
                                  onClick={e => {
                                    e.stopPropagation(); // Prevent parent click event
                                    handleTaskCellClick(taskData, rowIndex, colIndex);
                                  }}
                                  color="primary"
                                >
                                  {taskData.hours}
                                </Badge>
                              </span>
                            ))}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr className="text-center bg-primary fw-bold">
                  <td>
                    <Button
                      color="primary"
                      id="submit-entity"
                      onClick={handleTimeSheetRecordSubmit}
                      disabled={dayTotal.reduce((acc, current) => acc + (current || 0), 0) < 40}
                      className="ms-2 btn-sm"
                    >
                      Submit Weekly Timesheet
                    </Button>
                  </td>
                  {dayTotal.map((tot, index) => (
                    <td key={index} className="bg-light">
                      {tot}
                    </td>
                  ))}
                </tr>
                {modalOpen && (
                  <tr>
                    {/* <td/> */}
                    <td colSpan={8} className="bg-secondary text-white fw-bold">
                      <div className="d-flex justify-content-between align-items-center">
                        <Label htmlFor="txtHours" className="m-0">
                          Hours
                        </Label>
                        <input
                          id="idHours"
                          name="txtHours"
                          type="number"
                          className="mx-2 border border-gray border-rounded uniform-height-input"
                          min={curTimeSheet.minHour}
                          max={curTimeSheet.maxHour}
                          step="0.25"
                          required
                          value={curEntity?.hours}
                          onChange={e => {
                            const value = Number(e.target.value);
                            if (value >= curTimeSheet.minHour && value <= curTimeSheet.maxHour) {
                              setCurEntity(prevEntity => ({ ...prevEntity, hours: value }));
                            }
                          }}
                          style={{ width: '8ch' }} // Set the width to be just 5 characters lon
                        />
                        <Label htmlFor="txtActivities" className="m-0">
                          Activities
                        </Label>
                        <input
                          id="idActivities"
                          name="txtActivities"
                          type="text"
                          className="mx-2 border border-gray border-rounded uniform-height-input w-75"
                          maxLength={100}
                          required
                          value={curEntity?.activities}
                          onChange={e => {
                            const value = e.target.value;
                            if (value.length <= 100) {
                              setCurEntity(prevEntity => ({ ...prevEntity, activities: value }));
                            }
                          }}
                        />
                        <div className="ms-auto d-flex align-items-center">
                          <Button
                            color="info"
                            id="cancel-entity"
                            onClick={() => setModalOpen(false)}
                            className="ms-2 btn-sm  uniform-height-button"
                          >
                            <FontAwesomeIcon icon="arrow-left" />
                          </Button>
                          <Button color="primary" id="save-entity" onClick={handleSave} className="ms-2 btn-sm">
                            {' '}
                            <FontAwesomeIcon icon="save" />
                          </Button>
                          {curEntity.id !== null && curEntity.id !== undefined && (
                            <Button color="danger" id="delete-entity" onClick={handleRemove} className="ms-2 btn-sm">
                              <FontAwesomeIcon icon="trash" />{' '}
                            </Button>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tfoot>
            </Table>
          </>
        ) : null}
      </div>
      {/* {isTimesheetActivityModalOpen && (
          <TimesheetActivityModal isOpen={isTimesheetActivityModalOpen} toggle={toggleTimesheetActivityModal} onSave={handleSave} />
        )} */}
    </div>
  );
};

export default TimeSheetMatrix;
