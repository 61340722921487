import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-jhipster';
import { useParams } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IExpenseItem } from 'app/shared/model/expense-item.model';
import ExpenseItemUpdateModal from '../expense-item/expense-item-update-modal';
import dayjs from 'dayjs';
import ExpenseClaimItem from './expense-claim-item';
import { uploadFile } from '../emp-profile/emp-profile.reducer';

export const ExpenseClaimItemList = ({ onItemAdded, onItemsRemoved, onItemsUpdated, canUpdate }) => {
  // const expenseItemList = useAppSelector(state => state.expenseItem.entities);
  const loadingItem = useAppSelector(state => state.expenseItem.loading);
  const expenseClaimEntity = useAppSelector(state => state.expenseClaim.entity);
  const hrCodes = useAppSelector(state => state.hrCode.entities);

  const { id } = useParams<'id'>();

  const [expenseItems, setExpenseItems] = useState<IExpenseItem[]>([]);
  const [expenseItemModal, setExpenseItemModal] = useState(false);

  const dispatch = useAppDispatch();

  // const [refresh, setRefresh] = useState(0);
  // useEffect(() => {}, [refresh]);
  useEffect(() => {
    let expenseItemsList = expenseClaimEntity?.expenseItems;
    expenseItemsList = expenseItemsList?.map(item => {
      return {
        ...item,
        expDate: dayjs(item.expDate),
      };
    });
    setExpenseItems(expenseItemsList ?? []);
  }, [expenseClaimEntity, id]);

  const addExpenseItem = () => {
    setExpenseItemModal(true);
  };

  const delFile = (expenseItemId: number, fileName: string) => {
    // const formData = new FormData();
    // formData.append('fileName', fileName);
    // const response = dispatch(deleteFile(formData));
    // response.then(res => {
    //   if (res.payload === 'SUCCESS') {
    //     const expenseEntity = { ...expenseItemList.find(e => e.id === expenseItemId), attachmentPath: null };
    //     dispatch(updateExpenseItem(expenseEntity));
    //     // dispatch(getExpenseItems({ query: `expenseClaimId.equals=${id}` }));
    //   } else {
    //     alert('Failed to delete file');
    //   }
    // });
    // setShowAttachmentModal(false);
  };

  const handleAttachmentFileChange = (event, expenseItemId) => {
    if (event.target.files?.[0]) {
      try {
        const response = uploadPic(event.target.files?.[0], 'claims', expenseItemId);

        response.then(res => {
          const timestamp = new Date().getTime(); // Generate a unique timestamp
          const updatedPath = `${res.payload}?t=${timestamp}`;
          const expenseEntityList = expenseItems.map(e => (e.id === expenseItemId ? { ...e, attachmentPath: updatedPath } : e));
          setExpenseItems(expenseEntityList);
          onItemsUpdated(expenseEntityList);
        });
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  // const handleAttachmentFileChange = (event, expenseItemId) => {
  //   if (event.target.files?.[0]) {
  //     try {
  //       const resultAction = uploadPic(event.target.files?.[0], 'claims', expenseItemId);

  //       if (uploadFile.fulfilled.match(resultAction)) {
  //         const expenseEntity = {
  //           ...expenseItems.find(e => e.id === expenseItemId),
  //           attachmentPath: resultAction.payload,
  //         };
  //         setExpenseItems(prevItems => [...prevItems, expenseEntity]);
  //       } else {
  //         console.error('File upload failed:', resultAction);
  //       }
  //     } catch (error) {
  //       console.error('Error uploading file:', error);
  //     }
  //   }
  // };

  const handleItemAdded = data => {
    // Ensure expDate is converted to Dayjs or a JavaScript Date
    const formattedData = {
      ...data,
      expDate: data.expDate ? dayjs(data.expDate).toDate() : null, // Convert to Date
    };

    // Push to the list
    setExpenseItems(prevItems => [...prevItems, formattedData]);
    onItemAdded(formattedData);
  };
  const uploadPic = (selectedFile: File, folderName: string, expenseItemId: number) => {
    const formData = new FormData();
    formData.append('fileType', selectedFile.type);
    formData.append('fileName', expenseItemId.toString());
    formData.append('folderName', folderName);
    formData.append('file', selectedFile);
    return dispatch(uploadFile(formData));
  };

  const removeExpenseItem = expenseItemID => {
    setExpenseItems(prevItems => {
      const updatedItems = prevItems.filter(item => item.id !== expenseItemID);
      onItemsRemoved(updatedItems); // Ensure updated list is passed
      return updatedItems;
    });
  };

  const updateExpenseItem = updatedExpenseItem => {
    setExpenseItems(prevItems => {
      const updatedItems = prevItems.map(item => (item.id === updatedExpenseItem.id ? { ...item, ...updatedExpenseItem } : item));

      onItemsUpdated(updatedItems); // Ensure updated list is passed
      return updatedItems;
    });
  };

  return (
    <>
      {canUpdate && (
        <h5 id="expense-item-heading" data-cy="ExpenseItemHeading" className="mt-2 fw-bold text-danger">
          {/* <Translate contentKey="hrmsApp.expenseItem.home.title">Expense Items</Translate> */}
          <div className="d-flex justify-content-end">
            <Button color="warning" onClick={addExpenseItem}>
              <FontAwesomeIcon icon="plus" /> &nbsp; <Translate contentKey="hrmsApp.expenseItem.home.createLabel">Add Item</Translate>
            </Button>
          </div>
        </h5>
      )}

      <div className="table-responsive  align-middle">
        {!loadingItem && expenseItems && expenseItems.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="hrmsApp.expenseItem.expDate">Exp Date</Translate>{' '}
                </th>
                <th>
                  <Translate contentKey="hrmsApp.expenseItem.expenseType">Expense Type</Translate>
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.expenseItem.description">Description</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.expenseItem.receiptNo">Receipt No</Translate>{' '}
                </th>
                <th>
                  <Translate contentKey="hrmsApp.expenseItem.currecny">Currecny</Translate>
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.expenseItem.exchangeRate">Exchange Rate</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.expenseItem.amount">Amount</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.expenseItem.attachmentPath">Attachment Path</Translate>{' '}
                </th>
                <th />
              </tr>
            </thead>
            <tbody className="align-middle">
              {expenseItems
                .sort((a, b) => dayjs(a.expDate).toDate().getTime() - dayjs(b.expDate).toDate().getTime())
                .map((expenseItem, i) => (
                  <ExpenseClaimItem
                    key={`entity-${expenseItem?.id}`}
                    expenseItem={expenseItem}
                    hrCodes={hrCodes}
                    delFile={delFile}
                    handleAttachmentFileChange={handleAttachmentFileChange}
                    removeExpenseItem={removeExpenseItem}
                    itemIndex={i}
                    updateExpenseItem={updateExpenseItem}
                    canUpdate={canUpdate}
                  />
                ))}
            </tbody>
          </Table>
        ) : null}
      </div>

      {expenseItemModal && (
        <ExpenseItemUpdateModal
          isOpen={expenseItemModal}
          toggle={() => setExpenseItemModal(false)}
          expenseClaimId={expenseClaimEntity?.id}
          expenseItemId="0"
          onItemAdded={handleItemAdded}
        />
      )}
    </>
  );
};

export default ExpenseClaimItemList;
