import hrCode from 'app/entities/hr-code/hr-code.reducer';
import allowanceItem from 'app/entities/allowance-item/allowance-item.reducer';
import allowanceItemLog from 'app/entities/allowance-item-log/allowance-item-log.reducer';
import allowancePlan from 'app/entities/allowance-plan/allowance-plan.reducer';
import employee from 'app/entities/employee/employee.reducer';
import empProfile from 'app/entities/emp-profile/emp-profile.reducer';
import empDependent from 'app/entities/emp-dependent/emp-dependent.reducer';
import authorityDelegation from 'app/entities/authority-delegation/authority-delegation.reducer';
import approvalLimit from 'app/entities/approval-limit/approval-limit.reducer';
import approvalWorkflow from 'app/entities/approval-workflow/approval-workflow.reducer';
import approvalLimitLog from 'app/entities/approval-limit-log/approval-limit-log.reducer';
import pendingApproval from 'app/entities/pending-approval/pending-approval.reducer';
import approvalLog from 'app/entities/approval-log/approval-log.reducer';
import leavesPlan from 'app/entities/leaves-plan/leaves-plan.reducer';
import medicalPlan from 'app/entities/medical-plan/medical-plan.reducer';
import contractTemplate from 'app/entities/contract-template/contract-template.reducer';
import contract from 'app/entities/contract/contract.reducer';
import fcmToken from 'app/entities/fcm-token/fcm-token.reducer';
import fcmTemplate from 'app/entities/fcm-template/fcm-template.reducer';
import fcmNotification from 'app/entities/fcm-notification/fcm-notification.reducer';
import empIce from 'app/entities/emp-ice/emp-ice.reducer';
import empQualification from 'app/entities/emp-qualification/emp-qualification.reducer';
import empCertification from 'app/entities/emp-certification/emp-certification.reducer';
import empExperience from 'app/entities/emp-experience/emp-experience.reducer';
import empReference from 'app/entities/emp-reference/emp-reference.reducer';
import leavesRecord from 'app/entities/leaves-record/leaves-record.reducer';
import medicalRecord from 'app/entities/medical-record/medical-record.reducer';
import countryWorkday from 'app/entities/country-workday/country-workday.reducer';
import countryHoliday from 'app/entities/country-holiday/country-holiday.reducer';
import businessClient from 'app/entities/business-client/business-client.reducer';
import clientContact from 'app/entities/client-contact/client-contact.reducer';
import businessProject from 'app/entities/business-project/business-project.reducer';
import projectTask from 'app/entities/project-task/project-task.reducer';
import taskAssignment from 'app/entities/task-assignment/task-assignment.reducer';
import timeSheet from 'app/entities/time-sheet/time-sheet.reducer';
import timeSheetRecord from 'app/entities/time-sheet-record/time-sheet-record.reducer';
import employeeTimeSheetRecord from 'app/entities/time-sheet-record/employee-time-sheet-record.reducer';
import internalTask from 'app/entities/internal-task/internal-task.reducer';
import expenseClaim from 'app/entities/expense-claim/expense-claim.reducer';
import expenseItem from 'app/entities/expense-item/expense-item.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  hrCode,
  allowanceItem,
  allowanceItemLog,
  allowancePlan,
  employee,
  empProfile,
  empDependent,
  authorityDelegation,
  approvalLimit,
  approvalWorkflow,
  approvalLimitLog,
  pendingApproval,
  approvalLog,
  leavesPlan,
  medicalPlan,
  contractTemplate,
  contract,
  fcmToken,
  fcmTemplate,
  fcmNotification,
  empIce,
  empQualification,
  empCertification,
  empExperience,
  empReference,
  leavesRecord,
  medicalRecord,
  countryWorkday,
  countryHoliday,
  businessClient,
  clientContact,
  businessProject,
  projectTask,
  taskAssignment,
  timeSheet,
  timeSheetRecord,
  employeeTimeSheetRecord,
  internalTask,
  expenseClaim,
  expenseItem,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
