import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ApprovalLimitLog from './approval-limit-log';
import ApprovalLimitLogDetail from './approval-limit-log-detail';
import ApprovalLimitLogUpdate from './approval-limit-log-update';
import ApprovalLimitLogDeleteDialog from './approval-limit-log-delete-dialog';

const ApprovalLimitLogRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ApprovalLimitLog />} />
    <Route path="new" element={<ApprovalLimitLogUpdate />} />
    <Route path=":id">
      <Route index element={<ApprovalLimitLogDetail />} />
      <Route path="edit" element={<ApprovalLimitLogUpdate />} />
      <Route path="delete" element={<ApprovalLimitLogDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ApprovalLimitLogRoutes;
