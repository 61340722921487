import { IHrCode } from 'app/shared/model/hr-code.model';

export interface ICountryWorkday {
  id?: number;
  monOn?: boolean;
  tueOn?: boolean;
  wedOn?: boolean;
  thuOn?: boolean;
  friOn?: boolean;
  satOn?: boolean;
  sunOn?: boolean;
  timeZone?: number;
  remarks?: string | null;
  country?: IHrCode;
  status?: IHrCode;
}

export const defaultValue: Readonly<ICountryWorkday> = {
  monOn: false,
  tueOn: false,
  wedOn: false,
  thuOn: false,
  friOn: false,
  satOn: false,
  sunOn: false,
};
