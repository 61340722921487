import { IHrCode } from 'app/shared/model/hr-code.model';

export interface IBusinessClient {
  id?: number;
  clientName?: string;
  clientCode?: string;
  curAddress?: string | null;
  regNo?: string | null;
  remarks?: string | null;
  country?: IHrCode;
  status?: IHrCode;
}

export const defaultValue: Readonly<IBusinessClient> = {};
