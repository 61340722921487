import { CodeStatus } from 'app/shared/model/enumerations/code-status.model';

export interface IHrCode {
  id?: number;
  codeType?: string;
  codeName?: string;
  codeDescription?: string | null;
  codeStatus?: keyof typeof CodeStatus;
  codeSeq?: number | null;
  parent?: IHrCode | null;
}

export const defaultValue: Readonly<IHrCode> = {};
