import { IEmployee } from 'app/shared/model/employee.model';

export interface IEmpReference {
  id?: number;
  fullName?: string;
  organization?: string;
  designation?: string;
  linkage?: string | null;
  email?: string | null;
  contactNo?: number | null;
  locatopn?: string | null;
  cnic?: number | null;
  employee?: IEmployee;
}

export const defaultValue: Readonly<IEmpReference> = {};
