import React, { useState } from 'react';
import { Button, Card, CardImg, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextFormat } from 'react-jhipster';
import dayjs from 'dayjs';
import ExpenseItemUpdateDialog from '../expense-item/expense-item-update-dialog';

const APP_LOCAL_DATE_FORMAT = 'YYYY-MM-DD';

const ExpenseClaimItem = ({
  expenseItem,
  hrCodes,
  delFile,
  handleAttachmentFileChange,
  removeExpenseItem,
  updateExpenseItem,
  itemIndex,
  canUpdate,
}) => {
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [selectedExpenseItem, setSelectedExpenseItem] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [expenseItemEntity, setExpenseItemEntity] = useState(expenseItem);

  const handleEditClick = item => {
    setSelectedExpenseItem(item);
    setIsEditDialogOpen(true);
  };

  const updateEntityModel = e => {
    setExpenseItemEntity(e);
    updateExpenseItem(e);
  };

  return (
    <>
      <tr data-cy="entityTable">
        <td>
          {expenseItemEntity.expDate ? (
            <TextFormat type="date" value={dayjs(expenseItemEntity.expDate).toDate()} format={APP_LOCAL_DATE_FORMAT} />
          ) : null}
        </td>
        <td>{expenseItemEntity.expenseType ? hrCodes.find(e => e.id === expenseItemEntity.expenseType.id)?.codeName : ''}</td>
        <td>{expenseItemEntity.description}</td>
        <td>{expenseItemEntity.receiptNo}</td>
        <td>{expenseItemEntity.currecny ? hrCodes.find(e => e.id === expenseItemEntity.currecny.id)?.codeName : ''}</td>
        <td>{expenseItemEntity.exchangeRate}</td>
        <td>{expenseItemEntity.amount}</td>
        <td>
          {expenseItemEntity.attachmentPath ? (
            <>
              <Card className="border-0 d-flex justify-content-center align-middle text-center">
                <CardImg
                  src={expenseItemEntity.attachmentPath}
                  alt="Attachment"
                  style={{ width: '200px', cursor: 'pointer' }}
                  onClick={() => setShowAttachmentModal(true)}
                />
              </Card>

              <Modal isOpen={showAttachmentModal} toggle={() => setShowAttachmentModal(false)} centered>
                <ModalHeader toggle={() => setShowAttachmentModal(false)}>{expenseItemEntity.amount}</ModalHeader>
                <ModalBody className="text-center">
                  <img src={expenseItemEntity.attachmentPath} alt="Full Attachment" className="img-fluid" />
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={() => delFile(expenseItemEntity.id, expenseItemEntity.attachmentPath)}>
                    Delete
                  </Button>
                  <Button color="secondary" onClick={() => setShowAttachmentModal(false)}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </>
          ) : null}

          {canUpdate && (
            <p>
              <label htmlFor={`input-profile-pic-${expenseItemEntity.id}`} className="mt-1 p-1 fw-bold" style={{ cursor: 'pointer' }}>
                Click to change
              </label>
              <input
                id={`input-profile-pic-${expenseItemEntity.id}`}
                type="file"
                accept="image/jpeg,image/png"
                onChange={event => handleAttachmentFileChange(event, expenseItemEntity.id)}
                style={{ display: 'none' }}
              />
            </p>
          )}
        </td>
        <td className="text-end">
          {canUpdate && (
            <div className="btn-group flex-btn-group-container">
              <Button onClick={() => handleEditClick(expenseItemEntity)} color="primary" size="sm" data-cy="entityEditButton">
                <FontAwesomeIcon icon="pencil-alt" />
              </Button>
              <Button onClick={() => removeExpenseItem(expenseItemEntity.id)} color="danger" size="sm" data-cy="entityDeleteButton">
                <FontAwesomeIcon icon="trash" />
              </Button>
            </div>
          )}
        </td>
      </tr>

      {/* Expense Item Edit Dialog */}
      {isEditDialogOpen && canUpdate && (
        <ExpenseItemUpdateDialog
          isOpen={isEditDialogOpen}
          toggle={() => setIsEditDialogOpen(false)}
          expenseItemEntity={selectedExpenseItem}
          saveItemModelEntity={updateEntityModel}
        />
      )}
    </>
  );
};

export default ExpenseClaimItem;
