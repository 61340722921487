import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/hr-code">
        <Translate contentKey="global.menu.entities.hrCode" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/allowance-item">
        <Translate contentKey="global.menu.entities.allowanceItem" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/allowance-item-log">
        <Translate contentKey="global.menu.entities.allowanceItemLog" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/allowance-plan">
        <Translate contentKey="global.menu.entities.allowancePlan" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/employee">
        <Translate contentKey="global.menu.entities.employee" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/emp-profile">
        <Translate contentKey="global.menu.entities.empProfile" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/emp-dependent">
        <Translate contentKey="global.menu.entities.empDependent" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/authority-delegation">
        <Translate contentKey="global.menu.entities.authorityDelegation" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/approval-limit">
        <Translate contentKey="global.menu.entities.approvalLimit" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/approval-workflow">
        <Translate contentKey="global.menu.entities.approvalWorkflow" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/approval-limit-log">
        <Translate contentKey="global.menu.entities.approvalLimitLog" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/pending-approval">
        <Translate contentKey="global.menu.entities.pendingApproval" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/approval-log">
        <Translate contentKey="global.menu.entities.approvalLog" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/leaves-plan">
        <Translate contentKey="global.menu.entities.leavesPlan" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/medical-plan">
        <Translate contentKey="global.menu.entities.medicalPlan" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/contract-template">
        <Translate contentKey="global.menu.entities.contractTemplate" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/contract">
        <Translate contentKey="global.menu.entities.contract" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/fcm-token">
        <Translate contentKey="global.menu.entities.fcmToken" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/fcm-template">
        <Translate contentKey="global.menu.entities.fcmTemplate" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/fcm-notification">
        <Translate contentKey="global.menu.entities.fcmNotification" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/emp-ice">
        <Translate contentKey="global.menu.entities.empIce" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/emp-qualification">
        <Translate contentKey="global.menu.entities.empQualification" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/emp-certification">
        <Translate contentKey="global.menu.entities.empCertification" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/emp-experience">
        <Translate contentKey="global.menu.entities.empExperience" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/emp-reference">
        <Translate contentKey="global.menu.entities.empReference" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/leaves-record">
        <Translate contentKey="global.menu.entities.leavesRecord" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/medical-record">
        <Translate contentKey="global.menu.entities.medicalRecord" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/country-workday">
        <Translate contentKey="global.menu.entities.countryWorkday" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/country-holiday">
        <Translate contentKey="global.menu.entities.countryHoliday" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/business-client">
        <Translate contentKey="global.menu.entities.businessClient" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/client-contact">
        <Translate contentKey="global.menu.entities.clientContact" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/business-project">
        <Translate contentKey="global.menu.entities.businessProject" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/project-task">
        <Translate contentKey="global.menu.entities.projectTask" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/task-assignment">
        <Translate contentKey="global.menu.entities.taskAssignment" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/time-sheet">
        <Translate contentKey="global.menu.entities.timeSheet" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/internal-task">
        <Translate contentKey="global.menu.entities.internalTask" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/expense-claim">
        <Translate contentKey="global.menu.entities.expenseClaim" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/expense-item">
        <Translate contentKey="global.menu.entities.expenseItem" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
