import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { createEntity, getEntity as getAllowancePlan, reset as resetEntity, updateEntity } from './allowance-plan.reducer';
import { getEntities as getAllowancePlanItemEntities } from '../allowance-item/allowance-item.reducer';
import Multiselect from 'multiselect-react-dropdown';
import { toNumber } from 'lodash';
import { useForm } from 'react-hook-form';

export const AllowancePlanUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const allowancePlanEntity = useAppSelector(state => state.allowancePlan.entity);
  const loading = useAppSelector(state => state.allowancePlan.loading);
  const updating = useAppSelector(state => state.allowancePlan.updating);
  const updateSuccess = useAppSelector(state => state.allowancePlan.updateSuccess);
  const allowanceItemList = useAppSelector(state => state.allowanceItem.entities);

  const [selectedAllowancePlanCoverage, setSelectedAllowancePlanCoverage] = useState<number | null>(null);

  const handleClose = () => {
    navigate('/allowance-plan');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getAllowancePlan(id));
    }

    dispatch(getHrCodes({}));
    dispatch(getAllowancePlanItemEntities({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...allowancePlanEntity,
      ...values,
      allowancePlan: hrCodes.find(it => it.id.toString() === values.allowancePlan.toString()),
      allowanceItems: selectedAllowanceItems,
      allowancePlanCoverage: hrCodes.find(it => it.id.toString() === values.allowancePlanCoverage.toString()),
      allowanceStatus: isNew
        ? hrCodes.find(e => e.codeType === 'ALS' && e.codeName === 'Pending')
        : hrCodes.find(it => it.id.toString() === values.allowanceStatus.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = useMemo(() => {
    const defaultValuesData = isNew
      ? { allowanceStatus: 0 }
      : {
          ...allowancePlanEntity,
          allowancePlan: allowancePlanEntity?.allowancePlan?.id,
          allowanceItem: allowancePlanEntity?.allowanceItem?.id,
          allowanceStatus: allowancePlanEntity?.allowanceStatus?.id,
          allowancePlanCoverage: allowancePlanEntity?.allowancePlanCoverage?.id,
        };
    return defaultValuesData;
  }, [isNew, allowancePlanEntity, hrCodes, allowanceItemList]);

  useEffect(() => {
    setSelectedAllowancePlanCoverage(isNew ? null : allowancePlanEntity?.allowancePlanCoverage?.id);
  }, [allowancePlanEntity]);

  const [allowanceItems, setAllowanceItems] = useState({
    options: allowancePlanEntity?.allowanceItems,
    selectedValue: {},
  });

  const [selectedAllowanceItems, setSelectedAllowanceItems] = useState([]);

  const onSelect = (selectedList, selectedItem) => {
    setSelectedAllowanceItems(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedAllowanceItems(selectedList);
  };

  useEffect(() => {
    if (allowanceItemList.length > 0) {
      const allowanceItemListIDs = allowanceItemList
        .filter(d => d?.allowanceItemCoverage?.id === selectedAllowancePlanCoverage)
        .flatMap(item => [item.id]);

      // Filter hrcodes based on extracted IDs
      const filteredHRCodes = hrCodes
        .filter(hrcode => allowanceItemListIDs.includes(hrcode.id))
        .map(d => {
          return {
            ...d,
            displayValue: `${d.codeName} ${d.codeDescription ? ` - ${d.codeDescription}` : ''} ${d.parent ? `- [${d.parent.codeName}]` : ''}`,
          };
        });

      let preSelectedItems = [];
      if (selectedAllowancePlanCoverage === allowancePlanEntity?.allowancePlanCoverage?.id) {
        // selectedItems
        const selectedAllowanceItemListIDs = allowancePlanEntity?.allowanceItems
          // .filter(d => d?.allowanceItemCoverage?.id === selectedAllowancePlanCoverage)
          .flatMap(item => [item.id]);

        preSelectedItems = hrCodes
          .filter(hrcode => selectedAllowanceItemListIDs.includes(hrcode.id))
          .map(d => {
            return {
              ...d,
              displayValue: `${d.codeName} ${d.codeDescription ? ` - ${d.codeDescription}` : ''} ${d.parent ? `- [${d.parent.codeName}]` : ''}`,
            };
          });
      }

      const data = {
        options: filteredHRCodes || [],
        selectedValue: preSelectedItems || [],
      };
      setAllowanceItems(data);
    }
  }, [selectedAllowancePlanCoverage, isNew, allowancePlanEntity, hrCodes, allowancePlanEntity, hrCodes, allowanceItemList]);

  const handleAllowancePlanChange = useCallback(
    (selectedValue: number) => {
      const selectedAllowanceTypeCode = hrCodes.find(it => it.id === selectedValue);
      // setSelectedAllowanceType(selectedAllowanceTypeCode?.codeName || null);
      setSelectedAllowancePlanCoverage(selectedAllowanceTypeCode?.parent?.id);
      setValue('allowancePlanCoverage', selectedAllowanceTypeCode?.parent?.id);
    },
    [hrCodes, allowancePlanEntity],
  );

  const { control, setValue, register, handleSubmit, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (defaultValues && Object.keys(defaultValues).length > 0) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5
            id="hrmsApp.allowancePlan.home.createOrEditLabel"
            data-cy="AllowancePlanCreateUpdateHeading"
            className="text-center fw-bold text-primary"
          >
            <Translate contentKey="hrmsApp.allowancePlan.home.createOrEditLabel">Create or edit a AllowancePlan</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center text-primary fw-bold">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues} onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="allowance-plan-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                  register={register}
                />
              ) : null}
              <ValidatedField
                id="allowance-plan-allowancePlan"
                name="allowancePlan"
                data-cy="allowancePlan"
                label={translate('hrmsApp.allowancePlan.allowancePlan')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                onChange={e => handleAllowancePlanChange(toNumber(e.target.value))}
                register={register}
              >
                <option value="" key="0" />
                {hrCodes
                  ? hrCodes
                      .filter(outerEntity => outerEntity.codeType === 'ALP' && outerEntity.id % 100 > 0)
                      .sort((a, b) => a.id - b.id)
                      .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                          {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                        </option>
                      ))
                  : null}
              </ValidatedField>
              {/* <ValidatedField
                id="allowance-plan-allowanceItem"
                name="allowanceItem"
                data-cy="allowanceItem"
                label={translate('hrmsApp.allowancePlan.allowanceItem')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hrCodes
                  ? hrCodes
                      .filter(outerEntity => outerEntity.codeType === 'ALI' && outerEntity.id % 100 > 0)
                      .sort((a, b) => a.id - b.id)
                      .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                          {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                        </option>
                      ))
                  : null}
              </ValidatedField> */}
              <ValidatedField
                id="allowance-plan-allowancePlanCoverage"
                name="allowancePlanCoverage"
                // data-cy="allowancePlanCoverage"
                label={translate('hrmsApp.allowancePlan.allowancePlanCoverage')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                register={register}
                disabled={true}
                // onChange={e => handleAllowancePlanCoverage(toNumber(e.target.value))}
              >
                <option value="" key="0" />
                {hrCodes
                  ?.filter(outerEntity => outerEntity.codeType === 'COU' && outerEntity.id % 100 > 0)
                  .sort((a, b) => a.id - b.id)
                  .map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                      {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                    </option>
                  ))}
              </ValidatedField>
              <Multiselect
                className="my-5"
                placeholder="Select Items"
                options={allowanceItems.options} // Options to display in the dropdown
                selectedValues={allowanceItems.selectedValue} // Preselected value to persist in dropdown
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="displayValue" // Property name to display in the dropdown options
              />
              <ValidatedField
                label={translate('hrmsApp.allowancePlan.startDate')}
                id="allowance-plan-startDate"
                name="startDate"
                data-cy="startDate"
                type="date"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                register={register}
              />
              <ValidatedField
                label={translate('hrmsApp.allowancePlan.endDate')}
                id="allowance-plan-endDate"
                name="endDate"
                data-cy="endDate"
                type="date"
                register={register}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/allowance-plan" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AllowancePlanUpdate;
