import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { JhiItemCount, JhiPagination, TextFormat, Translate, getPaginationState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, FormGroup, Input, Label, Table } from 'reactstrap';
import HrCodeModal from '../hr-code/hr-code-modal';
import { createEntity, getEntity, reset as resetEntity, updateEntity } from './allowance-item.reducer';

import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { getEntities } from './allowance-item.reducer';

export const AllowanceItemRow = ({ allowanceItem, paginationState }: { allowanceItem: any; paginationState: any }) => {
  const dispatch = useAppDispatch();
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const [selectedHrCode, setSelectedHrCode] = useState(null);
  const [isHrCodeModalOpen, setHrCodeModalOpen] = useState(false);
  const [allowanceItemStatus, setAllowanceItemStatus] = useState(true);

  useEffect(() => {
    if (allowanceItem) {
      const allowanceItemStatusCode = hrCodes.find(e => e.id === allowanceItem?.status?.id);
      setAllowanceItemStatus(allowanceItemStatusCode.codeName === 'Active' ? true : false);
    }
  }, [allowanceItem]);

  const showHrCodeModal = detail => {
    setSelectedHrCode(detail);
    toggleHrCodeModal();
  };

  const toggleHrCodeModal = () => {
    setHrCodeModalOpen(!isHrCodeModalOpen);
  };

  const deactivateAllowanceItem = () => {
    // console.log('Allwance Item Deactivate .....', allowanceItemStatus);
    const updatedAllowanceItem = { ...allowanceItem };

    updatedAllowanceItem['status'] = allowanceItemStatus
      ? hrCodes.find(e => e.codeType === 'ALS' && e.codeName === 'Disabled')
      : hrCodes.find(e => e.codeType === 'ALS' && e.codeName === 'Active');
    dispatch(updateEntity(updatedAllowanceItem));
  };

  return (
    <>
      <tr key={`entity-${allowanceItem.id}`} data-cy="entityTable">
        <td>
          <Button tag={Link} to={`/allowance-item/${allowanceItem.id}`} color="link" size="sm">
            {allowanceItem.id}
          </Button>
        </td>
        <td>
          {allowanceItem.code ? (
            <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e => e.id === allowanceItem.code.id))}>
              {hrCodes.find(e => e.id === allowanceItem.code.id)?.codeName}{' '}
            </span>
          ) : (
            // <Link to={`/hr-code/${allowanceItem.code.id}`}>{allowanceItem.code.codeName}</Link>
            ''
          )}
        </td>
        <td>
          {allowanceItem.category ? (
            <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e => e.id === allowanceItem.category.id))}>
              {hrCodes.find(e => e.id === allowanceItem.category.id)?.codeName}{' '}
            </span>
          ) : (
            // <Link to={`/hr-code/${allowanceItem.category.id}`}>{allowanceItem.category.codeName}</Link>
            ''
          )}
        </td>
        <td>
          {allowanceItem.type ? (
            <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e => e.id === allowanceItem.type.id))}>
              {hrCodes.find(e => e.id === allowanceItem.type.id)?.codeName}{' '}
            </span>
          ) : (
            // <Link to={`/hr-code/${allowanceItem.type.id}`}>{allowanceItem.type.codeName}</Link>
            ''
          )}
        </td>
        <td>
          {allowanceItem.currency ? (
            <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e => e.id === allowanceItem.currency.id))}>
              {hrCodes.find(e => e.id === allowanceItem.currency.id)?.codeName}{' '}
            </span>
          ) : (
            // <Link to={`/hr-code/${allowanceItem.currency.id}`}>{allowanceItem.currency.codeName}</Link>
            ''
          )}
        </td>
        <td>{allowanceItem.curVal}</td>
        <td>
          {allowanceItem.startDate ? <TextFormat type="date" value={allowanceItem.startDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
        </td>
        <td>{allowanceItem.nextVal}</td>
        <td>
          {allowanceItem.nextStartDate ? (
            <TextFormat type="date" value={allowanceItem.nextStartDate} format={APP_LOCAL_DATE_FORMAT} />
          ) : null}
        </td>
        <td>
          {allowanceItem.status ? (
            <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e => e.id === allowanceItem.status.id))}>
              {hrCodes.find(e => e.id === allowanceItem.status.id)?.codeName}{' '}
            </span>
          ) : (
            // <Link to={`/hr-code/${allowanceItem.status.id}`}>{allowanceItem.status.codeName}</Link>
            ''
          )}
        </td>
        <td className="text-end">
          <div className="btn-group flex-btn-group-container">
            {(hrCodes.find(e => e.id === allowanceItem.status.id)?.codeName === 'Active' ||
              hrCodes.find(e => e.id === allowanceItem.status.id)?.codeName === 'Disabled') && (
              <FormGroup switch>
                <Input
                  type="switch"
                  checked={allowanceItemStatus}
                  onClick={() => {
                    setAllowanceItemStatus(!allowanceItemStatus);
                    deactivateAllowanceItem();
                  }}
                />
                <Label check></Label>
              </FormGroup>
            )}
            <Button
              tag={Link}
              to={`/allowance-item/${allowanceItem.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
              color="primary"
              size="sm"
              data-cy="entityEditButton"
            >
              <FontAwesomeIcon icon="pencil-alt" />{' '}
            </Button>
            <Button
              onClick={() =>
                (window.location.href = `/allowance-item/${allowanceItem.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
              }
              color="danger"
              size="sm"
              data-cy="entityDeleteButton"
            >
              <FontAwesomeIcon icon="trash" />{' '}
            </Button>
          </div>
        </td>
      </tr>
      {selectedHrCode && <HrCodeModal hrCodeEntity={selectedHrCode} isOpen={isHrCodeModalOpen} toggle={toggleHrCodeModal} />}
    </>
  );
};

export const AllowanceItem = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const allowanceItemList = useAppSelector(state => state.allowanceItem.entities);
  const loading = useAppSelector(state => state.allowanceItem.loading);
  const totalItems = useAppSelector(state => state.allowanceItem.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
    if (!hrCodes || hrCodes.length === 0) {
      dispatch(getHrCodes({}));
    }
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h5 id="allowance-item-heading" data-cy="AllowanceItemHeading" className="text-center text-primary fw-bold p-1">
        <Translate contentKey="hrmsApp.allowanceItem.home.title">Allowance Items</Translate>
        <div className="d-flex justify-content-end">
          <Button className="btn-sm me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="hrmsApp.allowanceItem.home.refreshListLabel">Refresh</Translate>
          </Button>
          <Link
            to="/allowance-item/new"
            className="btn btn-sm btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="hrmsApp.allowanceItem.home.createLabel">Create new Allowance Item</Translate>
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {hrCodes && hrCodes.length > 0 && allowanceItemList && allowanceItemList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr className="table-info">
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hrmsApp.allowanceItem.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.allowanceItem.code">Code</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.allowanceItem.category">Category</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.allowanceItem.type">Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.allowanceItem.currency">Currency</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('curVal')}>
                  <Translate contentKey="hrmsApp.allowanceItem.curVal">Cur Val</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('curVal')} />
                </th>
                <th className="hand" onClick={sort('startDate')}>
                  <Translate contentKey="hrmsApp.allowanceItem.startDate">Start Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('startDate')} />
                </th>
                <th className="hand" onClick={sort('nextVal')}>
                  <Translate contentKey="hrmsApp.allowanceItem.nextVal">Next Val</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('nextVal')} />
                </th>
                <th className="hand" onClick={sort('nextStartDate')}>
                  <Translate contentKey="hrmsApp.allowanceItem.nextStartDate">Next Start Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('nextStartDate')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.allowanceItem.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {allowanceItemList.map(allowanceItem => (
                <AllowanceItemRow key={allowanceItem.id} allowanceItem={allowanceItem} paginationState={paginationState} />
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.allowanceItem.home.notFound">No Allowance Items found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={allowanceItemList && allowanceItemList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default AllowanceItem;
