import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getBusinessProjects } from 'app/entities/business-project/business-project.reducer';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, getSortState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import { getEntities } from './expense-claim.reducer';

export const ExpenseClaim = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const businessProjects = useAppSelector(state => state.businessProject.entities);
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const expenseClaimList = useAppSelector(state => state.expenseClaim.entities);
  const loading = useAppSelector(state => state.expenseClaim.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
    dispatch(getBusinessProjects({}));
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  // useEffect(() => {
  //   dispatch(
  //     getEntities({ query: `employeeId.equals=${account?.employeeRef}`
  //       sort: `${sortState.sort},${sortState.order}`,
  //     }),
  //   );
  // }, [contracts])

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    }
    return order === ASC ? faSortUp : faSortDown;
  };

  return (
    <div>
      <h5 id="expense-claim-heading" data-cy="ExpenseClaimHeading" className="text-center text-primary fw-bold m-0 p-0">
        <Translate contentKey="hrmsApp.expenseClaim.home.title">Expense Claims</Translate>
        <div className="d-flex justify-content-end">
          <Link
            to="/expense-claim/new"
            className="btn btn-sm btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="hrmsApp.expenseClaim.home.createLabel">Create new Claim</Translate>
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {expenseClaimList && expenseClaimList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                {/* <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hrmsApp.expenseClaim.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th> */}
                <th>
                  <Translate contentKey="hrmsApp.expenseClaim.businessProject">Business Project</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('remarks')}>
                  <Translate contentKey="hrmsApp.expenseClaim.remarks">Remarks</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('remarks')} />
                </th>
                <th className="hand" onClick={sort('dated')}>
                  <Translate contentKey="hrmsApp.expenseClaim.dated">Dated</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('dated')} />
                </th>
                {/* <th>
                  <Translate contentKey="hrmsApp.expenseClaim.contract">Contract</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}
                <th>
                  <Translate contentKey="hrmsApp.expenseClaim.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody className="align-middle">
              {expenseClaimList.map((expenseClaim, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  {/* <td>{expenseClaim.id}</td> */}
                  <td>
                    {expenseClaim.businessProject ? businessProjects.find(e => e.id === expenseClaim.businessProject.id)?.projectName : ''}
                  </td>
                  <td>{expenseClaim.remarks}</td>
                  <td>
                    {expenseClaim.dated ? <TextFormat type="date" value={expenseClaim.dated} format={APP_LOCAL_DATE_FORMAT} /> : null}
                  </td>
                  <td>{expenseClaim.status ? hrCodes.find(e => e.id === expenseClaim.status.id)?.codeName : ''}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      {hrCodes.find(e => e.id === expenseClaim.status.id)?.codeName === 'Pending' && (
                        <>
                          <Button
                            onClick={() => (window.location.href = `/expense-claim/${expenseClaim.id}/submit`)}
                            color="secondary"
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <FontAwesomeIcon icon="plus" />
                          </Button>
                          <Button
                            tag={Link}
                            to={`/expense-claim/${expenseClaim.id}/edit`}
                            color="primary"
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <FontAwesomeIcon icon="pencil-alt" />
                          </Button>
                          <Button
                            onClick={() => (window.location.href = `/expense-claim/${expenseClaim.id}/delete`)}
                            color="danger"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <FontAwesomeIcon icon="trash" />{' '}
                            {/* <span className="d-none d-md-inline">
      <Translate contentKey="entity.action.delete">Delete</Translate>
    </span> */}
                          </Button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.expenseClaim.home.notFound">No Expense Claims found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ExpenseClaim;
