import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ExpenseItem from './expense-item';
import ExpenseItemDeleteDialog from './expense-item-delete-dialog';
import ExpenseItemDetail from './expense-item-detail';
import ExpenseItemUpdateDialog from './expense-item-update-dialog';

const ExpenseItemRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ExpenseItem />} />
    {/* <Route path="new" element={<ExpenseItemUpdate />} /> */}
    <Route path=":id">
      <Route index element={<ExpenseItemDetail />} />
      {/* <Route path="edit" element={<ExpenseItemUpdate />} /> */}
      <Route path="delete" element={<ExpenseItemDeleteDialog />} />
      <Route path=":mid">{/* <Route path="edit" element={<ExpenseItemUpdateDialog />} /> */}</Route>
    </Route>
  </ErrorBoundaryRoutes>
);

export default ExpenseItemRoutes;
