import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { CodeStatus } from 'app/shared/model/enumerations/code-status.model';
import { createEntity, getEntity, reset, updateEntity } from './hr-code.reducer';

export const HrCodeUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const hrCodeEntity = useAppSelector(state => state.hrCode.entity);
  const loading = useAppSelector(state => state.hrCode.loading);
  const updating = useAppSelector(state => state.hrCode.updating);
  const updateSuccess = useAppSelector(state => state.hrCode.updateSuccess);
  const codeStatusValues = Object.keys(CodeStatus);

  const handleClose = () => {
    navigate('/hr-code');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getHrCodes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.codeSeq !== undefined && typeof values.codeSeq !== 'number') {
      values.codeSeq = Number(values.codeSeq);
    }

    const entity = {
      ...hrCodeEntity,
      ...values,
      parent: hrCodes.find(it => it.id.toString() === values.parent.toString()),
    };
    // if (new Date(entity.endDate) < new Date(entity.startDate)) {
    if (!isNew && entity.parent?.id===entity.id) {
      document.getElementById('hr-code-message').innerHTML= '<span class="col-md-8 bg-danger">Code can\'t be it\'s own parent</span>';
      return;
    }

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          codeStatus: 'P',
          ...hrCodeEntity,
          parent: hrCodeEntity?.parent?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5 id="hrmsApp.hrCode.home.createOrEditLabel" data-cy="HrCodeCreateUpdateHeading" className="text-center fw-bold text-primary">
            <Translate contentKey="hrmsApp.hrCode.home.createOrEditLabel">Create or edit a HrCode</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center text-primary">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="hr-code-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('hrmsApp.hrCode.codeType')}
                id="hr-code-codeType"
                name="codeType"
                data-cy="codeType"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  minLength: { value: 3, message: translate('entity.validation.minlength', { min: 3 }) },
                  maxLength: { value: 3, message: translate('entity.validation.maxlength', { max: 3 }) },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.hrCode.codeName')}
                id="hr-code-codeName"
                name="codeName"
                data-cy="codeName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.hrCode.codeDescription')}
                id="hr-code-codeDescription"
                name="codeDescription"
                data-cy="codeDescription"
                type="text"
                validate={{
                  required: { value: false, message: translate('entity.validation.required') },
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.hrCode.codeStatus')}
                id="hr-code-codeStatus"
                name="codeStatus"
                data-cy="codeStatus"
                type="select"
              >
                {codeStatusValues.map(codeStatus => (
                  <option value={codeStatus} key={codeStatus}>
                    {translate('hrmsApp.CodeStatus.' + codeStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('hrmsApp.hrCode.codeSeq')}
                id="hr-code-codeSeq"
                name="codeSeq"
                data-cy="codeSeq"
                type="text"
                validate={{
                  min: { value: 0, message: translate('entity.validation.min', { min: 0 }) },
                  max: { value: 32767, message: translate('entity.validation.max', { max: 32767 }) },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField id="hr-code-parent" name="parent" data-cy="parent" label={translate('hrmsApp.hrCode.parent')} type="select">
                <option value="" key="0" />
                {hrCodes
                    ? hrCodes.filter(outerEntity => outerEntity.id>=0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}   disabled={otherEntity.id % 100 === 0 && otherEntity.id>0}>
                        {/* {otherEntity.id % 100 > 0 ? `- ${otherEntity.codeName}` : otherEntity.codeName} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''} */}
                        {otherEntity.id % 100 > 0 ? `- ${otherEntity.codeName}` : otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/hr-code" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
      <Row id="hr-code-message" className="justify-content-center text-white m-0 py-1"></Row>
    </div>
  );
};

export default HrCodeUpdate;
