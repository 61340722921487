import dayjs from 'dayjs';
import { IHrCode } from 'app/shared/model/hr-code.model';

export interface IAllowanceItem {
  id?: number;
  curVal?: number;
  startDate?: dayjs.Dayjs;
  nextVal?: number | null;
  nextStartDate?: dayjs.Dayjs | null;
  code?: IHrCode;
  category?: IHrCode;
  type?: IHrCode;
  currency?: IHrCode;
  status?: IHrCode;
}

export const defaultValue: Readonly<IAllowanceItem> = {};
