import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ClientContact from './client-contact';
import ClientContactDetail from './client-contact-detail';
import ClientContactUpdate from './client-contact-update';
import ClientContactDeleteDialog from './client-contact-delete-dialog';

const ClientContactRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ClientContact />} />
    <Route path="new" element={<ClientContactUpdate />} />
    <Route path=":id">
      <Route index element={<ClientContactDetail />} />
      <Route path="edit" element={<ClientContactUpdate />} />
      <Route path="delete" element={<ClientContactDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ClientContactRoutes;
