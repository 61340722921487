import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ExpenseClaim from './expense-claim';
import ExpenseClaimDetail from './expense-claim-detail';
import ExpenseClaimUpdate from './expense-claim-update';
import ExpenseClaimDeleteDialog from './expense-claim-delete-dialog';
import ExpenseClaimSubmitDialog from './expense-claim-submit-dialog';

const ExpenseClaimRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ExpenseClaim />} />
    <Route path="new" element={<ExpenseClaimUpdate />} />
    <Route path=":id">
      <Route index element={<ExpenseClaimDetail />} />
      <Route path="edit" element={<ExpenseClaimUpdate />} />
      <Route path="submit" element={<ExpenseClaimSubmitDialog />} />
      <Route path="delete" element={<ExpenseClaimDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ExpenseClaimRoutes;
