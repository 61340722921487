import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, getPaginationState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import { getEntities } from './emp-dependent.reducer';

export const EmpDependentHome = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const empDependentList = useAppSelector(state => state.empDependent.entities);
  const loading = useAppSelector(state => state.empDependent.loading);
  const totalItems = useAppSelector(state => state.empDependent.totalItems);

  useEffect(() => {
    dispatch(getEntities({
      page: paginationState.activePage - 1,
      size: paginationState.itemsPerPage,
      sort: `dob,desc`,
    }));
  }, []);

  return (
    <div>
      <h5 id="emp-dependent-heading" data-cy="EmpDependentHeading" className="text-center fw-bold text-primary p-1">
        Dependents
        <div className="d-flex justify-content-end">
          <Link to="/emp-dependent/new" className="btn btn-sm btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />&nbsp;
            <Translate contentKey="hrmsApp.empDependent.home.createLabel">Add</Translate>
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {empDependentList && empDependentList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr className="table-info">
                <th>
                  <Translate contentKey="hrmsApp.empDependent.fullName">Full Name</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empDependent.relationInfo">Relation Info</Translate>{' '}
                </th>
                <th className="hand">
                  <Translate contentKey="hrmsApp.empDependent.cnic">Cnic</Translate>{' '}
                </th>
                <th >
                  <Translate contentKey="hrmsApp.empDependent.dob">Dob</Translate>
                </th>
                <th >
                  <Translate contentKey="hrmsApp.empDependent.occupation">Occupation</Translate>{' '}
                </th>
                <th >
                  <Translate contentKey="hrmsApp.empDependent.contactNo">Contact No</Translate>{' '}
                </th>
                <th >
                  <Translate contentKey="hrmsApp.empDependent.curAddress">Cur Address</Translate>{' '}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {empDependentList.map((empDependent, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>{empDependent.fullName}</td>
                  <td>
                    <Translate contentKey={`hrmsApp.relationInfo.${empDependent.relationInfo}`} />
                  </td>
                  <td>{empDependent.cnic}</td>
                  <td>{empDependent.dob ? <TextFormat type="date" value={empDependent.dob} format={APP_LOCAL_DATE_FORMAT} /> : null}</td>
                  <td>{empDependent.occupation}</td>
                  <td>{empDependent.contactNo}</td>
                  <td>{empDependent.curAddress}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/emp-dependent/${empDependent.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span> */}
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/emp-dependent/${empDependent.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span> */}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.empDependent.home.notFound">No Dependents found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default EmpDependentHome;
