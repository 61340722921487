import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './pending-approval.reducer';

export const PendingApprovalDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const pendingApprovalEntity = useAppSelector(state => state.pendingApproval.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="pendingApprovalDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.pendingApproval.detail.title">PendingApproval</Translate>
        </h5>
        <dl className="jh-entity-details bg-white">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{pendingApprovalEntity.id}</dd>
          <dt>
            <span id="dated">
              <Translate contentKey="hrmsApp.pendingApproval.dated">Dated</Translate>
            </span>
          </dt>
          <dd>
            {pendingApprovalEntity.dated ? (
              <TextFormat value={pendingApprovalEntity.dated} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="entityId">
              <Translate contentKey="hrmsApp.pendingApproval.entityId">Entity Id</Translate>
            </span>
          </dt>
          <dd>{pendingApprovalEntity.entityId}</dd>
          <dt>
            <span id="roleName">
              <Translate contentKey="hrmsApp.pendingApproval.roleName">Role</Translate>
            </span>
          </dt>
          <dd>{pendingApprovalEntity.roleName}</dd>
          <dt>
            <span id="remarks">
              <Translate contentKey="hrmsApp.pendingApproval.remarks">Remarks</Translate>
            </span>
          </dt>
          <dd>{pendingApprovalEntity.remarks}</dd>
          <dt>
            <Translate contentKey="hrmsApp.pendingApproval.workflow">Workflow</Translate>
          </dt>
          <dd>{pendingApprovalEntity.workflow ? pendingApprovalEntity.workflow.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.pendingApproval.entityStatus">Entity Status</Translate>
          </dt>
          <dd>{pendingApprovalEntity.entityStatus ? pendingApprovalEntity.entityStatus.codeName : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.pendingApproval.initiator">Initiator</Translate>
          </dt>
          <dd>{pendingApprovalEntity.initiator ? pendingApprovalEntity.initiator.qlid : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.pendingApproval.approver">Approver</Translate>
          </dt>
          <dd>{pendingApprovalEntity.approver ? pendingApprovalEntity.approver.qlid : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.pendingApproval.delegator">Delegator</Translate>
          </dt>
          <dd>{pendingApprovalEntity.delegator ? pendingApprovalEntity.delegator.qlid : ''}</dd>
          <dt>
            <Translate contentKey="hrmsApp.pendingApproval.action">Action</Translate>
          </dt>
          <dd>{pendingApprovalEntity.action ? pendingApprovalEntity.action.codeName : ''}</dd>
        </dl>
        <Button tag={Link} to="/pending-approval" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/pending-approval/${pendingApprovalEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PendingApprovalDetail;
