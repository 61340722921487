import dayjs from 'dayjs';
import { IEmployee } from 'app/shared/model/employee.model';
import { IHrCode } from 'app/shared/model/hr-code.model';
import { ITimeSheet } from 'app/shared/model/time-sheet.model';

export interface ITimeSheetRecord {
  id?: number; // ID of the TimeSheetRecord
  dated?: dayjs.Dayjs | null; // Optional date associated with the record
  remarks?: string | null; // Optional remarks field
  employee?: IEmployee | null; // Optional employee associated with the record
  weekNumber?: number | null; // Optional week number associated with the record
  year?: number | null; // Optional year associated with the record
  timeSheets?: ITimeSheet[] | null; // Optional list of TimeSheet objects
  status?: IHrCode | null; // Optional status field
}

export const defaultValue: Readonly<ITimeSheetRecord> = {
  id: undefined,
  dated: null,
  remarks: null,
  employee: null,
  timeSheets: null,
  status: null,
};
