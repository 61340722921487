import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './emp-reference.reducer';

export const EmpReferenceDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const empReferenceEntity = useAppSelector(state => state.empReference.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="empReferenceDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.empReference.detail.title">EmpReference</Translate>
        </h5>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{empReferenceEntity.id}</dd>
          <dt>
            <span id="fullName">
              <Translate contentKey="hrmsApp.empReference.fullName">Full Name</Translate>
            </span>
          </dt>
          <dd>{empReferenceEntity.fullName}</dd>
          <dt>
            <span id="organization">
              <Translate contentKey="hrmsApp.empReference.organization">Organization</Translate>
            </span>
          </dt>
          <dd>{empReferenceEntity.organization}</dd>
          <dt>
            <span id="designation">
              <Translate contentKey="hrmsApp.empReference.designation">Designation</Translate>
            </span>
          </dt>
          <dd>{empReferenceEntity.designation}</dd>
          <dt>
            <span id="linkage">
              <Translate contentKey="hrmsApp.empReference.linkage">Linkage</Translate>
            </span>
          </dt>
          <dd>{empReferenceEntity.linkage}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="hrmsApp.empReference.email">Email</Translate>
            </span>
          </dt>
          <dd>{empReferenceEntity.email}</dd>
          <dt>
            <span id="contactNo">
              <Translate contentKey="hrmsApp.empReference.contactNo">Contact No</Translate>
            </span>
          </dt>
          <dd>{empReferenceEntity.contactNo}</dd>
          <dt>
            <span id="locatopn">
              <Translate contentKey="hrmsApp.empReference.locatopn">Locatopn</Translate>
            </span>
          </dt>
          <dd>{empReferenceEntity.locatopn}</dd>
          <dt>
            <span id="cnic">
              <Translate contentKey="hrmsApp.empReference.cnic">Cnic</Translate>
            </span>
          </dt>
          <dd>{empReferenceEntity.cnic}</dd>
          <dt>
            <Translate contentKey="hrmsApp.empReference.employee">Employee</Translate>
          </dt>
          <dd>{empReferenceEntity.employee ? empReferenceEntity.employee.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/emp-reference" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/emp-reference/${empReferenceEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default EmpReferenceDetail;
