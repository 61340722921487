import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { IAuthMatrix } from 'app/shared/model/auth-matrix.model';

const initialState = {
  authMatrix: [] as ReadonlyArray<IAuthMatrix>,
  errorMessage: null,
};

const apiUrl = 'api/auth_matrix';
// Async Actions
export const getAuthMatrix = createAsyncThunk('authManagement/fetch_auth_matrix', async () => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IAuthMatrix[]>(requestUrl);
});

export type AuthManagementState = Readonly<typeof initialState>;

export const AuthManagementSlice = createSlice({
  name: 'authManagement',
  initialState: initialState as AuthManagementState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAuthMatrix.pending, state => state)
      .addCase(getAuthMatrix.rejected, (state, action) => {
        state.errorMessage = action.error.message;
      })
      .addCase(getAuthMatrix.fulfilled, (state, action) => {
        state.authMatrix = action.payload.data;
      });
  },
});

export const { reset } = AuthManagementSlice.actions;

// Reducer
export default AuthManagementSlice.reducer;
