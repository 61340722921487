import dayjs from 'dayjs';
import { IEmployee } from 'app/shared/model/employee.model';

export interface IEmpQualification {
  id?: number;
  degreeLevel?: number;
  degreeName?: string;
  majorSub?: string;
  institute?: string;
  passGrade?: number;
  passedOn?: dayjs.Dayjs;
  anyAcheivement?: string | null;
  isLast?: boolean | null;
  employee?: IEmployee;
}

export const defaultValue: Readonly<IEmpQualification> = {
  isLast: false,
};
