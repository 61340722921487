import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardImg, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { MaritalStatus } from 'app/shared/model/enumerations/marital-status.model';
import { createEntity, getEntity, updateEntity, uploadFile } from './emp-profile.reducer';

export const EmpProfileHome = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const account = useAppSelector(state => state.authentication.account);
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const employees = useAppSelector(state => state.employee.entities);
  const empProfile = useAppSelector(state => state.empProfile.entity);
  const updating = useAppSelector(state => state.empProfile.updating);
  const loading = useAppSelector(state => state.empProfile.loading);
  const updateSuccess = useAppSelector(state => state.empProfile.updateSuccess);
  const maritalStatusValues = Object.keys(MaritalStatus);

  /* eslint no-console: off */
  const [isNew, setIsNew] = useState(true);

  const [cnicPic, setCnicPic] = useState<File | null>(null);
  const [cnicPicLink, setCnicPicLink] = useState<string | null>(null);
  const [passportPic, setPassportPic] = useState<File | null>(null);
  const [passportPicLink, setPassportPicLink] = useState<string | null>(null);
  const [profilePic, setProfilePic] = useState<File | null>(null);
  const [profilePicLink, setProfilePicLink] = useState<string | null>(null);
  const [showModalProfile, setShowModalProfile] = useState(false);
  const [showModalCnic, setShowModalCnic] = useState(false);
  const [showModalPassport, setShowModalPassport] = useState(false);

  // Loader
  const [loadingProfilePicture, setLoadingProfilePicture] = useState(false);
  const [loadingCnicPicture, setLoadingCnicPicture] = useState(false);
  const [loadingPassportPicture, setLoadingPassportPicture] = useState(false);

  const uploadPic = (selectedFile: File, folderName: string) => {
    const formData = new FormData();
    console.log('---- File Name: ', account?.employeeRef.toString());
    formData.append('fileType', selectedFile.type);
    formData.append('fileName', account?.employeeRef.toString());
    formData.append('folderName', folderName);
    formData.append('file', selectedFile);
    return dispatch(uploadFile(formData));
  };

  const handleProfileFileChange = event => {
    setLoadingProfilePicture(true);
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setProfilePic(selectedFile);
      const response = uploadPic(selectedFile, 'profile');
      response
        .then(res => {
          const updatedPicLink = `${res.payload}?t=${new Date().getTime()}`;
          setProfilePicLink(updatedPicLink);
        })
        .finally(() => {
          setLoadingProfilePicture(false);
        });
    }
  };

  const handleCnicFileChange = event => {
    setLoadingCnicPicture(true);
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setCnicPic(selectedFile);
      const response = uploadPic(selectedFile, 'cnic');
      response
        .then(res => {
          const updatedFileLink = `${res.payload}?t=${new Date().getTime()}`;
          setCnicPicLink(updatedFileLink);
        })
        .finally(() => {
          setLoadingCnicPicture(false);
        });
    }
  };

  const handlePassportFileChange = event => {
    setLoadingPassportPicture(true);
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setPassportPic(selectedFile);
      const response = uploadPic(selectedFile, 'passport');
      response
        .then(res => {
          const updatedFileLink = `${res.payload}?t=${new Date().getTime()}`;
          setPassportPicLink(updatedFileLink);
        })
        .finally(() => {
          setLoadingPassportPicture(false);
        });
    }
  };

  useEffect(() => {
    dispatch(getEntity(account.employeeRef));
  }, [account?.employeeRef]);

  useEffect(() => {
    console.log('----- ', loading, '-', empProfile);
    setIsNew(!loading && (empProfile === undefined || empProfile === null) ? true : false);
    setProfilePicLink(empProfile?.profilePicUrl);
    setCnicPicLink(empProfile?.cnicUrl);
    setPassportPicLink(empProfile?.passportUrl);
  }, [empProfile]);

  useEffect(() => {
    if (updateSuccess) {
      // handleClose();
    }
  }, [updateSuccess]);

  const handleClose = () => {
    navigate('/');
  };

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.cnic !== undefined && typeof values.cnic !== 'number') {
      values.cnic = Number(values.cnic);
    }
    if (values.personalMobile !== undefined && typeof values.personalMobile !== 'number') {
      values.personalMobile = Number(values.personalMobile);
    }
    if (values.personalLandline !== undefined && typeof values.personalLandline !== 'number') {
      values.personalLandline = Number(values.personalLandline);
    }

    const entity = {
      ...empProfile,
      ...values,
      gender: hrCodes.find(it => it.id.toString() === values.gender.toString()),
      employee: employees.find(e => e.id === account.employeeRef),
      profilePicUrl: profilePicLink,
      passportUrl: passportPicLink,
      cnicUrl: cnicPicLink,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...empProfile,
          maritalStatus: empProfile?.maritalStatus, //.to'Single',
          gender: empProfile?.gender?.id,
          employee: empProfile?.employee?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5
            id="hrmsApp.empProfile.home.createOrEditLabel"
            data-cy="EmpProfileCreateUpdateHeading"
            className="text-center fw-bold text-primary p-1"
          >
            <Translate contentKey="hrmsApp.empProfile.home.createOrEditLabel">Create or edit a EmpProfile</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="m-0 mt-3 text-primary fw-bold">
        <Col md="9">
          {loading && (isNew || empProfile) ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {/* {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="emp-profile-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null} */}
              <ValidatedField
                label="Name"
                id="emp-profile-employeeName"
                name="employeeName"
                data-cy="employeeName"
                type="text"
                disabled
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                readOnly
                value={account?.firstName + ' ' + account?.lastName}
              />
              <ValidatedField
                id="emp-profile-gender"
                name="gender"
                data-cy="gender"
                label={translate('hrmsApp.empProfile.gender') + '*'}
                type="select"
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hrCodes
                  ? hrCodes
                      .filter(outerEntity => outerEntity.codeType === 'GND' && outerEntity.id % 100 > 0)
                      .sort((a, b) => a.id - b.id)
                      .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                          {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                        </option>
                      ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('hrmsApp.empProfile.maritalStatus') + '*'}
                id="emp-profile-maritalStatus"
                name="maritalStatus"
                data-cy="maritalStatus"
                type="select"
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                {maritalStatusValues.map(maritalStatus => (
                  <option value={maritalStatus} key={maritalStatus}>
                    {translate('hrmsApp.MaritalStatus.' + maritalStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('hrmsApp.empProfile.cnic') + '*'}
                id="emp-profile-cnic"
                name="cnic"
                data-cy="cnic"
                type="text"
                className="row text-end d-flex"
                labelClass="col-md-5 text-start"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  min: { value: 1000000000001, message: translate('entity.validation.min', { min: 1000000000001 }) },
                  max: { value: 9999999999999, message: translate('entity.validation.max', { max: 9999999999999 }) },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.cnicIssueDate')}
                id="emp-profile-cnicIssueDate"
                name="cnicIssueDate"
                data-cy="cnicIssueDate"
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                type="date"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.cnicExpiryDate')}
                id="emp-profile-cnicExpiryDate"
                name="cnicExpiryDate"
                data-cy="cnicExpiryDate"
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                type="date"
              />
              {/* <ValidatedField
                label={translate('hrmsApp.empProfile.cnicUrl')}
                id="emp-profile-cnicFile"
                name="cnicFile"
                data-cy="cnicFile"
                type="file"
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                // disabled
              /> */}
              <ValidatedField
                label={translate('hrmsApp.empProfile.passportNo')}
                id="emp-profile-passportNo"
                name="passportNo"
                data-cy="passportNo"
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                type="text"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.passportIssueDate')}
                id="emp-profile-passportIssueDate"
                name="passportIssueDate"
                data-cy="passportIssueDate"
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                type="date"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.passportExpiryDate')}
                id="emp-profile-passportExpiryDate"
                name="passportExpiryDate"
                data-cy="passportExpiryDate"
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                type="date"
              />
              {/* <ValidatedField
                label={translate('hrmsApp.empProfile.passportUrl')}
                id="emp-profile-passportUrl"
                name="passportUrl"
                data-cy="passportUrl"
                type="text"
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                disabled
              /> */}
              <ValidatedField
                label={translate('hrmsApp.empProfile.personalMobile') + '*'}
                id="emp-profile-personalMobile"
                name="personalMobile"
                data-cy="personalMobile"
                type="text"
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  min: { value: 1000000001, message: translate('entity.validation.min', { min: 1000000001 }) },
                  max: { value: 999999999999, message: translate('entity.validation.max', { max: 999999999999 }) },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.personalLandline')}
                id="emp-profile-personalLandline"
                name="personalLandline"
                data-cy="personalLandline"
                type="text"
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                validate={{
                  max: { value: 999999999999, message: translate('entity.validation.max', { max: 999999999999 }) },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.personalEmailAddress')}
                id="emp-profile-personalEmailAddress"
                name="personalEmailAddress"
                data-cy="personalEmailAddress"
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                type="text"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.curAddressLine1') + '*'}
                id="emp-profile-curAddressLine1"
                name="curAddressLine1"
                data-cy="curAddressLine1"
                type="text"
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.curAddressLine2')}
                id="emp-profile-curAddressLine2"
                name="curAddressLine2"
                data-cy="curAddressLine2"
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                type="text"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.permAddressLine1')}
                id="emp-profile-permAddressLine1"
                name="permAddressLine1"
                data-cy="permAddressLine1"
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                type="text"
              />
              <ValidatedField
                label={translate('hrmsApp.empProfile.permAddressLine2')}
                id="emp-profile-permAddressLine2"
                name="permAddressLine2"
                data-cy="permAddressLine2"
                className="row d-flex"
                labelClass="col-md-5"
                inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
                type="text"
              />
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
        <Col md="3" className="justify-content-center text-center">
          {/* <ValidatedField
              label={translate('hrmsApp.empProfile.profilePicUrl')}
              id="emp-profile-profilePicUrl"
              name="profilePicUrl"
              data-cy="profilePicUrl"
              className="row d-flex"
              labelClass="col-md-5"
              inputClass="col-md-7 custom-input flex-grow-1 form-control-sm"
              type="file"
              accept="image/jpeg, image/png"
              onChange={(e) => handleProfileFileChange}
            /> */}
          <div className="container border-bottom border-gray mb-3">
            {profilePicLink != null ? (
              <>
                <Card className="border-0 d-flex justify-content-center align-items-center text-center">
                  <CardImg
                    src={profilePicLink}
                    alt="Profile"
                    style={{ width: '100px', cursor: 'pointer' }}
                    onClick={() => setShowModalProfile(true)} // Open modal on thumbnail click
                  />
                </Card>
                <Modal isOpen={showModalProfile} onHide={() => setShowModalProfile(false)} centered>
                  <ModalHeader closeButton>{/* <ModalTitle>Profile Picture</ModalTitle> */}</ModalHeader>
                  <ModalBody className="text-center">
                    <img src={profilePicLink} alt="Full Profile" className="img-fluid" />
                  </ModalBody>
                  <ModalFooter>
                    <Button variant="secondary" onClick={() => setShowModalProfile(false)}>
                      {' '}
                      Close{' '}
                    </Button>
                  </ModalFooter>
                </Modal>
              </>
            ) : null}
            <p>
              <label htmlFor="input-profile-pic" className="mt-1 p-1" style={{ cursor: 'pointer' }}>
                {loadingProfilePicture ? 'Uploading...' : 'Profile Picture (click to change)'}
              </label>

              <input
                id="input-profile-pic"
                type="file"
                accept="image/jpeg,image/png"
                onChange={handleProfileFileChange}
                style={{ display: 'none' }}
              />
            </p>
          </div>
          <div className="container border-bottom border-gray mb-3">
            {cnicPicLink != null ? (
              <>
                <Card className="border-0 d-flex justify-content-center align-items-center text-center">
                  <CardImg
                    src={cnicPicLink}
                    alt="CNIC"
                    style={{ width: '100px', cursor: 'pointer' }}
                    onClick={() => setShowModalCnic(true)}
                  />
                </Card>
                <Modal isOpen={showModalCnic} onHide={() => setShowModalCnic(false)} centered>
                  <ModalHeader closeButton>{/* <ModalTitle>Profile Picture</ModalTitle> */}</ModalHeader>
                  <ModalBody className="text-center">
                    <img src={cnicPicLink} alt="Full Profile" className="img-fluid" />
                  </ModalBody>
                  <ModalFooter>
                    <Button variant="secondary" onClick={() => setShowModalCnic(false)}>
                      {' '}
                      Close{' '}
                    </Button>
                  </ModalFooter>
                </Modal>
              </>
            ) : null}
            <p className="d-flex justify-content-center">
              <label htmlFor="input-cnic-pic" className="mt-1 p-1" style={{ cursor: 'pointer' }}>
                {' '}
                {loadingCnicPicture ? 'Uploading...' : 'CNIC Scan (click to change)'}{' '}
              </label>
              <input
                id="input-cnic-pic"
                type="file"
                accept="image/jpeg,image/png"
                onChange={handleCnicFileChange}
                style={{ display: 'none' }}
              />
            </p>
          </div>
          <div className="container border-bottom border-gray mb-3">
            {passportPicLink != null ? (
              <>
                <Card className="border-0 d-flex justify-content-center align-items-center text-center">
                  <CardImg
                    src={passportPicLink}
                    alt="Passport"
                    style={{ width: '100px', cursor: 'pointer' }}
                    onClick={() => setShowModalPassport(true)}
                  />
                </Card>
                <Modal isOpen={showModalPassport} onHide={() => setShowModalPassport(false)} centered>
                  <ModalHeader closeButton>{/* <ModalTitle>Profile Picture</ModalTitle> */}</ModalHeader>
                  <ModalBody className="text-center">
                    <img src={passportPicLink} alt="Full Profile" className="img-fluid" />
                  </ModalBody>
                  <ModalFooter>
                    <Button variant="secondary" onClick={() => setShowModalPassport(false)}>
                      {' '}
                      Close{' '}
                    </Button>
                  </ModalFooter>
                </Modal>
              </>
            ) : null}
            <p className="d-flex justify-content-center">
              <label htmlFor="input-passport-pic" className="mt-1 p-1" style={{ cursor: 'pointer' }}>
                {' '}
                {loadingPassportPicture ? 'Uploading...' : 'Passport Scan (click to change)'}{' '}
              </label>
              <input
                id="input-passport-pic"
                type="file"
                accept="image/jpeg,image/png"
                onChange={handlePassportFileChange}
                style={{ display: 'none' }}
              />
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EmpProfileHome;
