import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TimeSheetEmployees from './time-sheet-employees';

const TimeSheetRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TimeSheetEmployees />} />
  </ErrorBoundaryRoutes>
);

export default TimeSheetRoutes;
