import dayjs from 'dayjs';
import { IHrCode } from 'app/shared/model/hr-code.model';
import { IBusinessClient } from 'app/shared/model/business-client.model';
import { IEmployee } from 'app/shared/model/employee.model';

export interface IBusinessProject {
  id?: number;
  projectName?: string;
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs | null;
  remarks?: string | null;
  projectType?: IHrCode;
  mainClient?: IBusinessClient;
  partnerClient?: IBusinessClient | null;
  industry?: IHrCode;
  engagementType?: IHrCode;
  engagementStatus?: IHrCode;
  department?: IHrCode;
  location?: IHrCode;
  projectManager?: IEmployee;
  adhocManager?: IEmployee | null;
}

export const defaultValue: Readonly<IBusinessProject> = {};
