import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT, APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, getSortState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { getEntities as getTemplates } from '../contract-template/contract-template.reducer';
import EmployeeModal from '../employee/employee-modal';
import HrCodeModal from '../hr-code/hr-code-modal';
import { getEntities } from './contract.reducer';

export const Contract = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const employees = useAppSelector(state => state.employee.entities);
  const contractList = useAppSelector(state => state.contract.entities);
  const contractTemplates = useAppSelector(state => state.contractTemplate.entities);
  const loading = useAppSelector(state => state.contract.loading);
/* eslint no-console: off */

  useEffect(() => {
    dispatch(getTemplates({}));
  }, []);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  const [selectedHrCode, setSelectedHrCode] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isHrCodeModalOpen, setHrCodeModalOpen] = useState(false);
  const [isEmployeeModalOpen, setEmployeeModalOpen] = useState(false);
  const toggleHrCodeModal = () => {
    setHrCodeModalOpen(!isHrCodeModalOpen);
  };
  const toggleEmployeeModal = () => {
    setEmployeeModalOpen(!isEmployeeModalOpen);
  };

  const showHrCodeModal = (detail) => {
    setSelectedHrCode(detail);
    toggleHrCodeModal();
  };

  const showEmployeeModal = (detail) => {
    setSelectedEmployee(detail);
    toggleEmployeeModal();
  };

  return (
    <div>
      <h5 id="contract-heading" data-cy="ContractHeading" className="text-center text-primary fw-bold p-1">
        <Translate contentKey="hrmsApp.contract.home.title">Contracts</Translate>
        <div className="d-flex justify-content-end">
          <Button className="btn-sm me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="hrmsApp.contract.home.refreshListLabel">Refresh</Translate>
          </Button>
          <Link to="/contract/new" className="btn btn-sm btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="hrmsApp.contract.home.createLabel">Create new Contract</Translate>
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {hrCodes && hrCodes.length > 0 && employees && employees.length > 0 && contractTemplates && contractTemplates.length > 0 && contractList && contractList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr className="table-info">
                {/* <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hrmsApp.contract.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th> */}
                <th>
                  <Translate contentKey="hrmsApp.contract.contractTemplate">Contract Template</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contract.employee">Employee</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contract.payrollOffice">Payroll Office</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contract.designation">Designation</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contract.jobRole">Job Role</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contract.jobGrade">Job Grade</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contract.department">Department</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contract.location">Location</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('startedOn')}>
                  <Translate contentKey="hrmsApp.contract.startedOn">Started On</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('startedOn')} />
                </th>
                <th className="hand" onClick={sort('endedOn')}>
                  <Translate contentKey="hrmsApp.contract.endedOn">Ended On</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('endedOn')} />
                </th>
                <th className="hand" onClick={sort('probationCompletedOn')}>
                  <Translate contentKey="hrmsApp.contract.probationCompletedOn">Probation Completed On</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('probationCompletedOn')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contract.currency">Currency</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('hourlyRate')}>
                  <Translate contentKey="hrmsApp.contract.hourlyRate">Hourly Rate</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('hourlyRate')} />
                </th>
                <th className="hand" onClick={sort('baseSalary')}>
                  <Translate contentKey="hrmsApp.contract.baseSalary">Base Salary</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('baseSalary')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contract.allowancePlan">Allowance Plan</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                {/* <th className="hand" onClick={sort('aip')}>
                  <Translate contentKey="hrmsApp.contract.aip">Aip</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('aip')} />
                </th> */}
                <th>
                  <Translate contentKey="hrmsApp.contract.aipPlan">Aip Plan</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                {/* <th className="hand" onClick={sort('performanceBonus')}>
                  <Translate contentKey="hrmsApp.contract.performanceBonus">Performance Bonus</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('performanceBonus')} />
                </th> */}
                <th>
                  <Translate contentKey="hrmsApp.contract.bonusPlan">Bonus Plan</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contract.gfPlan">Gf Plan</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contract.pfPlan">Pf Plan</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('onsiteAllowance')}>
                  <Translate contentKey="hrmsApp.contract.onsiteAllowance">Onsite Allowance</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('onsiteAllowance')} />
                </th>
                <th className="hand" onClick={sort('flightAllowance')}>
                  <Translate contentKey="hrmsApp.contract.flightAllowance">Flight Allowance</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('flightAllowance')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contract.medicalPlan">Medical Plan</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contract.leavesPlan">Leaves Plan</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contract.contractStatus">Contract Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('revisionNo')}>
                  <Translate contentKey="hrmsApp.contract.revisionNo">Revision No</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('revisionNo')} />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {contractList.map((contract, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  {/* <td>
                    <Button tag={Link} to={`/contract/${contract.id}`} color="link" size="sm">
                      {contract.id}
                    </Button>
                  </td> */}
                  <td>
                    {contract.contractTemplate ? (
                      <span className="text-primary fw-bold" 
                        onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===(contractTemplates.find(x=>x.id===contract.contractTemplate.id)?.templateCode.id)))}>
                          {hrCodes.find(e=>e.id===(contractTemplates.find(x=>x.id===contract.contractTemplate.id)?.templateCode.id))?.codeName} </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{contract.employee ?
                    <span className="text-primary fw-bold" onClick={() => showEmployeeModal(contract.employee)}>
                      {employees.find(e=>e.id===contract.employee.id)?.firstName} {employees.find(e=>e.id===contract.employee.id)?.lastName}</span>
                    : ''}
                  </td>
                  <td>
                    {contract.payrollOffice ? (
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contract.payrollOffice.id))}>{hrCodes.find(e=>e.id===contract.payrollOffice.id)?.codeName} </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {contract.designation ?
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contract.designation.id))}>{hrCodes.find(e=>e.id===contract.designation.id)?.codeName} </span>
                    : ''}
                  </td>
                  <td>{contract.jobRole ?
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contract.jobRole.id))}>{hrCodes.find(e=>e.id===contract.jobRole.id)?.codeName} </span>
                    : ''}
                  </td>
                  <td>{contract.jobGrade ? 
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contract.jobGrade.id))}>{hrCodes.find(e=>e.id===contract.jobGrade.id)?.codeName} </span>
                    : ''}
                  </td>
                  <td>
                    {contract.department ?
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contract.department.id))}>{hrCodes.find(e=>e.id===contract.department.id)?.codeName} </span>
                      : ''}
                  </td>
                  <td>{contract.location ?
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contract.location.id))}>{hrCodes.find(e=>e.id===contract.location.id)?.codeName} </span>
                    : ''}
                  </td>
                  <td>
                    {contract.startedOn ? <TextFormat type="date" value={contract.startedOn} format={APP_LOCAL_DATE_FORMAT} /> : null}
                  </td>
                  <td>{contract.endedOn ? <TextFormat type="date" value={contract.endedOn} format={APP_LOCAL_DATE_FORMAT} /> : null}</td>
                  <td>
                    {contract.probationCompletedOn ? (
                      <TextFormat type="date" value={contract.probationCompletedOn} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{contract.currency ?
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contract.currency.id))}>{hrCodes.find(e=>e.id===contract.currency.id)?.codeName} </span>
                  : ''}
                  </td>
                  <td>{contract.hourlyRate? (
                      <TextFormat value={contract.hourlyRate} type="number" format={APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT}  /> ) : null }
                  </td>
                  <td>{contract.baseSalary? (
                      <TextFormat value={contract.baseSalary} type="number" format={APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT}  /> ) : null }
                  </td>
                  <td>
                    {contract.allowancePlan ? (
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contract.allowancePlan.id))}>{hrCodes.find(e=>e.id===contract.allowancePlan.id)?.codeName} </span>
                    ) : (
                      ''
                    )}
                  </td>
                  {/* <td>{contract.aip}</td> */}
                  <td>{contract.aipPlan ?
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contract.aipPlan.id))}>{hrCodes.find(e=>e.id===contract.aipPlan.id)?.codeName} </span>
                  : ''}
                  </td>
                  {/* <td>{contract.performanceBonus}</td> */}
                  <td>{contract.bonusPlan ?
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contract.bonusPlan.id))}>{hrCodes.find(e=>e.id===contract.bonusPlan.id)?.codeName} </span>
                  : ''}
                  </td>
                  <td>{contract.gfPlan ?
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contract.gfPlan.id))}>{hrCodes.find(e=>e.id===contract.gfPlan.id)?.codeName} </span>
                  : ''}
                  </td>
                  <td>{contract.pfPlan ? 
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contract.pfPlan.id))}>{hrCodes.find(e=>e.id===contract.pfPlan.id)?.codeName} </span>
                  : ''}
                  </td>
                  <td>{contract.onsiteAllowance}</td>
                  <td>{contract.flightAllowance}</td>
                  <td>{contract.medicalPlan ? 
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contract.medicalPlan.id))}>{hrCodes.find(e=>e.id===contract.medicalPlan.id)?.codeName} </span>
                  : ''}
                  </td>
                  <td>{contract.leavesPlan ? 
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contract.leavesPlan.id))}>{hrCodes.find(e=>e.id===contract.leavesPlan.id)?.codeName} </span>
                  : ''}
                  </td>
                  <td>
                    {contract.contractStatus ? (
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contract.contractStatus.id))}>{hrCodes.find(e=>e.id===contract.contractStatus.id)?.codeName} </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{contract.revisionNo}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/contract/${contract.id}/edit`} color="primary" size="sm" data-cy="entityEditButton" > <FontAwesomeIcon icon="pencil-alt" />{' '}
                      </Button>
                      <Button onClick={() => (window.location.href = `/contract/${contract.id}/delete`) } color="danger" size="sm" data-cy="entityDeleteButton" > <FontAwesomeIcon icon="trash" />{' '}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.contract.home.notFound">No Contracts found</Translate>
            </div>
          )
        )}
      </div>
        {/* Display Modal */}
        {selectedHrCode && (
          <HrCodeModal hrCodeEntity={selectedHrCode} isOpen={isHrCodeModalOpen} toggle={toggleHrCodeModal} />
        )}
        {selectedEmployee && (
          <EmployeeModal employeeEntity={selectedEmployee} isOpen={isEmployeeModalOpen} toggle={toggleEmployeeModal} />
        )}
    </div>
  );
};

export default Contract;
