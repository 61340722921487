import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CountryWorkday from './country-workday';
import CountryWorkdayDetail from './country-workday-detail';
import CountryWorkdayUpdate from './country-workday-update';
import CountryWorkdayDeleteDialog from './country-workday-delete-dialog';

const CountryWorkdayRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CountryWorkday />} />
    <Route path="new" element={<CountryWorkdayUpdate />} />
    <Route path=":id">
      <Route index element={<CountryWorkdayDetail />} />
      <Route path="edit" element={<CountryWorkdayUpdate />} />
      <Route path="delete" element={<CountryWorkdayDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CountryWorkdayRoutes;
