import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AuthorityDelegation from './authority-delegation';
import AuthorityDelegationDetail from './authority-delegation-detail';
import AuthorityDelegationUpdate from './authority-delegation-update';
import AuthorityDelegationDeleteDialog from './authority-delegation-delete-dialog';

const AuthorityDelegationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AuthorityDelegation />} />
    <Route path="new" element={<AuthorityDelegationUpdate />} />
    <Route path=":id">
      <Route index element={<AuthorityDelegationDetail />} />
      <Route path="edit" element={<AuthorityDelegationUpdate />} />
      <Route path="delete" element={<AuthorityDelegationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AuthorityDelegationRoutes;
