import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ApprovalWorkflow from './approval-workflow';
import ApprovalWorkflowDetail from './approval-workflow-detail';
import ApprovalWorkflowUpdate from './approval-workflow-update';
import ApprovalWorkflowDeleteDialog from './approval-workflow-delete-dialog';

const ApprovalWorkflowRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ApprovalWorkflow />} />
    <Route path="new" element={<ApprovalWorkflowUpdate />} />
    <Route path=":id">
      <Route index element={<ApprovalWorkflowDetail />} />
      <Route path="edit" element={<ApprovalWorkflowUpdate />} />
      <Route path="delete" element={<ApprovalWorkflowDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ApprovalWorkflowRoutes;
