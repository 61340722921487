import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IAuthority } from 'app/shared/model/authority.model';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';

const initialState = {
  errorMessage: null,
  authorities: [] as ReadonlyArray<IAuthority>,
};

const apiUrl = 'api/authorities';

// Async Actions

export const getAuthorities = createAsyncThunk('authorityManagement', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return axios.get<IAuthority[]>(requestUrl);
});

export type AuthorityState = Readonly<typeof initialState>;

export const AuthoritySlice = createSlice({
  name: 'authorityManagement',
  initialState: initialState as AuthorityState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAuthorities.pending, state => state)
      .addCase(getAuthorities.rejected, (state, action) => {
        state.errorMessage = action.error.message;
      })
      .addCase(getAuthorities.fulfilled, (state, action) => {
        state.authorities = action.payload.data;
      });
  },
});

export const { reset } = AuthoritySlice.actions;

// Reducer
export default AuthoritySlice.reducer;
