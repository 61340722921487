import React from 'react';
import Loadable from 'react-loadable';
import { Route, useLocation } from 'react-router-dom';

import { AUTHORITIES } from 'app/config/constants';
import { sendActivity } from 'app/config/websocket-middleware';
import EntitiesRoutes from 'app/entities/routes';
import Home from 'app/modules/home/home';
import LoginRedirect from 'app/modules/login/login-redirect';
import Logout from 'app/modules/login/logout';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/error/page-not-found';
import { getAuthMatrix } from 'app/shared/reducers/auth-management';
import { useAppDispatch, useAppSelector } from './config/store';

const loading = <div>loading ...</div>;
/* eslint no-console: off */
const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

const AppRoutes = () => {
  const pageLocation = useLocation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    sendActivity(pageLocation.pathname);
    // console.log('----- PageLoaction: ', pageLocation);
    // console.log('----- Loaction: ', location);
    const lastPage = localStorage.getItem('CUR_PAGE');
    console.log('-->>> LAST_PAGE: ', lastPage, ' CUR_PAGE: ', pageLocation.pathname);
    localStorage.setItem('LAST_PAGE', lastPage);
    localStorage.setItem('CUR_PAGE', pageLocation.pathname+pageLocation.search);
  }, [pageLocation]);

  React.useEffect(() => {
    // authMatrix?.length>0? console.log('------ -> AuthMatrix', authMatrix.length) : console.log('------- AuthMatrix null', dispatch(getAuthMatrix()));
    dispatch(getAuthMatrix());
  }, []);

  const authMatrix = useAppSelector(state => state.authManagement.authMatrix);

  return (
    <div id='view-routes' className="view-routes p-1">
      <ErrorBoundaryRoutes>
        <Route index element={<Home />} />
        <Route path="logout" element={<Logout />} />
        <Route path="admin/*"
              element={
                <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
                  <Admin />
                  <EntitiesRoutes />
                </PrivateRoute>
              }
            />
        <Route path="sign-in" element={<LoginRedirect />} />
        <Route path="*"
              element={
                <PrivateRoute entity={pageLocation.pathname.substring(1)} hasAnyAuthorities={[AUTHORITIES.CEO]} authMatrix={authMatrix}>
                  <EntitiesRoutes />
                </PrivateRoute>
              }
            />
        <Route path="*" element={<PageNotFound />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
