import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { JhiItemCount, JhiPagination, Translate, getPaginationState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import HrCodeModal from '../hr-code/hr-code-modal';
import { getEntities } from './contract-template.reducer';

export const ContractTemplate = () => {
  const dispatch = useAppDispatch();
/* eslint no-console: off */

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const contractTemplateList = useAppSelector(state => state.contractTemplate.entities);
  const loading = useAppSelector(state => state.contractTemplate.loading);
  const totalItems = useAppSelector(state => state.contractTemplate.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };
  const [selectedHrCode, setSelectedHrCode] = useState(null);
//  const [entityDetail, setEntityDetail] = useState(null);
  const [isHrCodeModalOpen, setHrCodeModalOpen] = useState(false);
  const toggleHrCodeModal = () => {
    setHrCodeModalOpen(!isHrCodeModalOpen);
  };

  const showHrCodeModal = (detail) => {
    setSelectedHrCode(detail);
    console.log('-----> val: ', detail.codeType, detail.codeName, detail.parent?.id);
    toggleHrCodeModal();
  };

  return (
    <div>
      <h5 id="contract-template-heading" data-cy="ContractTemplateHeading" className="text-center text-primary fw-bold p-1">
        <Translate contentKey="hrmsApp.contractTemplate.home.title">Contract Templates</Translate>
        <div className="d-flex justify-content-end">
          <Button className="btn-sm me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="hrmsApp.contractTemplate.home.refreshListLabel">Refresh</Translate>
          </Button>
          <Link to="/contract-template/new" className="btn btn-sm btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="hrmsApp.contractTemplate.home.createLabel">Create new Contract Template</Translate>
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {hrCodes && hrCodes.length > 0 && contractTemplateList && contractTemplateList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr className="table-info">
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hrmsApp.contractTemplate.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contractTemplate.templateCode">Template Code</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contractTemplate.employmentType">Employment Type</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contractTemplate.payrollOffice">Payroll Office</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contractTemplate.currency">Currency</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('hourlyBased')}>
                  <Translate contentKey="hrmsApp.contractTemplate.hourlyBased">Hourly Based</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('hourlyBased')} />
                </th>
                <th className="hand" onClick={sort('allowance')}>
                  <Translate contentKey="hrmsApp.contractTemplate.allowance">Allowance</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('allowance')} />
                </th>
                <th className="hand" onClick={sort('aip')}>
                  <Translate contentKey="hrmsApp.contractTemplate.aip">Aip</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('aip')} />
                </th>
                <th className="hand" onClick={sort('gf')}>
                  <Translate contentKey="hrmsApp.contractTemplate.gf">Gf</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('gf')} />
                </th>
                <th className="hand" onClick={sort('pf')}>
                  <Translate contentKey="hrmsApp.contractTemplate.pf">Pf</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('pf')} />
                </th>
                <th className="hand" onClick={sort('onsiteAllowance')}>
                  <Translate contentKey="hrmsApp.contractTemplate.onsiteAllowance">Onsite Allowance</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('onsiteAllowance')} />
                </th>
                <th className="hand" onClick={sort('performanceBonus')}>
                  <Translate contentKey="hrmsApp.contractTemplate.performanceBonus">Performance Bonus</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('performanceBonus')} />
                </th>
                <th className="hand" onClick={sort('medical')}>
                  <Translate contentKey="hrmsApp.contractTemplate.medical">Medical</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('medical')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.contractTemplate.templateStatus">Template Status</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {contractTemplateList.map((contractTemplate, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td> {contractTemplate.id} </td> 
                  <td>
                    {contractTemplate.templateCode ? (
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contractTemplate.templateCode.id))}>{hrCodes.find(e=>e.id===contractTemplate.templateCode.id)?.codeName} </span>
                      ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {contractTemplate.employmentType ? (
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contractTemplate.employmentType.id))}>{hrCodes.find(e=>e.id===contractTemplate.employmentType.id)?.codeName} </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {contractTemplate.payrollOffice ? (
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contractTemplate.payrollOffice.id))}>{hrCodes.find(e=>e.id===contractTemplate.payrollOffice.id)?.codeName} </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {contractTemplate.currency ? (
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(hrCodes.find(e=>e.id===contractTemplate.currency.id))}>{hrCodes.find(e=>e.id===contractTemplate.currency.id)?.codeName} </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-center fw-bold text-primary">{contractTemplate.hourlyBased ? '✔' : 'X'}</td>
                  <td className="text-center fw-bold text-primary">{contractTemplate.allowance ? '✔' : 'X'}</td>
                  <td className="text-center fw-bold text-primary">{contractTemplate.aip ? '✔' : 'X'}</td>
                  <td className="text-center fw-bold text-primary">{contractTemplate.gf ? '✔' : 'X'}</td>
                  <td className="text-center fw-bold text-primary">{contractTemplate.pf ? '✔' : 'X'}</td>
                  <td className="text-center fw-bold text-primary">{contractTemplate.onsiteAllowance ? '✔' : 'X'}</td>
                  <td className="text-center fw-bold text-primary">{contractTemplate.performanceBonus ? '✔' : 'X'}</td>
                  <td className="text-center fw-bold text-primary">{contractTemplate.medical ? '✔' : 'X'}</td>
                  <td>
                    {contractTemplate.templateStatus ? (
                      <span className="text-primary fw-bold" onClick={() => showHrCodeModal(contractTemplate.templateStatus)}>{hrCodes.find(e=>e.id===contractTemplate.templateStatus.id)?.codeName} </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      {/* <Button
                        tag={Link}
                        to={`/contract-template/${contractTemplate.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button> */}
                      <Button
                        tag={Link}
                        to={`/contract-template/${contractTemplate.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span> */}
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/contract-template/${contractTemplate.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span> */}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.contractTemplate.home.notFound">No Contract Templates found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={contractTemplateList && contractTemplateList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
        {/* Display Modal */}
        {selectedHrCode && (
          <HrCodeModal hrCodeEntity={selectedHrCode} isOpen={isHrCodeModalOpen} toggle={toggleHrCodeModal} />
        )}
    </div>
  );
};

export default ContractTemplate;
