import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getContractTemplates } from 'app/entities/contract-template/contract-template.reducer';
import { createEntity, getEntities, getEntity, reset, updateEntity } from './contract.reducer';

export const ContractUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const employees = useAppSelector(state => state.employee.entities);
  const contractTemplates = useAppSelector(state => state.contractTemplate.entities);
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const contractEntity = useAppSelector(state => state.contract.entity);
  const contractList = useAppSelector(state => state.contract.entities);
  const contracts = useAppSelector(state => state.contract.entities);
  const loading = useAppSelector(state => state.contract.loading);
  const updating = useAppSelector(state => state.contract.updating);
  const updateSuccess = useAppSelector(state => state.contract.updateSuccess);

  const handleClose = () => {
    navigate('/contract' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getContractTemplates({}));
    if (contractList.length === 0) {
      dispatch(getEntities({}));
    }
    console.log('----- Employee: ', employee);
    console.log('----- Template: ', contractTemplate);
  }, []);

  // Added
  /* eslint no-console: off */
  const [employee, setEmployee] = useState(contractEntity?.employee);
  const [contractTemplate, setContractTemplate] = useState(contractEntity?.contractTemplate);

  useEffect(() => {
    setContractTemplate(contractEntity?.contractTemplate);
    setEmployee(contractEntity?.employee);
  }, [contractEntity]);

  const handleContractTemplateChange = event => {
    setContractTemplate(contractTemplates.find(e => e.id.toString() === event.target.value));
    console.log('----- Template: ', event.target.value);
    if (
      event.target.value &&
      employee !== undefined &&
      contracts.find(e => e.contractTemplate.id.toString() === event.target.value && e.employee.id === employee.id) !== undefined
    ) {
      alert('You have already added Contract for this employee');
    }
  };

  const handleEmployeeChange = event => {
    setEmployee(employees.find(e => e.id.toString() === event.target.value));
    console.log('----- Employee: ', event.target.value);
    if (
      event.target.value &&
      contractTemplate !== undefined &&
      contracts.find(e => e.contractTemplate.id === contractTemplate.id && e.employee.id.toString() === event.target.value) !== undefined
    ) {
      alert('You have already added Contract for this employee');
    }
  };
  // End Added

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.hourlyRate !== undefined && typeof values.hourlyRate !== 'number') {
      values.hourlyRate = Number(values.hourlyRate);
    }
    if (values.baseSalary !== undefined && typeof values.baseSalary !== 'number') {
      values.baseSalary = Number(values.baseSalary);
    }
    if (values.onsiteAllowance !== undefined && typeof values.onsiteAllowance !== 'number') {
      values.onsiteAllowance = Number(values.onsiteAllowance);
    }
    if (values.flightAllowance !== undefined && typeof values.flightAllowance !== 'number') {
      values.flightAllowance = Number(values.flightAllowance);
    }
    if (values.aip !== undefined && typeof values.aip !== 'number') {
      values.aip = Number(values.aip);
    }
    if (values.performanceBonus !== undefined && typeof values.performanceBonus !== 'number') {
      values.performanceBonus = Number(values.performanceBonus);
    }
    if (values.revisionNo !== undefined && typeof values.revisionNo !== 'number') {
      values.revisionNo = Number(values.revisionNo);
    }
    const entity = {
      ...contractEntity,
      ...values,
      employee: employees.find(it => it.id.toString() === values.employee?.toString()),
      contractTemplate: isNew ? contractTemplate : contractTemplates.find(it => it.id.toString() === values.contractTemplate?.toString()),
      payrollOffice: hrCodes.find(it => it.id.toString() === values.payrollOffice?.toString()),
      currency: hrCodes.find(it => it.id.toString() === values?.currency?.toString()),
      designation: hrCodes.find(it => it.id.toString() === values.designation?.toString()),
      jobRole: hrCodes.find(it => it.id.toString() === values.jobRole?.toString()),
      jobGrade: hrCodes.find(it => it.id.toString() === (values.jobGrade ? values.jobGrade.toString() : '0')),
      department: hrCodes.find(it => it.id.toString() === values.department?.toString()),
      location: hrCodes.find(it => it.id.toString() === values.location?.toString()),
      allowancePlan: hrCodes.find(it => it.id.toString() === values.allowancePlan?.toString()),
      aipPlan: hrCodes.find(it => it.id.toString() === values.aipPlan?.toString()),
      bonusPlan: hrCodes.find(it => it.id.toString() === values.bonusPlan?.toString()),
      gfPlan: hrCodes.find(it => it.id.toString() === values.gfPlan?.toString()),
      pfPlan: hrCodes.find(it => it.id.toString() === values.pfPlan?.toString()),
      medicalPlan: hrCodes.find(it => it.id.toString() === values.medicalPlan?.toString()),
      leavesPlan: hrCodes.find(it => it.id.toString() === values.leavesPlan?.toString()),

      mealsPlan: hrCodes.find(it => it.id.toString() === values.mealsPlan?.toString()),
      otherPlan: hrCodes.find(it => it.id.toString() === values.otherPlan?.toString()),
      salesIncentivePlan: hrCodes.find(it => it.id.toString() === values.salesIncentivePlan?.toString()),
      communicationPlan: hrCodes.find(it => it.id.toString() === values.communicationPlan?.toString()),
      carPlan: hrCodes.find(it => it.id.toString() === values.carPlan?.toString()),
      wellBeingPlan: hrCodes.find(it => it.id.toString() === values.wellBeingPlan?.toString()),
      onSiteAllowancePlan: hrCodes.find(it => it.id.toString() === values.onSiteAllowancePlan?.toString()),
      flightAllowancePlan: hrCodes.find(it => it.id.toString() === values.flightAllowancePlan?.toString()),

      contractStatus: isNew
        ? hrCodes.find(e => e.codeType === 'CTS' && e.codeName === 'Pending')
        : hrCodes.find(it => it.id.toString() === values.contractStatus.toString()),
      revisionNo: isNew ? 1 : values.revisionNo,
    };
    console.log('----------- Start Date: ', entity.startDate);
    console.log('----------- Employee: ', entity.employee);
    if (entity.employee.joiningDate === null || entity.employee.joiningDate === undefined) {
      document.getElementById('contract-message').innerHTML = '<span class="col-md-8 bg-danger">Employee has not joined yet</span>';
      return;
    }
    if (new Date(entity.startedOn) < new Date(entity.employee.joiningDate)) {
      document.getElementById('contract-message').innerHTML =
        '<span class="col-md-8 bg-danger">Start Date is before Employee Joining Date: ' + entity.employee.joiningDate + '</span>';
      return;
    }
    if (entity.endedOn !== null && entity.endedOn !== undefined && new Date(entity.endedOn) <= new Date(entity.startedOn)) {
      document.getElementById('contract-message').innerHTML =
        '<span class="col-md-8 bg-danger">End Date can\'t be less than/equal to the Start Date.</span>';
      return;
    }
    let oldStartedOn = new Date(entity.employee.joiningDate);
    contractList
      .filter(e => e.employee.id === entity.employee.id)
      .map(contract => {
        console.log('---- Old StartedOn ', contract.startedOn, ' Cur: ', entity.startedOn);
        if (new Date(contract.startedOn) > new Date(entity.startedOn)) {
          oldStartedOn = new Date(contract.startedOn);
          return;
        }
      });
    if (oldStartedOn > new Date(entity.startedOn)) {
      document.getElementById('contract-message').innerHTML =
        '<span class="col-md-8 bg-danger">A prior contract exists with Start Date: ' + oldStartedOn.toLocaleDateString();
      return;
    }
    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const getId = obj => obj?.id;

  const defaultValues = () => {
    if (isNew) return {};

    const {
      payrollOffice,
      currency,
      designation,
      jobRole,
      jobGrade,
      department,
      location,
      allowancePlan,
      aipPlan,
      bonusPlan,
      gfPlan,
      pfPlan,
      medicalPlan,
      leavesPlan,
      mealsPlan,
      otherPlan,
      salesIncentivePlan,
      communicationPlan,
      carPlan,
      wellBeingPlan,
      onSiteAllowancePlan,
      flightAllowancePlan,
      contractStatus,
    } = contractEntity || {};

    return {
      ...contractEntity,
      employee: getId(employee),
      contractTemplate: getId(contractTemplate),
      payrollOffice: getId(payrollOffice),
      currency: getId(currency),
      designation: getId(designation),
      jobRole: getId(jobRole),
      jobGrade: getId(jobGrade),
      department: getId(department),
      location: getId(location),
      allowancePlan: getId(allowancePlan),
      aipPlan: getId(aipPlan),
      bonusPlan: getId(bonusPlan),
      gfPlan: getId(gfPlan),
      pfPlan: getId(pfPlan),
      medicalPlan: getId(medicalPlan),
      leavesPlan: getId(leavesPlan),
      mealsPlan: getId(mealsPlan),
      otherPlan: getId(otherPlan),
      salesIncentivePlan: getId(salesIncentivePlan),
      communicationPlan: getId(communicationPlan),
      carPlan: getId(carPlan),
      wellBeingPlan: getId(wellBeingPlan),
      onSiteAllowancePlan: getId(onSiteAllowancePlan),
      flightAllowancePlan: getId(flightAllowancePlan),
      contractStatus: getId(contractStatus),
    };
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5
            id="hrmsApp.contract.home.createOrEditLabel"
            data-cy="ContractCreateUpdateHeading"
            className="text-center fw-bold text-primary"
          >
            <Translate contentKey="hrmsApp.contract.home.createOrEditLabel">Create or edit a Contract</Translate>
          </h5>
        </Col>
      </Row>
      {isNew && (
        <Row className="justify-content-center mx-2 mt-4 text-primary fw-bold">
          <Col md="8">
            <ValidatedField
              id="contract-contractTemplate-master"
              name="contractTemplateMaster"
              label={translate('hrmsApp.contract.contractTemplate') + '*'}
              type="select"
              className="row d-flex"
              labelClass="col-md-4"
              inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
              validate={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
              onChange={handleContractTemplateChange}
            >
              <option value="" key="0" />
              {contractTemplates
                ? contractTemplates
                    .filter(e => e.templateStatus.id !== hrCodes.find(t => t.codeType === 'CTS' && t.codeName === 'Rejected')?.id)
                    .sort((a, b) => a.id - b.id)
                    .map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {hrCodes.find(t => t.id === otherEntity.templateCode.id)?.codeName} (
                        {hrCodes.find(t => t.id === otherEntity.employmentType.id)?.codeName})
                      </option>
                    ))
                : null}
            </ValidatedField>
          </Col>
        </Row>
      )}
      {contractTemplate !== undefined && (
        <Row className="justify-content-center mx-2 text-primary fw-bold">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
                {!isNew && (
                  <ValidatedField
                    id="contract-contractTemplate"
                    name="contractTemplate"
                    data-cy="contractTemplate"
                    label={translate('hrmsApp.contract.contractTemplate') + '*'}
                    type="select"
                    className="row d-flex mt-4"
                    labelClass="col-md-4"
                    inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                    disabled
                    validate={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                  >
                    <option value="" key="0" />
                    {contractTemplates
                      ? contractTemplates
                          .filter(e => e.templateStatus.id !== hrCodes.find(t => t.codeType === 'CTS' && t.codeName === 'Rejected')?.id)
                          .sort((a, b) => a.id - b.id)
                          .map(otherEntity => (
                            <option value={otherEntity.id} key={otherEntity.id}>
                              {hrCodes.find(t => t.id === otherEntity.templateCode.id)?.codeName} (
                              {hrCodes.find(t => t.id === otherEntity.employmentType.id)?.codeName})
                            </option>
                          ))
                      : null}
                  </ValidatedField>
                )}
                <ValidatedField
                  id="contract-employee"
                  name="employee"
                  data-cy="employee"
                  label={translate('hrmsApp.contract.employee') + '*'}
                  type="select"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  disabled={!isNew}
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                  onChange={handleEmployeeChange}
                >
                  <option value="" key="0" />
                  {employees
                    ? employees
                        .filter(
                          e =>
                            e.joiningDate !== undefined &&
                            e.joiningDate !== null &&
                            (e.employeeStatus.id === hrCodes.find(t => t.codeType === 'EMS' && t.codeName === 'Joined')?.id ||
                              e.employeeStatus.id === hrCodes.find(t => t.codeType === 'EMS' && t.codeName === 'Active')?.id),
                        )
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.qlid} : {otherEntity.firstName} {otherEntity.lastName}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  id="contract-startedOn"
                  name="startedOn"
                  data-cy="startedOn"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  disabled={contractTemplate === undefined}
                  label={translate('hrmsApp.contract.startedOn')}
                  type="date"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                />
                {!isNew && (
                  <ValidatedField
                    label={translate('hrmsApp.contract.endedOn')}
                    id="contract-endedOn"
                    name="endedOn"
                    data-cy="endedOn"
                    className="row d-flex"
                    labelClass="col-md-4"
                    inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                    type="date"
                  />
                )}
                {!isNew && (
                  <ValidatedField
                    label={translate('hrmsApp.contract.probationCompletedOn')}
                    id="contract-probationCompletedOn"
                    name="probationCompletedOn"
                    data-cy="probationCompletedOn"
                    className="row d-flex"
                    labelClass="col-md-4"
                    inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                    type="date"
                  />
                )}
                {!isNew && (
                  <ValidatedField
                    id="contract-payrollOffice"
                    name="payrollOffice"
                    data-cy="payrollOffice"
                    label={translate('hrmsApp.contract.payrollOffice') + '*'}
                    className="row d-flex"
                    labelClass="col-md-4"
                    inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                    type="select"
                    required
                  >
                    <option value="" key="0" />
                    {hrCodes
                      ? hrCodes
                          .filter(outerEntity => outerEntity.codeType === 'ORG' && outerEntity.id % 100 > 0)
                          .sort((a, b) => a.id - b.id)
                          .map(otherEntity => (
                            <option value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                              {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                            </option>
                          ))
                      : null}
                  </ValidatedField>
                )}
                {!isNew && (
                  <ValidatedField
                    id="contract-currency"
                    name="currency"
                    data-cy="currency"
                    label={translate('hrmsApp.contract.currency') + '*'}
                    className="row d-flex"
                    labelClass="col-md-4"
                    inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                    type="select"
                    required
                  >
                    <option value="" key="0" />
                    {hrCodes
                      ? hrCodes
                          .filter(outerEntity => outerEntity.codeType === 'CUR' && outerEntity.id % 100 > 0)
                          .sort((a, b) => a.id - b.id)
                          .map(otherEntity => (
                            <option value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                              {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                            </option>
                          ))
                      : null}
                  </ValidatedField>
                )}
                <ValidatedField
                  label={translate('hrmsApp.contract.baseSalary')}
                  id="contract-baseSalary"
                  name="baseSalary"
                  data-cy="baseSalary"
                  disabled={contractTemplate.hourlyBased}
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="text"
                />
                <ValidatedField
                  label={translate('hrmsApp.contract.hourlyRate')}
                  id="contract-hourlyRate"
                  name="hourlyRate"
                  disabled={!contractTemplate.hourlyBased}
                  data-cy="hourlyRate"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="text"
                />
                <ValidatedField
                  id="contract-designation"
                  name="designation"
                  data-cy="designation"
                  label={translate('hrmsApp.contract.designation') + '*'}
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'DSG' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  id="contract-jobRole"
                  name="jobRole"
                  data-cy="jobRole"
                  label={translate('hrmsApp.contract.jobRole') + '*'}
                  type="select"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'JBR' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  id="contract-department"
                  name="department"
                  data-cy="department"
                  label={translate('hrmsApp.contract.department') + '*'}
                  type="select"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'DPT' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  id="contract-location"
                  name="location"
                  data-cy="location"
                  label={translate('hrmsApp.contract.location')}
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'LOC' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${hrCodes.find(e => e.id === otherEntity.parent.id)?.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.contract.allowancePlan')}
                  id="contract-allowancePlan"
                  name="allowancePlan"
                  data-cy="allowancePlan"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  disabled={!contractTemplate.allowance}
                  type="select"
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'ALP' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.contract.aipPlan')}
                  id="contract-aipPlan"
                  name="aipPlan"
                  disabled={!contractTemplate.aip}
                  data-cy="aipPlan"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'APP' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.contract.bonusPlan')}
                  id="contract-bonusPlan"
                  name="bonusPlan"
                  disabled={!contractTemplate.performanceBonus}
                  data-cy="bonusPlan"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'BNP' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.contract.performanceBonus')}
                  id="contract-performanceBonus"
                  name="performanceBonus"
                  disabled={!contractTemplate.performanceBonus}
                  data-cy="performanceBonus"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="text"
                />
                <ValidatedField
                  label={translate('hrmsApp.contract.onSiteAllowancePlan')}
                  id="contract-onSiteAllowancePlan"
                  name="onSiteAllowancePlan"
                  disabled={!contractTemplate.onSiteAllowancePlan}
                  data-cy="onSiteAllowancePlan"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'OAP' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.contract.flightAllowancePlan')}
                  id="contract-flightAllowancePlan"
                  name="flightAllowancePlan"
                  disabled={!contractTemplate.flightAllowancePlan}
                  data-cy="flightAllowancePlan"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'FAP' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.contract.gfPlan')}
                  id="contract-gfPlan"
                  name="gfPlan"
                  disabled={!contractTemplate.gf}
                  data-cy="gfPlan"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'GFP' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.contract.pfPlan')}
                  id="contract-pfPlan"
                  name="pfPlan"
                  disabled={!contractTemplate.pf}
                  data-cy="pfPlan"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'PFP' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.contract.medicalPlan')}
                  id="contract-medicalPlan"
                  name="medicalPlan"
                  disabled={!contractTemplate.medical}
                  data-cy="medicalPlan"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'MDP' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.contract.leavesPlan')}
                  id="contract-leavesPlan"
                  name="leavesPlan"
                  data-cy="leavesPlan"
                  disabled={!contractTemplate.medical}
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'LVP' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.contract.mealsPlan')}
                  id="contract-mealsPlan"
                  name="mealsPlan"
                  disabled={!contractTemplate.mealsPlan}
                  data-cy="mealsPlan"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'MLP' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.contract.otherPlan')}
                  id="contract-otherPlan"
                  name="otherPlan"
                  disabled={!contractTemplate.otherPlan}
                  data-cy="otherPlan"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'OPL' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.contract.salesIncentivePlan')}
                  id="contract-salesIncentivePlan"
                  name="salesIncentivePlan"
                  disabled={!contractTemplate.salesIncentivePlan}
                  data-cy="salesIncentivePlan"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'SIP' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.contract.communicationPlan')}
                  id="contract-communicationPlan"
                  name="communicationPlan"
                  disabled={!contractTemplate.communicationPlan}
                  data-cy="communicationPlan"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'CMP' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.contract.carPlan')}
                  id="contract-carPlan"
                  name="carPlan"
                  disabled={!contractTemplate.carPlan}
                  data-cy="carPlan"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'CRP' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.contract.wellBeingPlan')}
                  id="contract-wellBeingPlan"
                  name="wellBeingPlan"
                  disabled={!contractTemplate.wellBeingPlan}
                  data-cy="wellBeingPlan"
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  type="select"
                >
                  <option value="" key="0" />
                  {hrCodes
                    ? hrCodes
                        .filter(outerEntity => outerEntity.codeType === 'WBP' && outerEntity.id % 100 > 0)
                        .sort((a, b) => a.id - b.id)
                        .map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.codeName} {otherEntity.codeDescription ? ` - ${otherEntity.codeDescription}` : ''}{' '}
                            {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                          </option>
                        ))
                    : null}
                </ValidatedField>
                <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/contract" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" /> &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" /> &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </ValidatedForm>
            )}
          </Col>
        </Row>
      )}
      <Row id="contract-message" className="justify-content-center text-white m-0 py-1"></Row>
    </div>
  );
};

export default ContractUpdate;
