import './home.scss';

import React, { useEffect, useState } from 'react';
import { Translate } from 'react-jhipster';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

// import Sidebar from 'app/shared/layout/menus/sidebar';
// import { getSummary } from 'app/entities/approval-log/approval-log.reducer';
import ApprovalLogStats from 'app/entities/approval-log/approval-log-stats';
import Genealogy from 'app/entities/employee/genealogy';
import PendingApprovalHome from 'app/entities/pending-approval/pending-approval-home';
import PendingApprovalStat from 'app/entities/pending-approval/pending-approval-stats';
import { REDIRECT_URL, getLoginUrl } from 'app/shared/util/url-utils';

export const Home = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const empProfile = useAppSelector(state => state.empProfile.entity);
  const employees = useAppSelector(state => state.employee.entities);
  const [selectedMenuItem, setSelectedMenuItem] = useState('dashboard');
  const [pagelink, setPagelink] = useState('');
  const pageLocation = useLocation();
  const navigate = useNavigate();

  /* eslint no-console: off */
  const handleMenuItemClick = menuItem => {
    setSelectedMenuItem(menuItem);
    console.log('>>====> SideMenue Selected: ', menuItem);
  };

  // useEffect(() => {
  //   console.log('======== Entered in useEffect');
  //   if (account?.employeeRef !== null)  {
  //     console.log('-------- Entered in dispatch: ', account?.login);
  //     dispatch(getEmpProfile(account?.employeeRef));
  //   }
  //   // setSelectedList(employeeList);
  // }, []);
  useEffect(() => {
    const redirectURL = localStorage.getItem(REDIRECT_URL);
    if (redirectURL) {
      localStorage.removeItem(REDIRECT_URL);
      location.href = `${location.origin}${redirectURL}`;
      console.log('--------> location.href', location.href);
    }
    const fragmentIndex = location.href.indexOf('#');
    setPagelink(fragmentIndex !== -1 ? location.href.substring(fragmentIndex + 1) : '');
    // console.log('--------------- PageLink: ', pagelink);
  });

  // useEffect(() => {
  //   if (account?.login) {
  //     if (!account?.employeeRef===null) {
  //       // history.push('/home/profile');
  //       dispatch(getEmployees({}));
  //     }
  //     console.log('--------------- User logged in');
  //   } else {
  //     console.log('--------------- User not logged in');
  //   }
  // }, [employees]);

  return (
    <>
      {!account?.login ? (
        <div>
          <Alert color="warning">
            <Translate contentKey="global.messages.info.authenticated.prefix">If you want to </Translate>
            <a
              className="alert-link"
              onClick={() =>
                navigate(getLoginUrl(), {
                  state: { from: pageLocation },
                })
              }
            >
              <Translate contentKey="global.messages.info.authenticated.link">sign in</Translate>
            </a>
          </Alert>
        </div>
      ) : account?.employeeRef != null ? (
        <>
          <div className="row m-0">
            <div className="col-md-6 mt-2 p-0">
              {' '}
              <PendingApprovalStat />{' '}
            </div>
            <div className="col-md-6 mt-2 p-0 ps-2">
              {' '}
              <ApprovalLogStats />{' '}
            </div>
          </div>
          {selectedMenuItem === 'dashboard' && <Genealogy />}
          {selectedMenuItem === 'dashboard' && <PendingApprovalHome />}
          {/* {selectedMenuItem === 'profile' && ( <EmpProfile employeeRef={account.employeeRef} />  )} */}
        </>
      ) : (
        <Alert color="warning">
          {' '}
          Hi <strong>{account.login}</strong>, Your Employee Account is missing / misconfigured. Please contact HR Department.{' '}
        </Alert>
      )}
    </>
  );
};

export default Home;
